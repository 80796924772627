export const disqualifyingOffensesOptionsEn = [
  { label: 'Murder', value: 'murder'},
  { label: 'Trafficking of persons', value: 'human_trafficking'},
  { label: 'Aggravated kidnapping', value: 'aggravated_kidnapping'},
  { label: 'Injury to a child, elderly individual, or a disabled individual', value: 'injury_child_elderly_disabled'},
  { label: 'Abandoning or endangering a child', value: 'child_abandoning_endangerment'},
  { label: 'Any offense involving family violence including violations of bond or court orders', value: 'family_violence'},
  { label: 'Stalking', value: 'stalking'},
  { label: 'Any offense requiring registration as a sex offender', value: 'sex_offender'},
]

export const disqualifyingOffensesOptionsEs = [
  { label: 'Asesinato', value: 'murder'},
  { label: 'Tráfico de personas', value: 'human_trafficking'},
  { label: 'Secuestro agravad', value: 'aggravated_kidnapping'},
  { label: 'Lesión a un niño, un adulto mayor o una persona discapacitada', value: 'injury_child_elderly_disabled'},
  { label: 'Abandonar o poner en peligro a un niño', value: 'child_abandoning_endangerment'},
  { label: 'Cualquier delito que involucre violencia familiar, incluyendo la violación de una fianza o de órdenes judiciales.', value: 'family_violence'},
  { label: 'Acoso', value: 'stalking'},
  { label: 'Un delito por el que deba registrarse como delincuente sexual.', value: 'sex_offender'},
]