import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setPageBeforeSteps } from '../../actions/pageBeforeSteps';
import { BeigeTile, Header, Text } from '../general/BeigeTile';
import { Button, InvertedButton } from '../general/Common';
import { TileBackArrow } from '../general/TileBackArrow';
import { Translate } from 'react-localize-redux';

export const PreCheck = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleContinue = () => {
    dispatch(setPageBeforeSteps('/steps/precheck'));
    history.push('/steps/review');
  };
  return (
    <BeigeTile>
      <Header>
        <TileBackArrow onClick={() => history.push('/')} />
        <Translate id="preCheckHeader" />
      </Header>
      <Text style={{ marginTop: '40px' }}>
        <Translate id="preCheck1" />
      </Text>
      <Text>
        <Translate id="preCheck2" />{' '}
      </Text>
      <InvertedButton
        onClick={() => history.push('/check/pre-check')}
        style={{ width: '280px', marginBottom: '20px', marginTop: '10px', display: 'block' }}
      >
        <Translate id="preCheck3" />
      </InvertedButton>
      <Button onClick={handleContinue}>
        <Translate id="preCheck4" />
      </Button>
    </BeigeTile>
  );
};
