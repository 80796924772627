import { createSelector } from "reselect";

export const modalsSelector = (state) => state.modals;

export const selectedCaseSelector = (state) => state.modals.selectedCase;

export const showDisclaimerSelector = (state) => state.modals.showDisclaimer;

export const showEligibilityDisclaimerSelector = (state) => state.modals.showEligibilityDisclaimer;

export const showIneligibleCasesSelector = (state) => state.modals.showIneligibleCases;

export const showExpEligibleCasesSelector = (state) => state.modals.showExpEligibleCases;

export const showPrivacySelector = (state) => state.modals.showPrivacy;

export const showEmailResultsSelector = (state) => state.modals.showEmailResults;

export const showEmailPetitionsSelector = (state) => state.modals.showEmailPetitions;

export const showEmailKnowYourRightsSelector = (state) => state.modals.showEmailKnowYourRights;

export const showSurveySelector = (state) => state.modals.showSurvey;

export const showAboutUsSelector = (state) => state.modals.showAboutUs;

export const showVetsReemploymentPrgSelector = (state) => state.modals.showVetsReemploymentPrg;

export const showVetsTreatmentCrtSelector = (state) => state.modals.showVetsTreatmentCrt;

export const showGuidedFormSelector = (state) => state.modals.showGuidedForm;

export const showDemoInstructionsSelector = (state) => state.modals.showDemoInstructions;

export const showHelpSelector = (state) => state.modals.showHelp;

export const anyModalIsActiveSelector = createSelector(
  [modalsSelector],
  (modals) => Object.values(modals).some(value => value === true)
);