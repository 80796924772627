import React from 'react';
import { Translate } from 'react-localize-redux';
import styled from 'styled-components';
import { BG_LIGHTER, MAX_DESKTOP_WIDTH, SECONDARY } from '../../cssVars.js';
import { EligibleTile, ExpunctionEligibleTile, IneligibleTile } from './StatusTiles.js';

const Card = styled.button`
  background-color: ${BG_LIGHTER};
  border: 1px solid ${SECONDARY};
  box-shadow: 0 2px 4px rgba(0,0,0,.16);
  color: #37352B;
  border-radius: 4px;
  width: 100%;
  padding: 10px 15px;
  transition: all 0.2s ease;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
  &:hover {
    box-shadow: 0 2px 8px rgba(0,0,0,.16);
  }
  cursor: pointer;
  margin: 15px 0px;
  ${({ hideStatus }) => hideStatus ? '' : `
    @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
      flex-direction: column-reverse;
      > div:last-child {
        margin-bottom: 10px;
        width: min(calc(100% - 20px), 400px);
        align-self: flex-end;
      }
    } 
  `}
`;

const MainContent = styled.div`
  margin-right: 10px;
  > div {
    margin: 5px 0px;
  }
`;

// Small card-style div that shows basic information about a case
// (just offense name, defendant name, & filing date),
// with prompt to click it and view full details about the case in a modal
export const CaseCard = ({ caseInfo, onClick, style }) => {
  const { current_offense_name, fda, full_name, final_eligibility, hideStatus } = caseInfo;

  let statusTile = <EligibleTile />
  if (final_eligibility === 'ineligible') {
    statusTile = <IneligibleTile />
  } else if (final_eligibility === 'expunction') {
    statusTile = <ExpunctionEligibleTile />
  }

  return (
      <Card type="button" onClick={onClick} style={style} hideStatus={hideStatus}>
        <MainContent>
          <div>{current_offense_name}</div>
          <div>{full_name}</div>
          <div><Translate id="filingDate" />: {fda}</div>
          <div><i><Translate id="clickToSeeFull" /></i></div>
        </MainContent>
        {!hideStatus && statusTile }
      </Card>
    )
}
