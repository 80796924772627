import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components'
import { Translate } from 'react-localize-redux'
import { Formik } from 'formik';
import { ParagraphTextField, TextField, SelectField, OptionsList, CheckboxGroup, MultiFieldLine } from './FormFields.js';
import { setSurveyModal } from '../../actions/modals';
import { showSurveySelector } from '../../selectors/modals';
import { Modal, ModalHeader as Title } from './Modal';
import { Button, StyledHR } from './Common.js';
import { useFeedbackSurvey } from '../../hooks/editors.js';
import { useFormOptions } from '../../hooks/useFormOptions.js';
import { validateSurvey } from '../../utils/validators.js';

const Text = styled.p`
  margin: 20px 0px;
  margin-bottom: 5px;
`

export const SurveyModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { 
    timeToCompleteFormsOptions, 
    didYouCompleteTheFormsOptions, 
    petitionFormOptions,
    howUserHeardAboutSiteOptions,
    genderOptions,
    raceOptions,
    helpfulnessOptions,
    didYouFindWhatYouNeededOptions,
    howEasyWasInfoToFindOptions,
    whereDidYouAccessWebsiteOptions,
    additionalInfoOptions,
    educationLevelOptions,
    incomeRangeOptions,
    ageOptions
  } = useFormOptions();

  // If we're showing someone this survey on page transition, use "Before you go ..." title.
  // Otherwise, show generic "Feedback Survey" title.
  const [showBeforeYouGo, setShowBeforeYouGo] = useState(false);

  const onClose = () => {
    // When closing the modal, also remove any query strings
    history.push({ search: '' });
    setShowBeforeYouGo(false);
    dispatch(setSurveyModal(false));
  };

  const [submit, submitting] = useFeedbackSurvey(onClose);

  // Show the survey when set to show in state
  const showModal = useSelector(showSurveySelector);

  // If 'modal=survey' query string is set, update the redux state to show survey modal
  // This way the modal can be easily shown when we navigate to a page, e.g. return to the homepage after finishing a flow
  useEffect(() => {
    if (!showModal && location.search.includes('modal=survey')) {
      setShowBeforeYouGo(true);
      dispatch(setSurveyModal(true));
    }
    // eslint-disable-next-line
  }, [location.search]);


  if (!showModal) {
    return null;
  }

  return (
    <Modal closeOnOuterClick={true} onClose={onClose} style={{ width: 'min(95%, 550px)' }}>
      <Title>{showBeforeYouGo ? <Translate id="beforeYouGo"/> : <Translate id="feedbackSurvey"/>}</Title>
      <Text>
        <Translate id="feedbackSurveyIntro" />
      </Text>
      <Formik
        initialValues={{
          howHelpfulWasWebsite: '',
          didUserCompleteForms: '',
          petitionFormsUsed: '',
          timeToCompleteForms: '',
          didYouFindWhatYouNeeded: '',
          howEasyWasInfoToFind: '',
          applicantAge: '',
          applicantGender: '',
          applicantGenderSelfDescribeInput: '',
          applicantRaceEthnicity:[],
          applicantRaceEthnicityOtherInput: '',
          educationLevel: '',
          incomeRange: '',
          additionalInfo: [],
          whereDidYouAccessWebsite: '',
          howUserHeardAboutSite: '',
          howUserHeardAboutSiteOtherInput: '',
          additionalFeedback: '',
          name: '',
          email: '',
          phone: '',
        }}
        validate={validateSurvey}
        enableReinitialize={true}
        onSubmit={submit}>
        {({ handleSubmit, values, errors, touched, submitCount }) => {
          const getError = (name) => (touched[name] || submitCount >= 1) && errors[name];
          return (
            <>
              <Text>
                <Translate id="helpful" />
              </Text>
              <SelectField name="howHelpfulWasWebsite" error={getError("howHelpfulWasWebsite")} >
                <OptionsList options={helpfulnessOptions} name="howHelpfulWasWebsite" />
              </SelectField>
              
              <Text>
                <Translate id="completeForms" />
              </Text>
              <SelectField name="didUserCompleteForms" error={getError("didUserCompleteForms")} >
                <OptionsList options={didYouCompleteTheFormsOptions} name="didUserCompleteForms" />
              </SelectField>
              {values.didUserCompleteForms === 'Yes' && (
                <div>
                  <Text>
                    <Translate id="petitionFormsUsed" />
                  </Text>
                  <SelectField name="petitionFormsUsed" error={getError("petitionFormsUsed")} >
                    <OptionsList options={petitionFormOptions} name="petitionFormsUsed" />
                  </SelectField>

                  <Text>
                    <Translate id="timeToComplete" />
                  </Text>
                  <SelectField name="timeToCompleteForms" error={getError("timeToCompleteForms")} >
                    <OptionsList options={timeToCompleteFormsOptions} name="timeToCompleteForms" />
                  </SelectField>
                </div>
              )}

              <Text>
                <Translate id="didYouFindWhatYouNeeded" />
              </Text>
              <SelectField name="didYouFindWhatYouNeeded" error={getError("didYouFindWhatYouNeeded")} >
                <OptionsList options={didYouFindWhatYouNeededOptions} name="didYouFindWhatYouNeeded" />
              </SelectField>

              <Text>
                <Translate id="easeOfUnderstanding" />
              </Text>
              <SelectField name="howEasyWasInfoToFind" error={getError("howEasyWasInfoToFind")} >
                <OptionsList options={howEasyWasInfoToFindOptions} name="howEasyWasInfoToFind" />
              </SelectField>

              <StyledHR />

              <Text>
                <Translate id="age" />
              </Text>
              <SelectField name="applicantAge" error={getError("applicantAge")} >
                <OptionsList options={ageOptions} name="applicantAge" />
              </SelectField>

              <Text>
                <Translate id="gender" />
              </Text>
              <SelectField name="applicantGender" error={getError("applicantGender")} >
                <OptionsList options={genderOptions} name="applicantGender" />
              </SelectField>
              {values.applicantGender === 'Prefer to self-describe' && <TextField name="applicantGenderSelfDescribeInput" placeholder="Please specify" />}

              <Text>
                <Translate id="race" />
              </Text>
              <CheckboxGroup name="applicantRaceEthnicity" labelId="applicantRaceEthnicity" 
                            error={getError('applicantRaceEthnicity')} checkboxOptions={raceOptions}
                            style={{ marginLeft: '20px' }} checkboxStyle={{ marginTop: '15px'}} />
               {values.applicantRaceEthnicity.includes("Other race-ethnicity") && <TextField name="applicantRaceEthnicityOtherInput" placeholder="Please specify"/>}

              <Text style={{marginTop: '30px'}}>
                <Translate id="schooling" />
              </Text>
              <SelectField name="educationLevel" error={getError("educationLevel")} >
                <OptionsList options={educationLevelOptions} name="educationLevel" />
              </SelectField>

              <Text>
                <Translate id="income" />
              </Text>
              <SelectField name="incomeRange" error={getError("incomeRange")} >
                <OptionsList options={incomeRangeOptions} name="incomeRange" />
              </SelectField>

              <Text>
                <Translate id="otherInfo" />
              </Text>
              <CheckboxGroup name="additionalInfo" labelId="additionalInfo" 
                            error={getError('additionalInfo')} checkboxOptions={additionalInfoOptions}
                            style={{ marginLeft: '20px'}} checkboxStyle={{ marginTop: '15px'}} /> 

              <StyledHR />

              <Text>
                <Translate id="accessedSite" />
              </Text>
              <SelectField name="whereDidYouAccessWebsite" error={getError("whereDidYouAccessWebsite")} >
                <OptionsList options={whereDidYouAccessWebsiteOptions} name="whereDidYouAccessWebsite" />
              </SelectField>

              <Text>
                <Translate id="heardAboutSite" />
              </Text>
              <SelectField name="howUserHeardAboutSite" error={getError("howUserHeardAboutSite")} >
                <OptionsList options={howUserHeardAboutSiteOptions} name="howUserHeardAboutSite" />
              </SelectField>
              {values.howUserHeardAboutSite === "Other" && <TextField name="howUserHeardAboutSiteOtherInput" placeholder="Please specify" />}

              <Text>
                <Translate id="otherFeedback" />
              </Text>
              <ParagraphTextField name="additionalFeedback" />

              <Text style={{ marginBottom: '0px'}}>
                <Translate id="surveyNote" /> 
              </Text>            
              <MultiFieldLine columns={`50% 50%`}>
                {/* <TextField name="name" labelId="yourName" /> */}
                <TextField name="email" labelId="emailAddress" placeholder="example@gmail.com" error={getError('email')} />
                <TextField name="phone" labelId="phoneOpt" />
              </MultiFieldLine>

              <Button isLoading={submitting} wrapperStyle={{ marginTop: '20px' }} fillOnMobile={true} onClick={handleSubmit}>
                <Translate id="submit" />
              </Button>
            </>
          )}}
    </Formik> 
    </Modal>
  )
}