import { useEffect } from 'react';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import enTranslations from "../../translations/en.json";
import esTranslations from "../../translations/es.json";

const onMissingTranslation = ({ defaultTranslation, translationId }) => {
  if (defaultTranslation !== "No default translation found! Ensure you've added translations for your default langauge.") {
    return defaultTranslation;
  }
  return translationId;
};

// initialize English and Spanish translations
const Translations = ({ initialize, addTranslationForLanguage, defaultToSpanish }) => {

  // This is to easily test Spanish translations
  const englishDefaultLangs = [{ name: "English", code: "en" }, { name: "Español", code: "es" }]
  const spanishDefaultLangs = [{ name: "Español", code: "es" }, { name: "English", code: "en" }]

  useEffect(() => {
    initialize({
      languages: defaultToSpanish ? spanishDefaultLangs : englishDefaultLangs,
      translation: enTranslations,
      options: { 
        renderInnerHtml: true,
        renderToStaticMarkup, 
        onMissingTranslation 
      } 
    })
    addTranslationForLanguage(esTranslations, 'es');
  }, []);

  return null;
}

export default withLocalize(Translations);
