import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { longLabelByIdEn, longLabelByIdEs } from '../../constants/steps';
import { englishIsActiveSelector } from '../../selectors/translations';
import { BeigeTile, Header } from '../general/BeigeTile';
import { Button } from '../general/Common';
import { HelpText } from '../general/HelpText';
import { TileBackArrow } from '../general/TileBackArrow';
import { FilingContent } from '../informational/FilingContent';

export const File = () => {
  const history = useHistory();
  const englishIsActive = useSelector(englishIsActiveSelector);
  const longLabelById = englishIsActive ? longLabelByIdEn : longLabelByIdEs;

  return (
    <BeigeTile>
      <Header>
        <TileBackArrow onClick={() => history.push('/steps/payment')} />
        {longLabelById.file}
      </Header>
      <FilingContent />
      <HelpText addHotlineLink={true} />
      <Button onClick={() => history.push('/steps/hearing')}>Next</Button>
    </BeigeTile>
  );
};
