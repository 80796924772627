import React from 'react';
import styled from 'styled-components';
import { withLocalize } from 'react-localize-redux';
import { MAX_DESKTOP_WIDTH, SHADOW_TINY } from '../../cssVars';
import { BUTTON_CLICK } from '../../constants/analytics';
import { trackEvent } from '../../utils/googleAnalytics';
import { useShowStickyButtons } from '../../hooks/useShowStickyButtons';

const LanguageButton = styled.button`
  border: none;
  background-color: #028DC6;
  color: white;
  font-weight: 600;
  font-size: 16px;
  padding: 6px 15px 7px 15px;
  border-radius: 20px 0px 0px 20px;
  ${({sticky, shiftDown}) => sticky 
    ? `position: fixed;
       top: ${shiftDown ? 100 : 10}px;
       right: 0px;
      `
    : `position: absolute;
       top: 10px;
       right: 0px;`}
  box-shadow: ${SHADOW_TINY};
  transition: background-color 0.2s ease;
  &:hover {
    background-color: #0070B8;
  }
  z-index: 10;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    top: ${({ shiftDown, sticky }) => (shiftDown && sticky) ? 90 : 0}px;
    padding: 4px 15px 5px 15px;
  }
`

const LanguageToggle = ({ activeLanguage, setActiveLanguage, onInfoPages }) => {
  const inEnglishMode = activeLanguage != null && activeLanguage.code === 'en';
  const sticky = useShowStickyButtons();

  const handleClick = () => {
    trackEvent(BUTTON_CLICK, { button_name: 'Language toggle', languaged_switched_to: inEnglishMode ? 'Spanish' : 'English' })
    setActiveLanguage(inEnglishMode ? 'es' : 'en')
  }

  return (
    <LanguageButton sticky={sticky} shiftDown={onInfoPages == null ? false : true} onClick={handleClick}>
      {inEnglishMode ? 'español' : 'english'}
    </LanguageButton>
  )
}

export default withLocalize(LanguageToggle);