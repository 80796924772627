import React from 'react';
import styled from 'styled-components';
import { SHADOW_TINY } from '../../cssVars';
import eligibleIcon from '../../assets/eligible.svg';
import ineligibleIcon from '../../assets/ineligible.svg';
import notYetEligibleIcon from '../../assets/not_yet_eligible.svg';
import couldNotDetermineIcon from '../../assets/could_not_determine.svg';
import { Translate } from 'react-localize-redux';
import { TextLink } from '../general/Common';
import { SITE_URL } from '../../constants/general';
import { TLH_ELIGIBILITY_REQS_LINK } from '../../constants/links';

const EligibilityTile = styled.div`
  border-radius: 4px;
  padding: ${({ large }) => (large ? '10px 13px' : '8px')};
  background-color: #9edfc4;
`;

const SecondaryText = styled.div`
  margin-top: 5px;
  font-size: 18px;
`;

const PrimaryText = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: ${({ large }) => (large ? 600 : 400)};
`;

const Icon = styled.img`
  width: 25px;
  height: 25px;
  margin-left: 10px;
`;
/*
  Description for components below:
    These are colorful divs used on case cards & modals to display eligibility status. 
    If `large` prop is true, additional text is shown.
*/

// Tile for eligible cases, with green coloring.
export const EligibleTile = ({ large }) => (
  <EligibilityTile
    large={large}
    style={{ backgroundColor: '#9EDFC4', color: '#0A4439', border: '2px solid #9EDFC4' }}
  >
    <PrimaryText large={large}>
      <Translate id="eligStatus" />
      <Icon src={eligibleIcon} alt="green checkmark" />
    </PrimaryText>
    {large && (
      <SecondaryText>
        <Translate id="eligNote" />
      </SecondaryText>
    )}
  </EligibilityTile>
);

// Tile for ineligible cases, with red coloring.
export const IneligibleTile = ({ large }) => (
  <EligibilityTile
    large={large}
    style={{ backgroundColor: '#E6BCB5', color: '#6E1100', border: '2px solid #E6BCB5' }}
  >
    <PrimaryText large={large}>
      <Translate id="ineligStatus" />
      <Icon src={ineligibleIcon} alt="" />
    </PrimaryText>
    {large && (
      <SecondaryText>
        <Translate id="ineligStatusNote1" />
        {` `}
        <TextLink style={{ color: '#6E1100', fontWeight: 600 }} to={TLH_ELIGIBILITY_REQS_LINK}>
          <Translate id="ineligStatusNote2" />
        </TextLink>
      </SecondaryText>
    )}
  </EligibilityTile>
);

// Tile for expunction eligible cases, with yellow coloring.
export const ExpunctionEligibleTile = ({ large }) => (
  <EligibilityTile
    large={large}
    style={{ backgroundColor: '#F5E6A5', color: '#4A3D0A', border: '2px solid #F5E6A5' }}
  >
    <PrimaryText large={large}>
      <Translate id="expStatus" />
      <Icon src={notYetEligibleIcon} alt="" />
    </PrimaryText>
    {large && (
      <SecondaryText>
        <Translate id="expNote1" />
        <TextLink
          style={{ color: '#4A3D0A', fontWeight: 600 }}
          to={`${SITE_URL}/info/nondisclosure-expunction`}
        >
          <Translate id="expNote2" />
        </TextLink>
      </SecondaryText>
    )}
  </EligibilityTile>
);

// Tile for not-yet-eligible cases, with yellow coloring (CURRENTLY NOT USED).
export const NotYetEligibleTile = ({ large }) => (
  <EligibilityTile large={large} style={{ backgroundColor: '#F5E6A5', color: '#4A3D0A' }}>
    <PrimaryText large={large}>
      Not yet eligible for nondisclosure. May become eligible on [TODO]
      <Icon src={notYetEligibleIcon} alt="" />
    </PrimaryText>
    {large && <SecondaryText>[TODO]</SecondaryText>}
  </EligibilityTile>
);

// Tile for cases that we couldn't determine the eligibility for, with yellow coloring (CURRENTLY NOT USED).
export const CouldNotDetermineTile = ({ large }) => (
  <EligibilityTile large={large} style={{ backgroundColor: '#F5E6A5', color: '#4A3D0A' }}>
    <PrimaryText large={large}>
      Could not determine eligibility
      <Icon src={couldNotDetermineIcon} alt="" />
    </PrimaryText>
    {large && <SecondaryText>[TODO]</SecondaryText>}
  </EligibilityTile>
);
