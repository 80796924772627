import { useSelector } from "react-redux";
import { additionalInfoOptionsEn, ageOptionsEn, didYouCompleteTheFormsOptionsEn, didYouFindWhatYouNeededOptionsEn, educationLevelOptionsEn, genderOptionsEn, helpfulnessOptionsEn, howEasyWasInfoToFindOptionsEn, howUserHeardAboutSiteOptionsEn, incomeRangeOptionsEn, monthOptionsEn, petitionFormOptionsEn, raceOptionsEn, suffixOptionsEn, timeToCompleteFormsOptionsEn, whereDidYouAccessWebsiteOptionsEn } from "../constants/formOptionsEn";
import { additionalInfoOptionsEs, ageOptionsEs, didYouCompleteTheFormsOptionsEs, didYouFindWhatYouNeededOptionsEs, educationLevelOptionsEs, genderOptionsEs, helpfulnessOptionsEs, howEasyWasInfoToFindOptionsEs, howUserHeardAboutSiteOptionsEs, incomeRangeOptionsEs, monthOptionsEs, petitionFormOptionsEs, raceOptionsEs, suffixOptionsEs, timeToCompleteFormsOptionsEs, whereDidYouAccessWebsiteOptionsEs } from "../constants/formOptionsEs";
import { englishIsActiveSelector } from "../selectors/translations";

export const useFormOptions = () => {
  const inEnglishMode = useSelector(englishIsActiveSelector);
  return {
    monthOptions: inEnglishMode ? monthOptionsEn : monthOptionsEs,
    suffixOptions: suffixOptionsEn, // This one doesn't need to be translated
    ageOptions: inEnglishMode ? ageOptionsEn : ageOptionsEs,
    genderOptions: inEnglishMode ? genderOptionsEn : genderOptionsEs,
    raceOptions: inEnglishMode ? raceOptionsEn : raceOptionsEs,
    helpfulnessOptions: inEnglishMode ? helpfulnessOptionsEn : helpfulnessOptionsEs,
    didYouFindWhatYouNeededOptions: inEnglishMode ? didYouFindWhatYouNeededOptionsEn : didYouFindWhatYouNeededOptionsEs,
    howEasyWasInfoToFindOptions: inEnglishMode ? howEasyWasInfoToFindOptionsEn : howEasyWasInfoToFindOptionsEs,
    whereDidYouAccessWebsiteOptions: inEnglishMode ? whereDidYouAccessWebsiteOptionsEn : whereDidYouAccessWebsiteOptionsEs,
    additionalInfoOptions: inEnglishMode ? additionalInfoOptionsEn : additionalInfoOptionsEs,
    didYouCompleteTheFormsOptions: inEnglishMode ? didYouCompleteTheFormsOptionsEn : didYouCompleteTheFormsOptionsEs,
    petitionFormOptions: inEnglishMode ? petitionFormOptionsEn : petitionFormOptionsEs,
    timeToCompleteFormsOptions: inEnglishMode ? timeToCompleteFormsOptionsEn : timeToCompleteFormsOptionsEs,
    howUserHeardAboutSiteOptions: inEnglishMode ? howUserHeardAboutSiteOptionsEn : howUserHeardAboutSiteOptionsEs,
    educationLevelOptions: inEnglishMode ? educationLevelOptionsEn : educationLevelOptionsEs,
    incomeRangeOptions: inEnglishMode ? incomeRangeOptionsEn : incomeRangeOptionsEs
  }
}