import { 
  BAC_UNDER_015, 
  COMMITTED_SOLELY_AS_TRAFFICKING_VICTIM, 
  COMMITTED_AFTER_SUBMITTING_PETITION, 
  HAD_COMMERCIAL_LICENSE_OR_PERMIT, 
  USED_IGNITION_INTERLOCK,
  FINAL_POT_ELIG_411_0731,
  FINAL_POT_ELIG_411_0736,
} from "../constants/cases";

export const validateInitialForm = (values) => {
  const errors = {};

  // required string fields
  const requiredFields = ['firstName', 'lastName', 'dob'];

  requiredFields.forEach(fieldName => {
    if (!values[fieldName]) {
      errors[fieldName] = 'Required'
    }
  })

  if (values.agreeToTerms === false) {
    errors.agreeToTerms = 'Must agree to terms to continue'
  }


  return errors;
};

export const validateConfirmCases = (values) => {
  const errors = {};

  if (values.areTheseYourCases == null) {
    errors.areTheseYourCases = 'Required'

  }

  return errors;
}

export const validateFollowupQuestions = (currCase) => (values) => {
  
  // Loop through all values
  const errors = Object.keys(values).reduce((errorsObj, valueId) => {

    // Initially set all values that are "null" to have "Required" error
    if (values[valueId] == null) {
        errorsObj[valueId] = "Required";
    }

    // We only want to ask about the ignition interlock if they are
    // flagged as potentially eligible (if the timing is between the shortened and full waiting period) and have a qualifying BAC
    const skipIgnitionInterlock = values[BAC_UNDER_015] === false || (!currCase[FINAL_POT_ELIG_411_0731] && !currCase[FINAL_POT_ELIG_411_0736]);

    // If the value is a child (we only show it if a parent is true)
    // and the parent is false, remove error for it
    if ( (valueId === HAD_COMMERCIAL_LICENSE_OR_PERMIT && values[BAC_UNDER_015] === false)
      || (valueId === USED_IGNITION_INTERLOCK && skipIgnitionInterlock)
      || (valueId === COMMITTED_AFTER_SUBMITTING_PETITION && values[COMMITTED_SOLELY_AS_TRAFFICKING_VICTIM] === false)) {
      delete errorsObj[valueId];
    } 

    return errorsObj;
  }, {});

  return errors;
};

export const validateEmails = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
}

export const validateHelpForm = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.phone) {
    errors.phone = 'Required';
  }
  if (!values.description) {
    errors.description = 'Required';
  }
  return errors;
}

export const validateSurvey = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
}

