/* Variables to keep track of analytics categories and common events so they're kept consistent */

// CATEGORIES
export const NAVIGATION = 'Navigation'
export const FAQ = 'FAQ'
export const LINK_CLICK = 'Link click'
export const BUTTON_CLICK = 'Button click'
export const HELP_CLICK_CORNER = 'Corner Help Button Click'
export const HELP_CLICK_INLINE = 'Inline Help Button Click'
export const MODALS = 'Modals'

// EVENT NAMES
export const CLICKED_DEEP_DIVE_TILE = 'Clicked "Deep Dive" tile'
export const OPENED_MENU = 'Opened menu'
