import React from 'react';
import styled from 'styled-components';
import { Route, Switch, useHistory } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { NondisclosureExpunction } from './NondisclosureExpunction';
import { Translate } from 'react-localize-redux';
import efileImg from '../../assets/blackSheep/filing.png'
import nondisclosureExpunctionImg from '../../assets/blackSheep/nondisc_expunc.png'
import { BG_LIGHTER, MAIN_CONTENT_WIDTH, MAX_DESKTOP_WIDTH, SHADOW_DARK } from '../../cssVars';
import { HowToFile } from './HowToFile';
import { BackArrowButton } from '../general/Common';
import { FAQPage } from './FAQPage';
import { trackEvent } from '../../utils/googleAnalytics';
import { NAVIGATION } from '../../constants/analytics';
import filingIcon from '../../assets/blackSheep/folder.png'
import birdIcon from '../../assets/blackSheep/bird2.png'
import faqIcon from '../../assets/blackSheep/cloud2.png'

const TileLink = styled(Link)`
  border-radius: 6px;
  flex-grow: 1;
  flex-basis: 0;
  margin-right: 20px;
  background-color: ${BG_LIGHTER};
  > img {
    width: 100%;
  }
  overflow: hidden;
  box-shadow: 0 3px 4px rgba(0,0,0,.2);
  text-align: left;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    margin-right: 0px;
    margin-bottom: 20px;
  }
  text-decoration: none;
  color: #37352B;
  transition: all 0.2s ease;
  &:hover {
    box-shadow: ${SHADOW_DARK};
  }
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    flex-grow: 0;
    flex-basis: auto;
  } 
`

const TilesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    flex-direction: column;
  } 
`

const TilesText = styled.div`
  padding: 15px;
  padding-top: 10px;
  > div:first-child {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 20px;
  }
  > div:last-child {
    font-style: italic;
    color: #44433d;
  }
`

const DeepDiveImg = styled.img`
  width: 100%;
`;

export const DeepDiveTiles = () => (
  <TilesWrapper>
    <TileLink to="/info/nondisclosure-expunction" 
              onClick={() => trackEvent(NAVIGATION, { deep_dive_name: `Nondisclosure & Expunction` })}>
      <DeepDiveImg src={nondisclosureExpunctionImg} loading="lazy" alt="" />
      <TilesText>
        <div><Translate id="nondisclosureExp"/> </div>
        <div><Translate id="nondisclosureExpBlurb"/></div>
      </TilesText>
    </TileLink>
    <TileLink to="/info/how-to-file"
              onClick={() => trackEvent(NAVIGATION, { deep_dive_name: `Filing in Harris County` })}>
      <DeepDiveImg src={efileImg} loading="lazy" alt="" />
      <TilesText>
        <div><Translate id="filingInHC"/> </div>
        <div><Translate id="filingInHCBlurb"/></div>
      </TilesText>
    </TileLink>
  </TilesWrapper>
)

const HeaderWrapper = styled.div`
  padding-top: 60px;
  margin-bottom: 20px;
  > a {
    &:focus { box-shadow: none; }
  }
`

const StyledHeader = styled.h1`
  font-size: 30px;
  font-weight: 600;
  position: relative;
  z-index: 0;
  > div {
    position: relative;
    z-index: 1;
  }
  > img {
    position: absolute;
    top: -50px;
    right: 0px;
    max-width: 120px;
    z-index: 0;
    opacity: 1;
    @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
      opacity: 0.2;
    } 
  }
`

export const DeepDiveWrapper = styled.div`
  width: ${MAIN_CONTENT_WIDTH}px;
  width: min(${MAIN_CONTENT_WIDTH}px, 90%);
  margin: 0px auto;
  padding-bottom: 40px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    width: 90%;
    width: min(${MAIN_CONTENT_WIDTH}px, 90%);
  } 
`;

export const DeepDiveHeader = ({ titleId, filing, faq }) => {
  const history = useHistory();
  return (
    <HeaderWrapper>
      <BackArrowButton onClick={() => history.length > 1 ? history.goBack() : history.push('/')} />
      <StyledHeader>
        <div><Translate id={titleId} /></div>
        {filing ? <img src={filingIcon} alt="" /> : faq ? <img src={faqIcon} alt="" /> : <img src={birdIcon} alt="" />}
      </StyledHeader>
    </HeaderWrapper>
  )
}

export const DeepDives = () => {
  return (
    <Switch>
      <Route path="/info/nondisclosure-expunction" render={NondisclosureExpunction} />
      <Route path="/info/how-to-file" render={HowToFile} />
      <Route path="/info/faq" render={FAQPage} />
    </Switch>
  )
}
