import React from 'react';
import styled from 'styled-components';
import useDates from '../../hooks/useDates.js';
import { useHistory } from 'react-router-dom';
import { usePreCheck } from '../../hooks/editors.js';
import { Formik } from 'formik';
import { BeigeTile, Header } from '../general/BeigeTile.js';
import { Translate } from 'react-localize-redux';
import { YesNoField } from '../general/FormFields.js';
import { Button, TextLink } from '../general/Common.js';
import { FRESH_START_LINK } from '../../constants/links.js';
import { TileBackArrow } from '../general/TileBackArrow.js';
import { SHADOW_TINY } from '../../cssVars.js';

const Text = styled.p`
  margin: 10px 0px;
`;

const ImportantTile = styled.div`
  border-radius: 5px;
  padding: 10px 13px;
  background-color: #ecd893;
  font-size: 17px;
  box-shadow: ${SHADOW_TINY};
`;

const finalNote = (hasCrimeOutsideTexas, hasCrimeBefore1990) => {
  if (hasCrimeOutsideTexas && hasCrimeBefore1990) {
    return (
      <>
        <Translate id="havingChargesOutsideHC" />
        {` `}
        <Translate id="and" />
        {` `}
        <Translate id="havingChargesBefore1990" />
      </>
    );
  } else if (hasCrimeOutsideTexas) {
    return (
      <>
        <Translate id="havingChargesOutsideHC" />
        {`.`}
      </>
    );
  } else {
    return <Translate id="havingChargesBefore1990" />;
  }
};

// First form users sees in the eligibility checker flow, it asks:
// 1. Whether the user has ever been arrested for a crime before January 1, 1990
// 2. Whether the user has ever been arrested for a crime outside of Harris County
export const PreCheck = () => {
  const { startDate, endDate } = useDates();
  const history = useHistory();
  const [submit, submitting] = usePreCheck(() => history.push('/check/basic-info'));

  return (
    <Formik
      initialValues={{
        hasCrimeBefore1990: null,
        hasCrimeOutsideTexas: null,
      }}
      enableReinitialize={true}
      onSubmit={submit}
    >
      {({ handleSubmit, values }) => {
        return (
          <BeigeTile>
            <Header>
              <TileBackArrow onClick={() => history.push('/')} />
              <Translate id="checkEligibility" />
            </Header>

            <Text style={{ marginBottom: '20px' }}>
              <Translate id="initialEligibilityIntro1" data={{ startDate, endDate }} />
              <TextLink to={FRESH_START_LINK}>
                <Translate id="freshStart" />
              </TextLink>
              {` `}
              <Translate id="initialEligibilityIntro2" />
            </Text>

            {/* Have you ever been arrested for a crime outside of Harris County? */}
            <Text>
              <Translate id="otherCrimesQ" />*
            </Text>
            <YesNoField name="hasCrimeOutsideTexas" />

            {/* Have you ever been arrested for a crime before January 1, 1990? */}
            <Text>
              <Translate id="otherCrimesQDate" />*
            </Text>
            <YesNoField name="hasCrimeBefore1990" />

            {/* If either of these questions is answered negatively, display a warning suggesting the user to use contact an attorney */}
            {(values.hasCrimeBefore1990 || values.hasCrimeOutsideTexas) && (
              <ImportantTile>
                <Translate id="youRespondedYes" />
                {` `}
                {finalNote(values.hasCrimeOutsideTexas, values.hasCrimeBefore1990)}
                <div style={{ marginTop: '10px' }}>
                  <Translate id="ifTheseCharges" />
                </div>
              </ImportantTile>
            )}

            {/* When both questions are answered, the "Continue" button will appear. If either question is answered negatively, the button text will be "Continue anyway" */}
            {values.hasCrimeBefore1990 !== null && values.hasCrimeOutsideTexas !== null && (
              <Button
                type="submit"
                fillOnMobile={true}
                isLoading={submitting}
                style={{ minWidth: '260px', marginTop: '20px' }}
                onClick={handleSubmit}
              >
                {values.hasCrimeBefore1990 || values.hasCrimeOutsideTexas ? (
                  <Translate id="continueAnyway" />
                ) : (
                  <Translate id="continue" />
                )}
              </Button>
            )}
          </BeigeTile>
        );
      }}
    </Formik>
  );
};
