import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Translate } from 'react-localize-redux';
import triangleImg from '../../assets/triangle.svg';
import { FAQ } from '../../constants/analytics';
import { SERVER_URL } from '../../constants/general';
import {
  ACROBAT_LINK,
  BEACON_LINK,
  CLEAN_SLATE_TX_LINK,
  FRESH_START_LINK,
  HCDC_SEARCH_LINK,
  HVL_LINK,
  LEGAL_LINE_LINK,
  TLH_DOCS_NEEDED_LINK,
  TLH_EXPUNCTION_LINK,
  TLH_MAIN_LINK,
  TLH_NDO_FORMS_LINK,
  TLH_NDO_TYPES_LINK,
} from '../../constants/links';
import { SECONDARY } from '../../cssVars';
import { trackEvent } from '../../utils/googleAnalytics';
import { TextLink } from '../general/Common';
import useDates from '../../hooks/useDates';
import englishTranslations from '../../translations/en.json';
import { useSelector } from 'react-redux';
import { englishIsActiveSelector } from '../../selectors/translations';

const Wrapper = styled.div`
  text-align: left;
  width: 90%;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const QuestionLine = styled.button`
  display: flex;
  cursor: pointer;
  user-select: none;
  border: none;
  background-color: transparent;
  color: ${SECONDARY};
  padding: 0px;
  &:focus {
    box-shadow: none;
  }
`;

const Question = styled.div`
  font-weight: 600;
  font-size: 18px;
  text-align: left;
`;

const Answer = styled.p`
  color: #2a2928;
  font-size: 18px;
  margin: 10px 0px;
  margin-left: 20px;
  display: ${({ open }) => (open ? 'block' : 'none')};
  overflow: hidden;
`;

const Triangle = styled.img`
  height: 10px;
  width: 7px;
  margin-right: 15px;
  margin-top: 8px;
  transition: transform 0.2s ease;
  transform: rotate(${({ open }) => (open ? 90 : 0)}deg);
`;

const QA = ({ question: questionId, children }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    trackEvent(FAQ, { faq_question_name: englishTranslations[questionId] });
    setOpen(!open);
  };

  return (
    <Section>
      <QuestionLine onClick={handleClick}>
        <Triangle open={open} src={triangleImg} alt="small chevron" />
        <Question>
          <Translate id={questionId} />
        </Question>
      </QuestionLine>
      <Answer open={open}>{children}</Answer>
    </Section>
  );
};

// Shared Questions
const IsThisSiteFree = () => (
  <QA question="isSiteFree">
    <Translate id="isSiteFreeAns" />{' '}
    <Link to="/steps/review">
      <Translate id="isSiteFreeAns2" />
    </Link>
    .
  </QA>
);

const IsThisASecureSite = ({ showSeeDetailsNote }) => (
  <QA question="isSiteSecure">
    <Translate id="isSiteSecureAns" /> <TextLink to={BEACON_LINK}>The Beacon</TextLink>
    {` `}
    <Translate id="isSiteSecureAns2" />{' '}
    {showSeeDetailsNote ? <Translate id="isSiteSecureAns3" /> : ''}.
  </QA>
);

const WhoCreatedThisSite = () => (
  <QA question="whoCreated">
    <Translate id="whoCreatedAns" /> <TextLink to={BEACON_LINK}>The Beacon</TextLink>{' '}
    <Translate id="whoCreatedAns2" />
  </QA>
);

// Question Groups
export const WebsiteFAQ = () => {
  return (
    <Wrapper>
      <WhoCreatedThisSite />
      <IsThisSiteFree />
      <QA question="whoCanUse">
        <Translate id="whoCanUseAns" />
      </QA>
      <QA question="adultCrim">
        <Translate id="adultCrimAns" />
      </QA>
      <IsThisASecureSite showSeeDetailsNote={true} />
      <QA question="willInfoBeSold">
        <Translate id="willInfoBeSoldAns" />
      </QA>
    </Wrapper>
  );
};

export const ShortFAQ1 = () => {
  return (
    <Wrapper style={{ padding: '0px 20px ' }}>
      <QA question="whatIsNondisc">
        <Translate id="whatIsNondiscAns" />{' '}
        <Link to="/info/nondisclosure-expunction">
          <Translate id="here" />
        </Link>
        .
      </QA>
      <IsThisSiteFree />
    </Wrapper>
  );
};

export const ShortFAQ2 = () => {
  return (
    <Wrapper style={{ padding: '0px 20px ' }}>
      <IsThisASecureSite />
      <WhoCreatedThisSite />
    </Wrapper>
  );
};

export const NondisclosureFAQ = () => {
  const { startDate } = useDates();
  const englishIsActive = useSelector(englishIsActiveSelector);
  const hcdcInstLink = `${SERVER_URL}/pdfs/HCDC_accessing_records_${
    englishIsActive ? 'en' : 'es'
  }.pdf`;
  return (
    <Wrapper>
      <QA question="whatIsANondisc">
        <Translate id="whatIsANondiscAn1" />{' '}
        <Link to="/info/nondisclosure-expunction">
          <Translate id="whatIsANondiscAn2" />
        </Link>
        .
      </QA>
      <QA question="otherPlaces">
        <Translate id="otherPlacesAns" />
        <TextLink to={FRESH_START_LINK}>
          <Translate id="freshStart" />
        </TextLink>
        ,{` `}
        <Translate id="otherPlacesAns2" />{' '}
        <TextLink to={TLH_NDO_FORMS_LINK}>Texas Law Help</TextLink>.{` `}
        <Translate id="otherPlacesAns3" />
      </QA>
      <QA question="allHist">
        <Translate id="allHistAns" />{' '}
        <TextLink to={TLH_NDO_TYPES_LINK}>
          <Translate id="allHistAns2" />
        </TextLink>{' '}
        <Translate id="allHistAns3" />
        <br />
        <br />
        <Translate id="allHistAns4" />{' '}
        <TextLink to={CLEAN_SLATE_TX_LINK}>Clean Slate Texas</TextLink>{' '}
        <Translate id="allHistAns5" />
      </QA>
      <QA question="whyNotExp">
        <Translate id="whyNotExpAns" /> <TextLink to={TLH_EXPUNCTION_LINK}>Texas Law Help</TextLink>
        .
      </QA>
      <QA question="newOffense">
        <Translate id="newOffenseAns" />
      </QA>
      <QA question="warrant">
        <Translate id="warrantAns" />
      </QA>
      <QA question="appeal">
        <Translate id="appealAns" />
      </QA>
      <QA question="probation">
        <Translate id="probationAns" />
      </QA>
      <QA question="greenCard">
        <Translate id="greenCardAns" />
      </QA>
      <QA question="efiling">
        <Translate id="efilingAns" />
      </QA>
      <QA question="pre1990">
        <Translate id="pre1990Ans" data={{ startDate }} />
      </QA>
      <QA question="cMisd">
        <Translate id="cMisdAns" />
      </QA>
      <QA question="save">
        <Translate id="saveAns" />
        <br />
        <br />
        <Translate id="saveAns" /> <TextLink to={ACROBAT_LINK}>www.acrobat.com</TextLink>.
      </QA>
      <QA question="mail">
        <Translate id="mailAns" />
      </QA>
      <QA question="infoNeeded">
        <TextLink to={TLH_DOCS_NEEDED_LINK}>Texas Law Help</TextLink>{' '}
        <Translate id="infoNeededAns" />{' '}
        <TextLink to={HCDC_SEARCH_LINK}>
          <Translate id="infoNeededAns2" />
        </TextLink>
        {` `}
        <Translate id="infoNeededAns3" />{' '}
        <TextLink to={hcdcInstLink}>
          <Translate id="here" />
        </TextLink>{' '}
        <Translate id="infoNeededAns4" />
      </QA>
      <QA question="howLong">
        <Translate id="howLongAns" />
      </QA>
      <QA question="questions">
        <Translate id="questionsAns" /> <TextLink to={TLH_MAIN_LINK}>Texas Law Help</TextLink>.
        <Translate id="questionsAns2" />{' '}
        <TextLink to={HVL_LINK}>Houston Volunteer Lawyers</TextLink> <Translate id="and" />
        {` `}
        <TextLink to={LEGAL_LINE_LINK}>LegalLine</TextLink>.
      </QA>
    </Wrapper>
  );
};
