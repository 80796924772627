import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { clearStore } from '../actions/clearStore';
import { englishIsActiveSelector } from '../selectors/translations';
import enTranslations from '../translations/en.json'
import esTranslations from '../translations/es.json'

// Returns function to clear state and redirect to the homepage with an error message
// For use when an unauthorized status is found
export const useHandleUnauthorized = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const englishIsActive = useSelector(englishIsActiveSelector)

  return () => {
    dispatch(clearStore());
    toast.info(englishIsActive ? enTranslations.sessionExpiredMsg : esTranslations.sessionExpiredMsg);
    history.push('/');
  }
}
