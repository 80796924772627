import React from 'react';
import { Beforeunload } from 'react-beforeunload';
import { Redirect, Route, Switch } from 'react-router-dom';
import { eligibilityInfoSelector, tokenSelector } from '../../selectors/entities';
import Header from '../general/Header';
import { PreCheck } from './PreCheck';
import { BasicInfo } from './BasicInfo';
import { AdditionalInfo } from './AdditionalInfo';
import { Results } from './Results';
import { ConfirmCases } from './ConfirmCases';
import { useSelector } from 'react-redux';
import { KnowYourRights } from './KnowYourRights';
import { CaseFollowupQuestionsRoutes } from './CaseFollowupQuestions';
import LanguageToggle from '../general/LanguageToggle';
import { DemoInstructionsModal } from '../general/DemoInstructionsModal';
import { HelpButton } from '../general/HelpButton';

// Root component for all of the "Check eligibility" pages.
// Includes the initial form, case confirmation, followup questions, & eligibility results.
export const CheckRoot = () => {
  // Want to show refresh warning if there's an active token in state while on any of the "check" pages
  const showRefreshWarning = useSelector(tokenSelector) != null;
  const eligibilityInfo = useSelector(eligibilityInfoSelector);
  const hasEligibilityInfo = Object.keys(eligibilityInfo).length > 0;
  return (
    <>
      <DemoInstructionsModal />
      <div style={{ backgroundColor: 'rgba(0,0,0,0.1)', minHeight: '100vh', textAlign: 'center' }}>
        {showRefreshWarning && <Beforeunload onBeforeunload={event => event.preventDefault()} />}
        <Header titleId="menuChecker" />
        <Switch>
          <Route path="/check/pre-check" component={PreCheck} />
          <Route path="/check/basic-info" component={BasicInfo} />
          <Route path="/check/know-your-rights" component={KnowYourRights} />
          {hasEligibilityInfo && (
            <>
              <Route path="/check/confirm-cases" component={ConfirmCases} />
              <Route path="/check/additional-info" component={AdditionalInfo} />
              <Route path="/check/case-verifications" component={CaseFollowupQuestionsRoutes} />
              <Route path="/check/results" component={Results} />
            </>
          )}
          <Redirect to="/check/pre-check" />
        </Switch>
        <LanguageToggle />
        <HelpButton />
      </div>
    </>
  );
};
