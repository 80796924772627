import React from 'react';
import { Translate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TLH_AFTER_ORDER_LINK } from '../../constants/links';
import { longLabelByIdEn, longLabelByIdEs } from '../../constants/steps';
import { englishIsActiveSelector } from '../../selectors/translations';
import { BeigeTile, BottomSpacedHeader, Text } from '../general/BeigeTile';
import { Button, StyledHR, TextLink } from '../general/Common';
import { TileBackArrow } from '../general/TileBackArrow';

export const Order = () => {
  const history = useHistory();
  const englishIsActive = useSelector(englishIsActiveSelector);
  const longLabelById = englishIsActive ? longLabelByIdEn : longLabelByIdEs;

  return (
    <BeigeTile>
      <BottomSpacedHeader>
        <TileBackArrow onClick={() => history.push('/steps/hearing')} />
        {longLabelById.order}
      </BottomSpacedHeader>
      <Text>
        <Translate id='order1' /> <TextLink to={TLH_AFTER_ORDER_LINK}>
        < Translate id='here' /></TextLink>.
      </Text>
      <Text style={{ wordBreak: 'break-word'}}>
        <Translate id='order2' />, {` `}<TextLink to="tel:832-927-5900">832-927-5900</TextLink> <Translate id='andOr' />{` `}
        <a href = "mailto:criminalcustomerservice@hcdistrictclerk.com?subject=Request Copy of Nondisclosure Order">
          criminalcustomerservice@hcdistrictclerk.com
        </a>.
      </Text>
      <StyledHR style={{ margin: '30px 0px' }}  />
      <Text>
        <Translate id='order3' />
      </Text>
      <Button style={{ marginTop: '10px' }} onClick={() => history.push({ pathname: '/', search: '?modal=survey' })}>
        <Translate id='finish' />
      </Button>
    </BeigeTile>
  )
}
