import React from 'react';
import { useHistory } from 'react-router-dom';
import { TLH_INABILITY_TO_PAY_LINK } from '../../constants/links';
import { BeigeTile, BottomSpacedHeader, Text } from '../general/BeigeTile';
import { Button, TextLink } from '../general/Common';
import { TileBackArrow } from '../general/TileBackArrow';
import { Translate } from 'react-localize-redux';
import { englishIsActiveSelector } from '../../selectors/translations';
import { useSelector } from 'react-redux';
import { longLabelByIdEn, longLabelByIdEs } from '../../constants/steps';
import { HelpText } from '../general/HelpText';

export const Payment = () => {
  const history = useHistory();
  const englishIsActive = useSelector(englishIsActiveSelector);
  const longLabelById = englishIsActive ? longLabelByIdEn : longLabelByIdEs;

  return (
    <BeigeTile>
      <BottomSpacedHeader>
        <TileBackArrow onClick={() => history.push('/steps/petition')} />
        {longLabelById.payment}
      </BottomSpacedHeader>
      <Text>
        <Translate id="payment1" />
        <TextLink to={TLH_INABILITY_TO_PAY_LINK}>https://texaslawhelp.org/form/statement-inability-afford-payment-court-costs-or-
  appeal-bond</TextLink>
      </Text>
      <Text>
        <Translate id="payment2" /><TextLink to="tel:832-927-5800">832-927-5800</TextLink>.{` `}
        <Translate id="payment3" />
      </Text>
      <HelpText />
      <Button wrapperStyle={{ marginTop: '20px' }} onClick={() => history.push('/steps/file')}>
        <Translate id="next" />
      </Button>
    </BeigeTile>
  )
}
