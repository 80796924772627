import { MODALS } from '../constants/analytics';
import { trackEvent } from '../utils/googleAnalytics';

export const SET_CASE_MODAL = 'SET_CASE_MODAL';
export const SET_DISCLAIMER_MODAL = 'SET_DISCLAIMER_MODAL';
export const SET_ELIGIBILITY_DISCLAIMER_MODAL = 'SET_ELIGIBILITY_DISCLAIMER_MODAL';
export const SET_PRIVACY_MODAL = 'SET_PRIVACY_MODAL';
export const SET_EMAIL_RESULTS_MODAL = 'SET_EMAIL_RESULTS_MODAL';
export const SET_EMAIL_PETITIONS_MODAL = 'SET_EMAIL_PETITIONS_MODAL';
export const SET_EMAIL_KNOW_YOUR_RIGHTS_MODAL = 'SET_EMAIL_KNOW_YOUR_RIGHTS_MODAL';
export const SET_OTHER_NAMES_MODAL = 'SET_OTHER_NAMES_MODAL';
export const SET_SURVEY_MODAL = 'SET_SURVEY_MODAL';
export const SET_ABOUT_US_MODAL = 'SET_ABOUT_US_MODAL';
export const SET_VETS_REEMPLOYMENT_MODAL = 'SET_VETS_REEMPLOYMENT_MODAL';
export const SET_VETS_TREATMENT_CRT_MODAL = 'SET_VETS_TREATMENT_CRT_MODAL';
export const SET_GUIDED_FORM_MODAL = 'SET_GUIDED_FORM_MODAL';
export const SET_DEMO_INSTRUCTIONS_MODEL = 'SET_DEMO_INSTRUCTIONS_MODEL';
export const SET_HELP_MODAL = 'SET_HELP_MODAL';

// Shows case details modal for `selectedCase` if non-null
export const setCase = selectedCase => {
  trackEvent(MODALS, {
    modal_name: 'Case details modal',
    opened: selectedCase ? 'opened' : 'closed',
  });
  return { type: SET_CASE_MODAL, payload: selectedCase };
};

export const setEligibilityDisclaimerModal = showDisclaimer => {
  trackEvent(MODALS, { modal_name: 'Eligibility disclaimer modal', opened: showDisclaimer });
  return { type: SET_ELIGIBILITY_DISCLAIMER_MODAL, payload: showDisclaimer };
};

export const setDisclaimerModal = showDisclaimer => {
  trackEvent(MODALS, { modal_name: 'Disclaimer modal', opened: showDisclaimer });
  return { type: SET_DISCLAIMER_MODAL, payload: showDisclaimer };
};

export const setPrivacyModal = showPrivacyModal => {
  trackEvent(MODALS, { modal_name: 'Privacy modal', opened: showPrivacyModal });
  return { type: SET_PRIVACY_MODAL, payload: showPrivacyModal };
};

export const setEmailResultsModal = showEmailResultsModal => {
  trackEvent(MODALS, { modal_name: 'Email results modal', opened: showEmailResultsModal });
  return { type: SET_EMAIL_RESULTS_MODAL, payload: showEmailResultsModal };
};

export const setEmailPetitionsModal = showEmailPetitionsModal => {
  trackEvent(MODALS, { modal_name: 'Eamil petitions modal', opened: showEmailPetitionsModal });
  return { type: SET_EMAIL_PETITIONS_MODAL, payload: showEmailPetitionsModal };
};

export const setEmailKnowYourRightsModal = setEmailKnowYourRightsModal => {
  trackEvent(MODALS, {
    modal_name: 'Email Know Your Rights modal',
    opened: setEmailKnowYourRightsModal,
  });
  return { type: SET_EMAIL_KNOW_YOUR_RIGHTS_MODAL, payload: setEmailKnowYourRightsModal };
};

export const setSurveyModal = showSurvey => {
  trackEvent(MODALS, { modal_name: 'Survey modal', opened: showSurvey });
  return { type: SET_SURVEY_MODAL, payload: showSurvey };
};

export const setAboutUsModal = showAboutUs => {
  trackEvent(MODALS, { modal_name: 'About us modal', opened: showAboutUs });
  return { type: SET_ABOUT_US_MODAL, payload: showAboutUs };
};

export const setVeteransReemploymentPrgModal = showModal => {
  trackEvent(MODALS, { modal_name: 'Veterans reemployment program modal', opened: showModal });
  return { type: SET_VETS_REEMPLOYMENT_MODAL, payload: showModal };
};

export const setVeteransTreatmentCrtModal = showModal => {
  trackEvent(MODALS, { modal_name: 'Veterans treatment court modal', opened: showModal });
  return { type: SET_VETS_TREATMENT_CRT_MODAL, payload: showModal };
};

export const setGuidedFormModal = showModal => {
  trackEvent(MODALS, { modal_name: 'Guided form modal', opened: showModal });
  return { type: SET_GUIDED_FORM_MODAL, payload: showModal };
};

export const setDemoInstructionsModal = showDemoInstructions => {
  trackEvent(MODALS, { modal_name: 'Demo instructions modal', opened: showDemoInstructions });
  return { type: SET_DEMO_INSTRUCTIONS_MODEL, payload: showDemoInstructions };
};

export const setHelpModal = showModal => {
  trackEvent(MODALS, { modal_name: 'Help modal', opened: showModal ? 'opened' : 'closed' });
  return { type: SET_HELP_MODAL, payload: showModal };
};
