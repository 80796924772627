import { createSelector } from 'reselect';
import { PENDING_POT_ELIG_411_0727, PENDING_POT_ELIG_411_0728, PENDING_POT_ELIG_411_0729 } from '../constants/cases';
import { getFormsNeededForFollowUp } from '../utils/cases';

export const tokenSelector = (state) => state.entities.token;

// Returns the time at which the token expires in milliseconds
export const tokenExpirationTimeSelector = (state) => state.entities.tokenExpirationTime;

export const emailSelector = (state) => state.entities.email || '';

export const eligibilityInfoSelector = (state) => state.entities.eligibilityInfo || {};

export const searchValuesSelector = (state) => state.entities.searchValues;

export const postPreliminaryCasesSelector = (state) => state.entities.postPreliminaryCases || [];

export const hasCrimeOutsideTexasSelector = (state) => state.entities.hasCrimeOutsideTexas;
export const hasCrimeBefore1990Selector = (state) => state.entities.hasCrimeBefore1990;

export const lookupEndDateEnSelector = (state) => state.entities.lookupEndDateEn;
export const lookupEndDateEsSelector = (state) => state.entities.lookupEndDateEs;

export const needSPNSelector = createSelector(
  [eligibilityInfoSelector],
  eligibilityInfo => eligibilityInfo.needSPN
);

// Returns all cases from BE response
export const allCasesSelector = createSelector(
  [eligibilityInfoSelector],
  eligibilityInfo => eligibilityInfo.cases || []
);

// Returns all cases with a "hide status" flag
export const allCasesHiddenStatusSelector = createSelector(
  [allCasesSelector],
  allCases => allCases.map(curCase => ({ ...curCase, hideStatus: true }))
);

export const getEligibleCases = (cases) => 
  Array.isArray(cases) ? cases.filter((curCase) => curCase.final_eligibility === 'nondisclosure') : [];

// Returns only nondisclosure eligible cases
export const eligibleCasesSelector = createSelector(
  [allCasesSelector],
  allCases => getEligibleCases(allCases)
);

// Returns all cases that are not eligible for nondisclosure
export const ineligibleCasesSelector = createSelector(
  [allCasesSelector],
  allCases => allCases.filter((curCase) => curCase.final_eligibility !== 'nondisclosure')
);

// Returns 'ineligible' marked cases
export const purelyIneligibleCasesSelector = createSelector(
  [allCasesSelector],
  allCases => allCases.filter((curCase) => curCase.final_eligibility === 'ineligible')
);

// Returns expunction eligible cases
export const expunctionEligibleCasesSelector = createSelector(
  [allCasesSelector],
  allCases => allCases.filter((curCase) => curCase.final_eligibility === 'expunction')
);

// Returns not-yet-eligible cases
export const notYetEligibleCasesSelector = createSelector(
  [allCasesSelector],
  allCases => allCases.filter((curCase) => curCase.final_eligibility === 'not_yet_eligible')
);

// Returns could-not-determine-eligibility cases
export const couldNotDetermineCasesSelector = createSelector(
  [allCasesSelector],
  allCases => allCases.filter((curCase) => curCase.final_eligibility === 'could_not_determine')
);

// SPN Grouped Cases (used for confirm cases page)
// Returns an array of case arrays (each sub-array containing cases with the same SPN)
export const spnGroupedCasesSelector = createSelector(
  [allCasesSelector],
  (allCases) => {
    return allCases.reduce((spnGroupedCases, curCase) => {
      // For the confirm cases page, we don't want to show eligibility statuses, so adding flag
      const caseToAdd = { ...curCase, hideStatus: true };
      // Check if there are any existing arrays grouped by the current case's SPN
      const indexOfMatchingSpnArray = spnGroupedCases.findIndex(
        (caseArr) => caseArr.length > 0 && caseArr[0].def_spn === caseToAdd.def_spn
      );
      // If there is no existing index, add a new array with the given case.
      if (indexOfMatchingSpnArray < 0) {
        spnGroupedCases.push([caseToAdd]);
      } else {
        // Otherwise, add the case to the existing matching array
        spnGroupedCases[indexOfMatchingSpnArray].push(caseToAdd);
      }
      return spnGroupedCases;
    }, []);
  }
);

// Returns array of cases that need to show follow-up questions for NDO form determination.
// (hiding eligibility status on these cases, since we are still gathering eligibility info)
export const followUpQuestionCasesSelector = createSelector(
  [postPreliminaryCasesSelector], 
  (postPreliminaryCases) => postPreliminaryCases.map(curCase => ({ ...curCase, hideStatus: true })).filter(
    (currCase) => getFormsNeededForFollowUp(currCase).length > 0
  )
)

// Returns whether or not we should ask preliminary veteran questions
export const askVeteranQuestionsSelector = createSelector(
  [allCasesSelector], 
  (allCases) => {
    const askVetTreatmentCrtQuestions = allCases.some(
      (currCase) => currCase[PENDING_POT_ELIG_411_0727]
    )
    const askVetReemploymentPrgQuestions = allCases.some(
      (currCase) => currCase[PENDING_POT_ELIG_411_0729]
    )
    return [askVetTreatmentCrtQuestions || askVetReemploymentPrgQuestions, askVetTreatmentCrtQuestions, askVetReemploymentPrgQuestions]
  }
)

// Returns whether or not we should ask preliminary human trafficking victim questions
// (we should if the user is potentially eligible for 411.0728 in any case)
export const askTraffickingVictimQuestionsSelector = createSelector(
  [allCasesSelector], 
  (allCases) => allCases.some(
    (currCase) => currCase[PENDING_POT_ELIG_411_0728]
  )
)

