export const stepIds = ['review', 'petition', 'payment', 'file', 'hearing', 'order']


export const labelByIdEn = {
  review: 'Overview',
  petition: 'Fill out petition',
  payment: 'Fee waiver',
  file: 'File forms',
  hearing: 'Prep. for hearing',
  order: 'Obtain order',
}

export const shortLabelByIdEn = {
  review: 'Overview',
  petition: 'Petition',
  payment: 'Waiver',
  file: 'File',
  hearing: 'Hearing',
  order: 'Order',
}

export const longLabelByIdEn = {
  review: 'Overview of the nondisclosure process',
  petition: 'Fill out petition',
  payment: 'Fill out fee waiver',
  file: 'File your forms in Harris County',
  hearing: 'Prepare for hearing',
  order: 'Obtain order',
}


export const labelByIdEs = {
  review: 'Resumen',
  petition: 'Solicitud',
  payment: 'Exención',
  file: 'Declarar',
  hearing: 'Audiencia',
  order: 'Orden',
}

export const shortLabelByIdEs = {
  review: 'Resumen',
  petition: 'Solicitud',
  payment: 'Exención',
  file: 'Declarar',
  hearing: 'Audiencia',
  order: 'Orden',
}

export const longLabelByIdEs = {
  review: 'Descripción general del proceso de no divulgación',
  petition: 'Completar la solicitud',
  payment: 'Completar la exención de cuota',
  file: 'Presente sus formularios en el condado de Harris',
  hearing: 'Prepárese para la audiencia',
  order: 'Obtener la orden',
}
