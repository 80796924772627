export const monthOptionsEn = [
  {value: '', label:'Select month'},
  {value:'1', label:'January'},
  {value:'2', label:'February'},
  {value:'3', label:'March'},
  {value:'4', label:'April'},
  {value:'5', label:'May'},
  {value:'6', label:'June'},
  {value:'7', label:'July'},
  {value:'8', label:'August'},
  {value:'9', label:'September'},
  {value:'10', label:'October'},
  {value:'11', label:'November'},
  {value:'12', label:'December'},
]

// personal info

export const suffixOptionsEn = [
  {value:'', label:''},
  {value:'Jr', label:'Jr'},
  {value:'Sr', label:'Sr'},
  {value:'II', label:'II'},
  {value:'III', label:'III'},
  {value:'IV', label:'IV'} // TELL KELSEY - this was set to value "III"
]

export const ageOptionsEn = [
  {value:"Under 18", label:"Under 18"},
  {value:"18-29 years", label:"18-29 years"},
  {value:"30-39 years", label:"30-39 years"},
  {value:"40-49 years", label:"40-49 years"},
  {value:"50-59 years", label:"50-59 years"},
  {value:"60-69 years", label:"60-69 years"},
  {value:"70 and over", label:"70 and over"},
]

export const genderOptionsEn = [
  {value:'Male', label:'Male'},
  {value:'Female', label:'Female'},
  {value:'Transgender man/trans man/female-to-male (FTM)', label:'Transgender man/trans man/female-to-male (FTM)'},
  {value:'Transgender woman/trans woman/male-to-female (MTF)', label:'Transgender woman/trans woman/male-to-female (MTF)'},
  {value:'Non-Binary/Third Gender', label:'Non-Binary/Third Gender '},
]

export const raceOptionsEn = [
  {value:'Hispanic/Latinx', label: 'Hispanic/Latinx'},
  {value:'Black or African American (non-Hispanic/Latinx)', label:'Black or African American (non-Hispanic/Latinx)'},
  {value:'White (Non-Hispanic/Latinx)', label:'White (Non-Hispanic/Latinx)'},
  {value: 'Asian or Pacific Islander', label: 'Asian or Pacific Islander'},
  {value:'American Indian and/or Alaska Native', label:'American Indian and/or Alaska Native'},
  {value:'Other race-ethnicity', label:'Other race-ethnicity'}
]

// Survey options

export const helpfulnessOptionsEn = [
  {value: "Very helpful", label:"Very helpful"},
  {value: "Moderately helpful", label:"Moderately helpful"},
  {value: "Slightly helpful", label:"Slightly helpful"},
  {value: "Not helpful", label:"Not helpful"},
]

export const didYouFindWhatYouNeededOptionsEn = [
  {value: 'Yes', label: 'Yes'},
  {value: 'Partially', label: 'Partially'},
  {value: "No", label: "No"},
]

export const howEasyWasInfoToFindOptionsEn = [
  {value: 'Very easy to find', label: 'Very easy to find'},
  {value: 'Easy to find', label: 'Easy to find'},
  {value: 'Neither easy nor hard to find', label: 'Neither easy nor hard to find'},
  {value: 'Hard to find', label: 'Hard to find'},
  {value: 'Very hard to find', label: 'Very hard to find'},
  {value: "I didn't find what I was looking for", label: "I didn't find what I was looking for"},
]

export const whereDidYouAccessWebsiteOptionsEn = [
  {value: "At a friend or family member's house", label: "At a friend or family member's house"},
  {value: "At a self help center", label: "At a self help center"},
  {value: "At home", label: "At home"},
  {value: "At the library", label: "At the library"},
  {value: "At work", label: "At work"},
  {value: "On my phone", label: "On my phone"},
  {value: "Other", label: "Other"},
]

export const additionalInfoOptionsEn = [
  {value: "I am a victim and/or survivor of domestic violence", label: "I am a victim and/or survivor of domestic violence"},
  {value: "I am a victim and/or survivor of another crime other than domestic violence", label: "I am a victim and/or survivor of another crime other than domestic violence"},
  {value: "I am a veteran", label: "I am a veteran"},
  {value: "I am homeless or unstably housed", label: "I am homeless or unstably housed"},
  {value: "I am a full time student", label: "I am a full time student"},
  {value: "I am using this website to help someone else", label: "I am using this website to help someone else"},
  {value: "If I needed to, I don't think I could afford a lawyer", label: "If I needed to, I don't think I could afford a lawyer"},
  {value: "I am unemployed or have been unemployed in the last year", label: "I am unemployed or have been unemployed in the last year"},
  {value: "None of the above", label: "None of the above"},
]

export const didYouCompleteTheFormsOptionsEn = [
  {value:"Yes", label:"Yes"},
  {value:"No", label:"No"},
  {value:"Not yet, but I plan to", label:"Not yet, but I plan to"},
]

export const petitionFormOptionsEn = [
  {value:"411.072", label:"411.072"},
  {value:"411.0725", label:"411.0725"},
  {value:"411.0726", label:"411.0726"},
  {value:"411.0727", label:"411.0727"},
  {value:"411.0728", label:"411.0728"},
  {value:"411.0729", label:"411.0729"},
  {value:"411.03", label:"411.073"},
  {value:"411.031", label:"411.0731"},
  {value:"411.035", label:"411.0735"},
  {value:"411.036", label:"411.0736"},
  {value:"I filled out multiple", label:"I filled out multiple"},
]

export const timeToCompleteFormsOptionsEn = [
  {value:"Less than 30 minutes", label:"Less than 30 minutes"},
  {value:"Between 30 minutes and 1 hour", label:"Between 30 minutes and 1 hour"},
  {value:"1-2 hours", label:"1-2 hours"},
  {value:"2-4 hours", label:"2-4 hours"},
  {value:"Over 4 hours", label:"Over 4 hours"},
]

export const howUserHeardAboutSiteOptionsEn = [
  {value:"Friend", label:"Friend"},
  {value:"Social media", label:"Social media"},
  {value:"The Beacon", label:"The Beacon"},
  {value:"Other", label:"Other"},
]

export const educationLevelOptionsEn = [
  {value:"Less than a high school degree", label: "Less than a high school degree"},
  {value:"High school degree or GED", label: "High school degree or GED"},
  {value:"Some college credit", label: "Some college credit"},
  {value:"Associate's degree", label: "Associate's degree"},
  {value:"Bachelor's degree", label: "Bachelor's degree"},
  {value:"Master's/professional/doctorate", label: "Master's/professional/doctorate"},
  {value:"Prefer not to say", label: "Prefer not to say"},
]

export const incomeRangeOptionsEn = [
  {value:"Less than $20,000", label: "Less than $20,000"},
  {value:"$20,000 - $44,999", label: "$20,000 - $44,999"},
  {value:"$45,000 - $59,999", label: "$45,000 - $59,999",},
  {value:"$60,000 - $139,999", label: "$60,000 - $139,999"},
  {value:"$140,000 or more", label: "$140,000 or more"},
  {value:"Prefer not to say", label: "Prefer not to say"},
]