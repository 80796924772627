import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { YesNoField } from '../general/FormFields.js';
import { Button, StyledHR } from '../general/Common';
import { validateConfirmCases } from '../../utils/validators.js';
import { useConfirmCases } from '../../hooks/editors.js';
import { BeigeTile, Header } from '../general/BeigeTile.js';
import { BG_LIGHTER, PRIMARY } from '../../cssVars.js';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  allCasesHiddenStatusSelector,
  needSPNSelector,
  spnGroupedCasesSelector,
} from '../../selectors/entities.js';
import { CaseCard } from '../general/CaseCard.js';
import { setCase } from '../../actions/modals.js';
import { NoCasesFound, SearchRangeNote } from './NoCasesFound.js';
import { Translate } from 'react-localize-redux';
import useDates from '../../hooks/useDates.js';

const Text = styled.p`
  margin: 10px 0px;
`;

const OptionWrapper = styled.div`
  padding: 15px;
  margin: 20px 0px;
  margin-bottom: 30px;
  background-color: #dbdad6;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  > div:first-child {
    font-weight: 800;
    margin: -15px;
    margin-bottom: 10px;
    padding: 10px 20px;
    background-color: #807f77;
    color: ${BG_LIGHTER};
    border-radius: 4px 4px 0px 0px;
  }
  > div:last-child {
    align-self: flex-end;
  }
`;

// Page that asks user to confirm if a list of cases is theirs.
// If the backend provided multiple possible SPN matches,
// show multiple groups of case-lists and have the user choose.
export const ConfirmCases = () => {
  const dispatch = useDispatch();
  // "needSPN" is true if there were multiple possible SPN matches
  // & we need to determine which one is correct for the user
  const needSPN = useSelector(needSPNSelector);
  const clickCase = currCase => () => dispatch(setCase(currCase));

  return (
    <BeigeTile>
      {needSPN ? (
        <MultipleSPNConfirmation clickCase={clickCase} />
      ) : (
        <SingleSPNConfirmation clickCase={clickCase} />
      )}
    </BeigeTile>
  );
};

// Presents a single list of cases & asks if they are correct for the user
const SingleSPNConfirmation = ({ clickCase }) => {
  const casesArray = useSelector(allCasesHiddenStatusSelector);
  const [confirmCases] = useConfirmCases();
  const submit = () => confirmCases(casesArray[0].def_spn);

  return (
    <Formik
      validate={validateConfirmCases}
      initialValues={{ areTheseYourCases: null }}
      enableReinitialize={true}
      onSubmit={submit}
    >
      {({ handleSubmit, errors, touched, values, submitCount }) => {
        // return error if field is touched
        const getError = name => (touched[name] || submitCount >= 1) && errors[name];
        return (
          <>
            <Header>
              <Translate id="areTheseYourCases" />
            </Header>

            <Text>
              <Translate id="verifyCasesText" />
            </Text>

            {casesArray.map((currCase, idx) => (
              <CaseCard onClick={clickCase(currCase)} key={`case${idx}`} caseInfo={currCase} />
            ))}

            <Text style={{ marginTop: '30px' }}>
              <Translate id="areTheseTheCorrectCases" />
            </Text>
            <YesNoField name="areTheseYourCases" error={getError('areTheseYourCases')} />
            {values.areTheseYourCases === false && <StyledHR style={{ marginBottom: '30px' }} />}
            {values.areTheseYourCases == null ? null : values.areTheseYourCases === true ? (
              <Button type="submit" style={{ minWidth: '260px' }} onClick={handleSubmit}>
                <Translate id="next" />
              </Button>
            ) : (
              <NoCasesFound other={true} />
            )}
          </>
        );
      }}
    </Formik>
  );
};

// Presents multiple lists of cases, & asks user to choose which list is theirs
const MultipleSPNConfirmation = ({ clickCase }) => {
  const spnGroupedCases = useSelector(spnGroupedCasesSelector);
  const [submit] = useConfirmCases();
  const { startDate, endDate } = useDates();

  return (
    <div>
      <Header>
        <Translate id="selectYourCases" />
      </Header>

      <Text>
        <Translate id="foundMultiple" />{' '}
      </Text>
      <Text>
        <Translate id="pleaseTakeALookLong" data={{ startDate, endDate }} />
      </Text>

      {spnGroupedCases.map((caseArray, arrayIdx) => (
        <OptionWrapper key={arrayIdx}>
          <div>Case List {arrayIdx + 1}</div>
          {caseArray.map((currCase, idx) => (
            <CaseCard
              onClick={clickCase(currCase)}
              key={`case${idx}-${arrayIdx}`}
              caseInfo={currCase}
              style={{ margin: '0px', marginBottom: '10px' }}
            />
          ))}
          <Button style={{ minWidth: '260px' }} onClick={() => submit(caseArray[0].def_spn)}>
            <Translate id="continueWithTheseCases" />
          </Button>
        </OptionWrapper>
      ))}
      <StyledHR />
      <Text style={{ marginBottom: '20px' }}>
        <SearchRangeNote />
        <Translate id="noneOfTheseCasesYours" />
        {` `}
        <Link style={{ color: PRIMARY }} to="/check/pre-check#">
          <Translate id="resubmitYourInfo" />{' '}
        </Link>{' '}
        {` `}
        <Translate id="or" />{' '}
        <Link style={{ color: PRIMARY }} to="/#">
          <Translate id="returnToTheHomepage" />{' '}
        </Link>
        .
      </Text>
    </div>
  );
};
