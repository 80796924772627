import React from 'react';
import styled from 'styled-components';
import { SERVER_URL } from '../../constants/general';
import { EFILE_TYLERTECH_LINK, FELONY_CT_LINK } from '../../constants/links';
import { SHADOW_TINY } from '../../cssVars';
import { TextLink } from '../general/Common';
import { Translate } from 'react-localize-redux';

const Wrapper = styled.div`
  margin-bottom: 50px;
  position: relative;
  z-index: 2;
`;

const SpacedUL = styled.ul`
  > li {
    margin: 15px 0px;
  }
`;

const Text = styled.p`
  margin: 20px 0px;
`;

export const ImportantTile = styled.div`
  border-radius: 5px;
  padding: 10px 13px;
  margin: 0px -13px;
  background-color: #ecd893;
  box-shadow: ${SHADOW_TINY};
`;

export const FilingContent = () => {
  return (
    <Wrapper>
      <ImportantTile>
        <b>
          <Translate id="felonyCourtNote1" />{' '}
          <TextLink to={FELONY_CT_LINK}>
            <Translate id="felonyCourtNote2" />
          </TextLink>
        </b>
        ,{' '}
        <TextLink to="/steps/hearing#felony-courts">
          <Translate id="clickHereLc" />
        </TextLink>{' '}
        <Translate id="felonyCourtNote3" />
      </ImportantTile>
      <Text>
        <Translate id="filing1" />
      </Text>
      <Text>
        <Translate id="filing2" />
      </Text>
      <SpacedUL>
        <li>
          <Translate id="filing3" /> <TextLink to={EFILE_TYLERTECH_LINK}>eFileTexas.gov</TextLink>
        </li>
        <li>
          <Translate id="filing4" />
          <TextLink to={`${SERVER_URL}/pdfs/efiletexas_en.pdf`}>
            <Translate id="here" />
          </TextLink>
          .
        </li>
      </SpacedUL>
    </Wrapper>
  );
};
