import React from 'react';
import { DeepDiveHeader, DeepDiveWrapper } from './DeepDives';
import { FilingContent } from './FilingContent';

export const HowToFile = () => {
  return (
    <DeepDiveWrapper>
      <DeepDiveHeader filing={true} titleId="filingInHC" />
      <FilingContent />
    </DeepDiveWrapper>
  )
}
