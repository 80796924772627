import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { YesNoField, CheckboxGroup, CheckboxField } from '../general/FormFields.js';
import { Button, LinkLikeButton, StyledHR } from '../general/Common';
import {
  askTraffickingVictimQuestionsSelector,
  askVeteranQuestionsSelector,
} from '../../selectors/entities.js';
import { BeigeTile, Header } from '../general/BeigeTile.js';
import { useHistory } from 'react-router-dom';
import { useAdditionalInfo } from '../../hooks/editors.js';
import { useDispatch, useSelector } from 'react-redux';
import {
  setVeteransReemploymentPrgModal,
  setVeteransTreatmentCrtModal,
} from '../../actions/modals.js';
import { ERROR } from '../../cssVars.js';
import { Translate } from 'react-localize-redux';
import { englishIsActiveSelector } from '../../selectors/translations.js';
import {
  disqualifyingOffensesOptionsEn,
  disqualifyingOffensesOptionsEs,
} from '../../constants/check.js';

const Text = styled.p`
  margin: 10px 0px;
`;

const ErrorText = styled.p`
  color: ${ERROR};
  margin: 10px 0px;
`;

// Prompts user for disqualifying history responses (e.g. sex offender registration, serious violent/abusive offenses)
// and some preliminary followup questions depending on their case flags (veteran & human trafficking victim questions)
export const AdditionalInfo = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [submit, submitting] = useAdditionalInfo(() => history.push('/check/case-verifications/0'));
  const [askVeteranQuestions, askVetTreatmentCrtQuestions, askVetReemploymentPrgQuestions] =
    useSelector(askVeteranQuestionsSelector);
  const askTraffickingVictimQuestions = useSelector(askTraffickingVictimQuestionsSelector);
  const isEnglishActive = useSelector(englishIsActiveSelector);
  const disqualifyingOffensesOptions = isEnglishActive
    ? disqualifyingOffensesOptionsEn
    : disqualifyingOffensesOptionsEs;

  return (
    <Formik
      initialValues={{
        sexOffender: null,
        disqualifyingOffenses: [],
        noneOfTheAbove: false,
        isVeteran: null,
        completedVetReemploymentPrg: null,
        completedVetTreatmentCrt: null,
        hasBeenVictim: null,
        hasVictimCaseInHarrisCounty: null,
        assistedInvestigation: null,
      }}
      enableReinitialize={true}
      onSubmit={submit}
    >
      {({ handleSubmit, errors, touched, values, submitCount }) => {
        // return error if field is touched
        const getError = name => (touched[name] || submitCount >= 1) && errors[name];

        // Disqualifying offenses / sex offender logic
        const checkedAtLeastOneBox =
          values.noneOfTheAbove || values.disqualifyingOffenses.length > 0;
        const noneOfTheAboveAndOffenseError =
          values.noneOfTheAbove && values.disqualifyingOffenses.length > 0;
        const hasDisqualifyingOffense =
          values.sexOffender === true || values.disqualifyingOffenses.length > 0;
        // Sex offender question and disqualifying offenses checklist are complete (needed not to display the veteran questions before these are completed)
        const disqualifyingOffensesQuestionsComplete =
          values.sexOffender === true ||
          (values.sexOffender === false && checkedAtLeastOneBox && !noneOfTheAboveAndOffenseError);

        // Veteran logic
        const vetReemploymentPrgQuestionsComplete =
          !askVetReemploymentPrgQuestions || values.completedVetReemploymentPrg != null;
        const vetTreatmentCrtQuestionsComplete =
          !askVetTreatmentCrtQuestions ||
          values.completedVetTreatmentCrt != null ||
          hasDisqualifyingOffense;
        const veteranPortionComplete =
          values.isVeteran === false ||
          (vetReemploymentPrgQuestionsComplete && vetTreatmentCrtQuestionsComplete);

        // Victim of trafficking / compelling prostitution logic, cascading flow
        let traffickingVictimPortionComplete =
          values.hasBeenVictim != null || hasDisqualifyingOffense;
        if (values.hasBeenVictim) {
          traffickingVictimPortionComplete = values.hasVictimCaseInHarrisCounty != null;
          if (values.hasVictimCaseInHarrisCounty) {
            traffickingVictimPortionComplete = values.investigationAssistanceRequested != null;
            if (values.investigationAssistanceRequested) {
              traffickingVictimPortionComplete = values.assistedInvestigation != null;
            }
          }
        }

        // Overall NDO form section (veteran & trafficking victim) completion
        const ndoPrelimQuestionsComplete =
          (!askVeteranQuestions || veteranPortionComplete) &&
          (!askTraffickingVictimQuestions || traffickingVictimPortionComplete);

        return (
          <BeigeTile>
            <Header style={{ marginBottom: '0px' }}>
              <Translate id="addtInfo" />
            </Header>

            <Text>
              <Translate id="addtInfoIntro" />
            </Text>

            <Text>
              <Translate id="sexOffenderQ" />*
            </Text>
            <YesNoField name="sexOffender" error={getError('sexOffender')} />

            {values.sexOffender === false && (
              <>
                <Text id="disqualifyingOffensesLabel">
                  <Translate id="disqOffensesLabel" />
                </Text>
                <CheckboxGroup
                  name="disqualifyingOffenses"
                  labelId="disqualifyingOffensesLabel"
                  error={getError('disqualifyingOffenses')}
                  checkboxOptions={disqualifyingOffensesOptions}
                  style={{ marginLeft: '20px' }}
                  checkboxStyle={{ marginTop: '15px' }}
                />
                <CheckboxField
                  name="noneOfTheAbove"
                  testId="disqOffensesNoneOfTheAbove"
                  error={getError('noneOfTheAbove')}
                  wrapperStyle={`margin: 15px 0px 30px 20px;`}
                >
                  <Translate id="noneOfTheAbove" />
                </CheckboxField>
                {noneOfTheAboveAndOffenseError && (
                  <ErrorText>
                    <Translate id="cantCheckMultiple1" />{' '}
                    {values.disqualifyingOffenses.length > 1 ? (
                      <Translate id="cantCheckMultiple2" />
                    ) : (
                      <Translate id="cantCheckMultiple3" />
                    )}{' '}
                    <Translate id="cantCheckMultiple4" />
                  </ErrorText>
                )}
              </>
            )}

            {/* Show helper message if the user hasn't checked any boxes in list */}
            {values.sexOffender === false && !checkedAtLeastOneBox && (
              <div style={{ fontSize: '18px' }}>
                <i>
                  *<Translate id="checkAtLeastOne" />
                </i>
              </div>
            )}

            {/* Once the disqualifying offenses portion is complete, move on to veteran section */}
            {/* Ask about veteran's reemployment program if they are potentially eligible, even if they have a disqualifying offense */}
            {disqualifyingOffensesQuestionsComplete &&
              askVeteranQuestions &&
              (!hasDisqualifyingOffense || askVetReemploymentPrgQuestions) && (
                <>
                  <StyledHR />
                  <Text>
                    <Translate id="veteranQ" />*
                  </Text>
                  <YesNoField name="isVeteran" error={getError('isVeteran')} />

                  {values.isVeteran && askVetReemploymentPrgQuestions && (
                    <>
                      <Text>
                        <Translate id="vetReemploymentQ" />*{` `}
                        <LinkLikeButton
                          onClick={() => dispatch(setVeteransReemploymentPrgModal(true))}
                          style={{ fontWeight: '600', fontStyle: 'italic ' }}
                        >
                          <Translate id="whatDoesThisMean" />
                        </LinkLikeButton>
                      </Text>
                      <YesNoField
                        name="completedVetReemploymentPrg"
                        error={getError('completedVetReemploymentPrg')}
                      />
                    </>
                  )}
                </>
              )}

            {/* ONLY IF they do not have any disqualifying offenses, show vet treatment court & victim of human trafficking questions */}
            {!hasDisqualifyingOffense && disqualifyingOffensesQuestionsComplete && (
              <>
                {values.isVeteran && askVetTreatmentCrtQuestions && (
                  <>
                    <Text>
                      <Translate id="vetTreatmentQ" />*{` `}
                      <LinkLikeButton
                        onClick={() => dispatch(setVeteransTreatmentCrtModal(true))}
                        style={{ fontWeight: '600', fontStyle: 'italic ' }}
                      >
                        <Translate id="whatDoesThisMean" />
                      </LinkLikeButton>
                    </Text>
                    <YesNoField
                      name="completedVetTreatmentCrt"
                      error={getError('completedVetTreatmentCrt')}
                    />
                  </>
                )}

                {askTraffickingVictimQuestions &&
                  (veteranPortionComplete || !askVeteranQuestions) && (
                    <>
                      <StyledHR />
                      <Text>
                        <Translate id="humanTraffickingQ1" />
                      </Text>
                      <YesNoField name="hasBeenVictim" error={getError('hasBeenVictim')} />

                      {values.hasBeenVictim === true && (
                        <>
                          <Text>
                            <Translate id="humanTraffickingQ2" />
                          </Text>
                          <YesNoField
                            name="hasVictimCaseInHarrisCounty"
                            error={getError('hasVictimCaseInHarrisCounty')}
                          />
                        </>
                      )}

                      {values.hasBeenVictim === true &&
                        values.hasVictimCaseInHarrisCounty === true && (
                          <>
                            <Text>
                              <Translate id="humanTraffickingQ3" />
                            </Text>
                            <YesNoField
                              name="investigationAssistanceRequested"
                              error={getError('investigationAssistanceRequested')}
                            />
                          </>
                        )}

                      {values.hasBeenVictim === true &&
                        values.hasVictimCaseInHarrisCounty === true &&
                        values.investigationAssistanceRequested === true && (
                          <>
                            <Text>
                              <Translate id="humanTraffickingQ4" />
                            </Text>
                            <YesNoField
                              name="assistedInvestigation"
                              error={getError('assistedInvestigation')}
                            />
                          </>
                        )}
                    </>
                  )}
              </>
            )}

            {disqualifyingOffensesQuestionsComplete &&
              ((hasDisqualifyingOffense && vetReemploymentPrgQuestionsComplete) ||
                ndoPrelimQuestionsComplete) && (
                <Button
                  type="submit"
                  fillOnMobile={true}
                  isLoading={submitting}
                  style={{ minWidth: '260px', marginTop: '20px' }}
                  onClick={handleSubmit}
                >
                  <Translate id="next" />
                </Button>
              )}
          </BeigeTile>
        );
      }}
    </Formik>
  );
};
