import React from 'react';
import styled from 'styled-components'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useRequest } from 'redux-query-react'
import { Header } from '../general/BeigeTile.js';
import { eligibleCasesSelector } from '../../selectors/entities.js';
import { getCasesByEmailToken } from '../../actions/queries';
import { MultipleEligibleCasesContent, NoCasesAvailableContent, OneEligibleCaseContent } from '../steps/Petition.js';
import { Translate } from 'react-localize-redux';

const Wrapper = styled.div`
  padding: 30px;
`
// Render page to show cases & petitions
export const PetitionsEmail = () => {
  const { emailToken } = useParams();
  useRequest(getCasesByEmailToken(emailToken));
  
  const eligibleCases = useSelector(eligibleCasesSelector);

  return (
    <Wrapper>
        <Header>
          <Translate id="petitionInstructions" />
        </Header>
        {
        eligibleCases.length > 0 
        ? eligibleCases.length > 1 
          ? <MultipleEligibleCasesContent eligibleCases={eligibleCases} formatForEmail={true} />
          : <OneEligibleCaseContent eligibleCase={eligibleCases[0]} formatForEmail={true} />
        : <NoCasesAvailableContent />
      }
    </Wrapper>
  )
}