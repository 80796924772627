import { useEffect } from "react";
import { useLocation } from "react-router";
import { withLocalize } from 'react-localize-redux';

const SwitchLanguageBasedOnQueryString = ({ activeLanguage, setActiveLanguage }) => {
  const location = useLocation();
  const inEnglishMode = activeLanguage != null && activeLanguage.code === 'en';
  // If 'lang=es' query string is set, update the language to be spanish
  useEffect(() => {
    if (inEnglishMode && location.search.includes('lang=es')) {
      setActiveLanguage('es')
    }
  }, [location.search, inEnglishMode]);

  return null;
}

export default withLocalize(SwitchLanguageBasedOnQueryString);