
import React from 'react';
import styled from 'styled-components';
import { MAX_DESKTOP_WIDTH } from '../../cssVars';
import { BackArrowButton } from './Common';

const Wrapper = styled.div`
  > button {
    margin-bottom: 20px;
    margin-top: -20px;
    @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
      margin-bottom: 10px;
      margin-top: 0px;
    }
  }
`

// Shared BackArrowButton on the steps back to sit at top of the BeigeTile
export const TileBackArrow = ({ onClick }) => (
  <Wrapper>
    <BackArrowButton onClick={onClick} />
  </Wrapper>
)