import { useSelector } from 'react-redux';
import { englishIsActiveSelector } from '../selectors/translations';

const useResultsText = (plural) => {
  const inEnglishMode = useSelector(englishIsActiveSelector);
  return {
    theseCasesText: inEnglishMode ? (plural ? 'These cases' : 'This case') : '',
    plainCasesText: inEnglishMode ? `case${plural ? 's' : ''}` : `caso${plural ? 's' : ''}`,
    expunctionEligText: inEnglishMode ? `expunction eligible case${plural ? 's' : ''}` : `caso${plural ? 's' : ''} elegible${plural ? 's' : ''} para expurgo`,
    ineligibleText: inEnglishMode ? `ineligible case${plural ? 's' : ''}` : `caso${plural ? 's' : ''} no eligible${plural ? 's' : ''}`,
    eligibleText: inEnglishMode ? `likely eligible case${plural ? 's' : ''}` : `caso${plural ? 's' : ''} muy posiblemente elegible${plural ? 's' : ''}`
  }
}

export default useResultsText;