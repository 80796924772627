import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ResultsEmail } from './ResultsEmail';
import { KnowYourRightsEmail } from './KnowYourRightsEmail';
import { PetitionsEmail } from './PetitionsEmail';
import SwitchLanguageBasedOnQueryString from './SwitchLanguageBasedOnQueryString';

export const EmailsRoot = () => {
  return (
    <>
      <Switch>
        <Route path="/emails/results/:emailToken" component={ResultsEmail} />
        <Route path="/emails/petitions/:emailToken" component={PetitionsEmail} />
        <Route path="/emails/know-your-rights" component={KnowYourRightsEmail} />
      </Switch>
      <SwitchLanguageBasedOnQueryString />
    </>
  )
}
