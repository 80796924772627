import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { labelByIdEn, labelByIdEs, shortLabelByIdEn, shortLabelByIdEs, stepIds } from '../../constants/steps';
import { BG_DARKER, BG_LIGHTER, MAX_DESKTOP_WIDTH, PRIMARY, BG_DARKEST } from '../../cssVars';
import { englishIsActiveSelector } from '../../selectors/translations';

const CIRCLE_DIAM = 32;
const BAR_HEIGHT = 6;
const BAR_RADIUS = 3;

const Wrapper = styled.nav`
  display: flex;
  justify-content: center;
  width: 750px;
  width: min(750px, 95%);
  margin: 0px auto;
  margin-top: 30px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    width: 90%;
    width: min(750px, 95%);
    margin-top: 25px;
  } 
`

const Section = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
`

const StepCounter = styled.div`
  position: relative;
  width: 100%;
`

const Circle = styled.div`
  background-color: ${({ filled }) => filled ? PRIMARY : BG_LIGHTER };
  border: 2px solid ${({ filled }) => filled ? PRIMARY : BG_DARKEST };
  box-sizing: border-box;
  font-size: 18px;
  color: ${({ filled }) => filled ? BG_LIGHTER : PRIMARY };
  width: ${CIRCLE_DIAM}px;
  height: ${CIRCLE_DIAM}px;
  border-radius: 100%;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  z-index: 1;
  font-weight: 800;
`

const StepLinkWrapper = styled.div`
  > a {
    text-align: center;
    margin-top: 10px;
    font-size: 15px;
    font-weight: 600;
    color: #5D5D51; 
    text-decoration: none;
    cursor: pointer; 
    &:hover {
      text-decoration: underline;
    } 
    @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
      font-size: 14px;
      text-transform: capitalize;
    } 
  }
  > a:first-child { display: block; }
  > a:last-child { display: none; }
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    > a:first-child { display: none; }
    > a:last-child { display: block; }
  } 
`

const Bar = styled.div`
  background-color: ${({ filled }) => filled ? PRIMARY : BG_DARKEST };
  position: absolute;
  width: 100%;
  width: calc(100% + 2px);
  left: -1px;
  height: 6px;
  top: calc((${CIRCLE_DIAM}px - ${BAR_HEIGHT}px) / 2);
  border-radius: ${({ index }) => index === 0 ? `${BAR_RADIUS}px 0px 0px ${BAR_RADIUS}px` 
                                : index === 5 ? `0px ${BAR_RADIUS}px ${BAR_RADIUS}px 0px` 
                                : '0px'};
`

const Step = ({ label, index, filled, id }) => {
  const destination = `/steps/${id}`;
  const history = useHistory();
  const englishIsActive = useSelector(englishIsActiveSelector);
  const shortLabelById = englishIsActive ? shortLabelByIdEn : shortLabelByIdEs;

  return (
    <Section>
      <StepCounter>
        <Circle onClick={() => history.push(destination)} filled={filled}>{index || '●'}</Circle>
        <Bar filled={filled} index={index} />
      </StepCounter>
      <StepLinkWrapper>
        <Link to={destination}>{label}</Link>
        <Link to={destination}>{shortLabelById[id]}</Link>
      </StepLinkWrapper>
    </Section>
  )
}

// Display bubble step navigation if current path is part of listed ids
export const BubbleNavigation = () => {
  const { id: curId } = useParams();
  const englishIsActive = useSelector(englishIsActiveSelector);
  const labelById = englishIsActive ? labelByIdEn : labelByIdEs;

  if (!stepIds.includes(curId)) {
    return null;
  }

  return (
    <Wrapper>
      {stepIds.map((id, index) => (
        <Step label={labelById[id]} index={index} filled={index <= stepIds.indexOf(curId)} id={id} key={id} />
      ))}
    </Wrapper>
  )
}
