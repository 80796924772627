import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { pageBeforeStepsSelector } from '../../selectors/pageBeforeSteps';
import { BeigeTile, BottomSpacedHeader, Text } from '../general/BeigeTile';
import { Button, TextLink } from '../general/Common';
import { TileBackArrow } from '../general/TileBackArrow';
import { Translate } from 'react-localize-redux';
import greenCheckImg from '../../assets/green_check_mark.svg';
import checkImg from '../../assets/check_mark.svg';
import formsImg from '../../assets/forms.svg';
import formImg from '../../assets/form.svg';
import submitFormsImg from '../../assets/submit_forms.svg';
import mailOrderImg from '../../assets/mail_order.svg';
import readFormsImg from '../../assets/read_forms.svg';
import gavelImg from '../../assets/gavel.svg';
import { eligibleCasesSelector } from '../../selectors/entities';
import { SERVER_URL } from '../../constants/general';
import { longLabelByIdEn, longLabelByIdEs } from '../../constants/steps';
import { englishIsActiveSelector } from '../../selectors/translations';
import { HelpText } from '../general/HelpText';

const SpacedUL = styled.ul`
  list-style-type: none;
  padding: 0px;
  > li {
    margin: 20px 0px;
    > div:first-child {
      display: flex;
      align-items: center;
      > img {
        height: 40px;
        width: 40px;
        margin-right: 15px;
      }
    }
    > div:last-child {
      margin-top: 10px;
      margin-left: 55px;
      border-bottom: 1px solid #E0DDCE;
      padding-bottom: 20px;
    }
  }
  > li:last-child {
    > div:last-child {
      border-bottom: none;

    }
  }
  margin-bottom: 20px;
`;

const ItemComplete = styled.div`
  background-color: #A6E0CC;
  border-radius: 20px;
  padding: 3px;
  font-weight: 600;
  padding-left: 10px;
  margin-left: 10px;
  display: inline-flex;
  align-items: center;
  color: #167167;
  > img {
    width: 28px;
    height: 28px;
    margin-left: 2px;
  }
`

const ItemCompleteMarker = ({ hasEligibleCases }) => {
  if (!hasEligibleCases) {
    return null;
  }
  return <ItemComplete>
    <Translate id="done" />
    <img src={greenCheckImg} alt="green checkmark" />
  </ItemComplete>
}

export const Review = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const pageBeforeSteps = useSelector(pageBeforeStepsSelector);
  const eligibleCases = useSelector(eligibleCasesSelector);
  const hasEligibleCases = eligibleCases.length > 0;
  const englishIsActive = useSelector(englishIsActiveSelector);
  const longLabelById = englishIsActive ? longLabelByIdEn : longLabelByIdEs;
  const hcdcInstLink = `${SERVER_URL}/pdfs/HCDC_accessing_records_${englishIsActive ? 'en' : 'es'}.pdf`

  return (
    <BeigeTile>
      <BottomSpacedHeader>
        {pageBeforeSteps != null && <TileBackArrow onClick={() => history.push(pageBeforeSteps)} />}
        {longLabelById.review}
      </BottomSpacedHeader>
      <Text>
        <Translate id="review1" /> <TextLink to={`${SERVER_URL}/info/nondisclosure-expunction`}><Translate id="here" /></TextLink>.
      </Text>
      {/* <SpacedUL>
         <li>
          <div>
            <img src={readFormsImg} alt="small eye over graphic of webpage" />
            <b>Review Your Criminal History</b>
          </div>
          <div>
            Learn how to navigate the Harris County District Clerk website to
            confirm your criminal history and look up your case files to find the
            information you will need to complete your petition <TextLink to={`${SERVER_URL}/pdfs/HCDC_accessing_records.pdf`}>here</TextLink>.
          </div>
        </li> 
      </SpacedUL> */}
      <SpacedUL>
        <li>
          <div>
            <img src={checkImg} alt="check in a circle" />
            <b><Translate id="review2" /></b>
            <ItemCompleteMarker hasEligibleCases={hasEligibleCases} />
          </div>
          <div>
            <Translate id="review3" />
          </div>
        </li>
        <li>
          <div>
            <img src={readFormsImg} alt="small eye over graphic of webpage" />
            <b><Translate id="review4" /></b>
          </div>
          <div>
            <Translate id="review5" /> <TextLink to={hcdcInstLink}><Translate id="here" /></TextLink>.
          </div>
        </li> 
        <li>
          <div>
            <img src={formsImg} alt="small pencil over graphic of forms" />
            <b><Translate id="review6" /></b>
          </div>
          <div>
            <Translate id="review7" />
          </div>
        </li>
        <li>
          <div>
            <img src={formImg} alt="small pencil over graphic of forms" />
            <b><Translate id="review8" /></b>
          </div>
          <div>
            <Translate id="review9" />
          </div>
        </li>
        <li>
          <div>
            <img src={submitFormsImg} alt="small arrow pointing right over graphic of forms" />
            <b><Translate id="review10" /></b>
          </div>
          <div>
            <Translate id="review11" />
          </div>
        </li>
        <li>
          <div>
            <img src={gavelImg} alt="judge's gavel" />
            <b><Translate id="review12" /></b>
          </div>
          <div>
            <Translate id="review13" />
          </div>
        </li>
        <li>
          <div>
            <img src={mailOrderImg} alt="small mail icon over graphic of a form" />
            <b><Translate id="review14" /></b>
          </div>
          <div>
            <Translate id="review15" />
          </div>
        </li>
      </SpacedUL>
      <HelpText />
      <Button onClick={() => history.push('/steps/petition')}>
        <Translate id="next" />
      </Button>
    </BeigeTile>
  )
}
