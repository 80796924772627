import { useHistory } from 'react-router-dom';

// Idea from https://javascript.plainenglish.io/how-to-setup-and-add-google-analytics-to-your-react-app-fd361f47ac7b
// Listen to any route changes & send pageview events to Google Analytics
export const RouteChangeTracker = () => {
  const history = useHistory();

  // Temporarily remove listener - may want to reactivate if we need additional pageview events for GA4
  // history.listen((location) => {
  //     ReactGA.set({ page: location.pathname });
  //     ReactGA.pageview(location.pathname);
  // });

  return null;
};
