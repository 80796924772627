import React from 'react';
import styled from 'styled-components';
import { Button } from '../general/Common';
import { Header, Text } from '../general/BeigeTile.js';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPageBeforeSteps } from '../../actions/pageBeforeSteps';
import { Translate } from 'react-localize-redux';

const Wrapper = styled.div`
  margin: 20px 0px;
`;

// Little section for the end of results page, prompts user to continue to the next steps
export const NextStepsOverview = ({ nondisclosureEligibleCasesExist }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleBeginNextSteps = () => {
    // Marks that the user just came from `results` so they can be navigated back here
    // if they press the back-arrow on the steps overview page
    dispatch(setPageBeforeSteps('/check/results'));
    history.push('/steps/review');
  };

  return (
    <Wrapper>
      {/* If some nondisclosure cases exist, display a "Next Steps" section, otherwise show a "Return to homepage" button  */}
      {nondisclosureEligibleCasesExist ? (
        <>
          <Header>
            <Translate id="nextSteps" />
          </Header>
          <Text style={{ marginTop: '30px' }}>
            <Translate id="nextStepsQuickOverview" />
          </Text>
          <Button onClick={handleBeginNextSteps}>
            <Translate id="beginNextSteps" />{' '}
          </Button>
        </>
      ) : (
        <Button onClick={() => history.push('/')}>Return to homepage</Button>
      )}
    </Wrapper>
  );
};
