import { createSelector } from 'reselect';

export const languagesSelector = (state) => state.localize.languages || [];

// Check localize state to see if english is the active language
export const englishIsActiveSelector = createSelector(
  [languagesSelector],
  languages => {
    const spanish = languages.find(({code}) => code === 'es')
    if (spanish && spanish.active) {
      return false;
    }
    return true;
  }
);
