import React from 'react';
import styled from 'styled-components';
import { Button, ButtonLikeLink } from '../general/Common';
import { MAX_DESKTOP_WIDTH, PRIMARY, SECONDARY, SECONDARY_DARK } from '../../cssVars.js';
import { Section } from '../general/Section';
import { Translate } from 'react-localize-redux';
import { HashLink as Link } from 'react-router-hash-link';
import { useHistory } from 'react-router-dom';
import { DeepDiveTiles } from './DeepDives';
import { ShortFAQ1, ShortFAQ2 } from './FAQ';
import { useDispatch, useSelector } from 'react-redux';
import { eligibleCasesSelector } from '../../selectors/entities';
import { setPageBeforeSteps } from '../../actions/pageBeforeSteps';
import learnCircle from '../../assets/blackSheep/circle_learn.svg';
import checkCircle from '../../assets/blackSheep/circle_check.svg';
import fileCircle from '../../assets/blackSheep/circle_file.svg';
import heroDesktop from '../../assets/blackSheep/banner_desktop.png';
import heroMobileShort from '../../assets/blackSheep/banner_mobile_shorter.png';
import skyline from '../../assets/blackSheep/skyline.png';
import { trackEvent } from '../../utils/googleAnalytics';
import { onMobile } from '../../utils/helpers';
import { HOTLINE_LINK } from '../../constants/links.js';

const INTRO_WIDTH = 700;

const Wrapper = styled.div`
  width: 100%;
  color: ${SECONDARY_DARK};
`;

const FAQHeader = styled.h2`
  margin: 0px;
  color: ${SECONDARY};
  font-weight: 600;
  font-size: 32px;
  text-transform: none;
  text-align: center;
`;

const TitleSectionWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: 30px;
`;

const HeroSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  background-color: ${SECONDARY};
  > img {
    width: 800px;
    height: 249px;
  }
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    > img {
      height: auto;
      width: 100%;
    }
  }
`;

const TitleWrapper = styled.div`
  width: ${MAX_DESKTOP_WIDTH - 200}px;
  width: min(${MAX_DESKTOP_WIDTH - 200}px, 90%);
  margin: 0px auto;
  text-align: center;
  > img {
    width: 375px;
    height: 102px;
    margin-top: 40px;
  }
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    width: 90%;
    text-align: center;
    padding-top: 30px;
    > img {
      display: none;
      // width: 80%;
      // height: 22%;
    }
  }
`;

const Hook = styled.h1`
  color: ${SECONDARY};
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0px;
  font-size: 40px;
  text-transform: none;
`;

const IntroText = styled.p`
  margin-bottom: 0px;
  margin-top: 10px;
  > b {
    font-weight: 700;
  }
`;

const NextStepText = styled.div`
  text-align: center;
  font-weight: 500;
  margin-top: 20px;
  > a {
    font-style: italic;
    color: ${PRIMARY};
    display: inline-block;
  }
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    > a {
      display: block;
    }
  }
`;

const IntroWrapper = styled.div`
  margin: 0px;
  text-align: center;
  padding: 20px 20px 0px 20px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    margin: 20px 0px;
    text-align: center;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px auto;
  max-width: ${INTRO_WIDTH}px;
  text-align: left;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    margin: 15px;
    margin-top: 0px;
  }
  > div {
    font-weight: 400;
    > b {
      font-weight: 400;
    }
  }
`;

const Image = styled.img`
  width: 80px;
  height: 80px;
  margin-right: 30px;
  margin-left: 70px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    margin-right: 20px;
    margin-left: 00px;
  }
`;

const ButtonPromptText = styled.div`
  padding: 0px 20px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    padding: 0px;
  }
`;

const StyledLink = styled(Link)`
  font-weight: 600;
  text-align: center;
`;

export const Landing = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const eligibleCases = useSelector(eligibleCasesSelector);
  const hasEligibleCases = eligibleCases.length > 0;
  const showMobileImage = onMobile();

  const handleCheckMyEligibilityClick = async () => {
    trackEvent('Check My Eligibility Click'); // Beacon Law requested event
    history.push('/check/pre-check');
  };

  const handleViewStepsToFileClick = () => {
    if (hasEligibleCases) {
      dispatch(setPageBeforeSteps('/'));
    }
  };

  return (
    <Wrapper>
      <HeroSection>
        <img
          src={showMobileImage ? heroMobileShort : heroDesktop}
          loading="lazy"
          alt="Two smiling people with colorful lines illustrating them and surrounding landscape. Nearby text reads: Turn the page, it's your future."
        />
      </HeroSection>
      <TitleSectionWrapper>
        <TitleWrapper>
          <img src={skyline} loading="lazy" alt="lineart of Houston skyline" />
          <Hook>
            <Translate id="haveACriminalRecord" />
          </Hook>
          <IntroText>
            <Translate id="youreNotAlone" />
          </IntroText>
          <Button
            id="check_eligibility_cta"
            wrapperStyle={{ textAlign: 'center', margin: '40px auto' }}
            prominent={true}
            onClick={handleCheckMyEligibilityClick}
          >
            <Translate id="checkMyElig" />
          </Button>
          <ButtonPromptText>
            <Translate id="siteDescriptor" />
          </ButtonPromptText>
          <NextStepText>
            <Translate id="alreadyKnowYouHaveCases" /> <br />
            <Link
              to={hasEligibleCases ? '/steps/review' : '/steps/precheck'}
              onClick={handleViewStepsToFileClick}
            >
              <Translate id="viewStepsToFile" />
            </Link>
          </NextStepText>
        </TitleWrapper>
        <IntroWrapper>
          <ImageWrapper>
            <Image src={learnCircle} loading="lazy" alt="" />
            <div>
              <Translate id="siteHook1" />
            </div>
          </ImageWrapper>
          <ImageWrapper>
            <Image src={checkCircle} loading="lazy" alt="" />
            <div>
              <Translate id="siteHook2" />
            </div>
          </ImageWrapper>
          <ImageWrapper>
            <Image src={fileCircle} loading="lazy" alt="" />
            <div>
              <Translate id="siteHook3" />
            </div>
          </ImageWrapper>
        </IntroWrapper>
        <div style={{ margin: '40px 0px 20px 0px' }}>
          <TitleWrapper>
            <ButtonPromptText>
              <Translate id="hotlineText1" />
              {` `}
              <Translate id="hotlineLink" />
              {` `}
              <Translate id="hotlineText2" />
              {` `}
              <Translate id="hotlineText3" />
            </ButtonPromptText>
          </TitleWrapper>
        </div>
        <ButtonLikeLink
          wrapperStyle={{ textAlign: 'center', margin: '40px auto' }}
          to={HOTLINE_LINK}
        >
          <Translate id="schedule1on1" />
        </ButtonLikeLink>
      </TitleSectionWrapper>
      <Section
        color="rgba(0,0,0,0.05)"
        templateColumns="100%"
        padding="30px 0px"
        mobilePadding="30px 0px"
      >
        <FAQHeader>
          <Translate id="frequentlyAskedQuestions" />
        </FAQHeader>
      </Section>
      <Section
        color="rgba(0,0,0,0.05)"
        templateColumns="50% 50%"
        splitMargin="0px"
        padding="0px"
        mobilePadding="0px"
      >
        <ShortFAQ1 />
        <ShortFAQ2 />
      </Section>
      <Section
        color="rgba(0,0,0,0.05)"
        templateColumns="100%"
        padding="0px 0px 30px 0px"
        mobilePadding="0px 0px 30px 0px"
      >
        <StyledLink to="/info/faq">
          <Translate id="viewAllFAQ" />
        </StyledLink>
      </Section>
      <Section
        color="rgba(0,0,0,0.1)"
        id="deep-dives"
        templateColumns="100%"
        mobilePadding="20px 10px"
      >
        <DeepDiveTiles />
      </Section>
    </Wrapper>
  );
};
