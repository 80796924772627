import React from 'react';
import styled from 'styled-components';
import { LinkLikeButton } from '../general/Common';
import { MAX_DESKTOP_WIDTH, SECONDARY } from '../../cssVars.js';
import { Section } from '../general/Section';
import { TextLink } from '../general/Common';
import { HashLink as Link } from 'react-router-hash-link';
import { setAboutUsModal, setDisclaimerModal, setPrivacyModal, setSurveyModal } from '../../actions/modals';
import blLogo from '../../assets/blackSheep/beacon_logo.png'
import jaLogo from '../../assets/ja-logo-horizontal.svg'
import teajfLogo from '../../assets/teajfLogo.jpg'
import { useDispatch } from 'react-redux';
import { ISSUE_REPORTER_LINK, SITE_URL } from '../../constants/general';
import { Translate } from 'react-localize-redux';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { BEACON_LINK, CLEAN_SLATE_TX_LINK, HCDC_LINK, JA_LINK, TATJ_LINK, TLH_MAIN_LINK } from '../../constants/links';

const BeaconLawLogo = styled.img`
  width: 200px;
  height: 60px;
  margin-bottom: 0px;
`

const TEAJFLogo = styled.img`
  width: 200px;
  height: 55px;
  margin-bottom: 0px;
  background-color: #fff;
  padding: 0px 10px;
  border-radius: 4px;
  box-sizing: border-box;
`

const FooterSection = styled.div`
  font-size: 16px;
  color: white;
  > div {
    margin-bottom: 10px;
  }
  > a {
    margin-bottom: 10px;
    display: block;
  }
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    text-align: center;
    > a, button {
      text-align: center;
      font-size: 18px !important;
    }
  } 
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    font-size: 18px;
  } 
`

const FooterHeader = styled.div`
  font-size: 18px;
  color: #C7C39F;
  font-weight: 800;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    margin-top: 20px;
    font-size: 18px;
  } 
`

const FooterSubheader = styled.div`
  font-size: 16px;
  color: #CDCBB5;
  font-weight: 600;
  margin-bottom: 0px !important;
`

const JASection = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  > a > img {
    width: 114px;
    height: 20px;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 5px;
  }
`

const SharingSection = styled.div`
  display: flex;
  align-items: center;
`

const AssistanceDisclaimer = styled.p`
  color: #BFCDEB;
  font-size: 15px;
  text-align: center;
  margin: 0px;
  padding: 0px;
`

export const Footer = () => {
  const dispatch = useDispatch();
  
  return (
    <>
      <Section templateColumns="30% 20% 25% 25%" color={SECONDARY} padding="40px 100px 20px 100px" mobilePadding="20px 10px 10px 10px" splitMargin="0px">
        <FooterSection>
          <TextLink style={{ width: '200px' }} to={BEACON_LINK}>
            <BeaconLawLogo src={blLogo} loading="lazy" alt="The Beacon" />
          </TextLink>
          <TextLink style={{ width: '200px', marginBottom: '5px' }} to={TATJ_LINK}>
            <TEAJFLogo src={teajfLogo} loading="lazy" alt="Texas Access to Justice Foundation" />
          </TextLink>
          <JASection style={{ marginBottom: '5px' }}>
            <Translate id="footer1_1" />
            <TextLink to={JA_LINK}>
              <img src={jaLogo} loading="lazy" alt="January Advisors" />
            </TextLink>
          </JASection>
          <SharingSection><Translate id="footer1_2" />
            <FacebookShareButton
              style={{ margin: '0px 5px 0px 10px', display: 'flex', alignItems: 'center'}}
              url={SITE_URL}
            >
              <FacebookIcon size={30} round loading="lazy" />
            </FacebookShareButton>
            <TwitterShareButton
              style={{ margin: '0px 5px', display: 'flex', alignItems: 'center'}}
              url={SITE_URL}
            >
              <TwitterIcon size={30} round loading="lazy" />
            </TwitterShareButton>
            <LinkedinShareButton
              style={{ margin: '0px 5px', display: 'flex', alignItems: 'center'}}
              url={SITE_URL}
            >
              <LinkedinIcon size={30} round loading="lazy" />
            </LinkedinShareButton>
          </SharingSection>
        </FooterSection>
        <FooterSection>
          <FooterHeader>
            <Translate id="footer2" />
          </FooterHeader>
          <LinkLikeButton style={{ color: 'white', marginBottom: '10px', fontSize: '16px', fontWeight: 400, textDecoration: 'none', textAlign: 'left' }} 
                            onClick={() => dispatch(setAboutUsModal(true))}>
            <Translate id="footer2_1" />
          </LinkLikeButton>
          <Link smooth style={{ color: 'white', textDecoration: 'none' }} to="/info/faq">
            <Translate id="footer2_2" />
          </Link>
          <LinkLikeButton style={{ color: 'white', marginBottom: '10px', fontSize: '16px', fontWeight: 400, textDecoration: 'none', textAlign: 'left' }} 
                          onClick={() => dispatch(setDisclaimerModal(true))}>
            <Translate id="footer2_3" />
          </LinkLikeButton><br/>
          <LinkLikeButton style={{ color: 'white', marginBottom: '10px', fontSize: '16px', fontWeight: 400, textDecoration: 'none', textAlign: 'left' }} 
                          onClick={() => dispatch(setPrivacyModal(true))}>
            <Translate id="footer2_4" />
          </LinkLikeButton>
         </FooterSection>
        <FooterSection>
          <FooterHeader>
            <Translate id="footer3" />
          </FooterHeader>
          <FooterSubheader>
            <Translate id="footer3_1" />
          </FooterSubheader>
          <TextLink style={{ color: 'white', textDecoration: 'none' }} to={HCDC_LINK}>
            <Translate id="footer3_2" />
          </TextLink>
          <FooterSubheader>
            <Translate id="footer3_3" />
          </FooterSubheader>
          <TextLink style={{ color: 'white', textDecoration: 'none'}} to={CLEAN_SLATE_TX_LINK}>
            <Translate id="footer3_4" />
          </TextLink>
          <FooterSubheader>
            <Translate id="footer3_5" />
          </FooterSubheader>
          <TextLink style={{ color: 'white', textDecoration: 'none' }} to={TLH_MAIN_LINK}>
            <Translate id="footer3_6" />
          </TextLink>
        </FooterSection>
        <FooterSection>
          <FooterHeader>
            <Translate id="footer4" />
          </FooterHeader>
          <LinkLikeButton style={{ color: 'white', marginBottom: '10px', fontSize: '16px', fontWeight: 400, textDecoration: 'none' }} 
                          onClick={() => dispatch(setSurveyModal(true))}>
            <Translate id="footer4_1" />
          </LinkLikeButton>
          <TextLink style={{ color: 'white', textDecoration: 'none' }} to={ISSUE_REPORTER_LINK}>
            <Translate id="footer4_2" />
          </TextLink>
        </FooterSection>
      </Section> 
      <Section templateColumns="100%" color={'#052639'} padding="20px 0px 30px 0px" mobilePadding="20px 20px 40px 20px" splitMargin="0px">
        <AssistanceDisclaimer>
          <Translate id="footerDisclaimer" />
        </AssistanceDisclaimer>
      </Section>
    </>
  )
}