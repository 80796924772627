import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useRequest } from 'redux-query-react';
import { TextLink } from '../general/Common';
import { Header, Subheader, Text } from '../general/BeigeTile.js';
import {
  eligibleCasesSelector,
  expunctionEligibleCasesSelector,
  purelyIneligibleCasesSelector,
} from '../../selectors/entities.js';
import { getCasesByEmailToken } from '../../actions/queries';
import { LargeCaseCard } from '../general/LargeCaseCard.js';
import { TLH_EXPUNCTION_LINK } from '../../constants/links';
import { Translate } from 'react-localize-redux';
import { englishIsActiveSelector } from '../../selectors/translations';
import useResultsText from '../../hooks/useResultsText.js';

const Wrapper = styled.div`
  padding: 30px;
`;

// Helper, gives "# case" or "# cases" depending on length of array
const getCasesText = (caseArray, casesText) => {
  return `${caseArray.length} ${casesText}`;
};

// Render page to show case results
export const ResultsEmail = () => {
  const { emailToken } = useParams();
  useRequest(getCasesByEmailToken(emailToken));

  const nondisclosureEligibleCases = useSelector(eligibleCasesSelector);
  const expunctionEligibleCases = useSelector(expunctionEligibleCasesSelector);
  const ineligibleCases = useSelector(purelyIneligibleCasesSelector);

  const englishIsActive = useSelector(englishIsActiveSelector);
  const caseText = englishIsActive ? 'case' : 'caso';

  const { eligibleText: nondisclosureEligText } = useResultsText(
    nondisclosureEligibleCases.length > 1
  );
  const { eligibleText: expunctionEligText } = useResultsText(expunctionEligibleCases.length > 1);
  const { ineligibleText } = useResultsText(ineligibleCases.length > 1);

  return (
    <Wrapper>
      <Header>
        <Translate id="yourCaseResults" />
      </Header>
      <Text>
        <Translate id="basedOnOurRecords" />
      </Text>
      <ul>
        <li>
          {nondisclosureEligibleCases.length} {caseText}
          {nondisclosureEligibleCases.length !== 1 && 's'}{' '}
          <Translate
            id={
              nondisclosureEligibleCases.length !== 1
                ? 'thatMayBeEligPlural'
                : 'thatMayBeEligSingular'
            }
          />
        </li>
        <li>
          {expunctionEligibleCases.length} {caseText}
          {expunctionEligibleCases.length !== 1 && 's'}{' '}
          <Translate
            id={
              expunctionEligibleCases.length !== 1
                ? 'thatMayBeExpEligPlural'
                : 'thatMayBeExpEligSingular'
            }
          />
        </li>
        <li>
          {ineligibleCases.length} {caseText}
          {ineligibleCases.length !== 1 && 's'}{' '}
          <Translate
            id={ineligibleCases.length !== 1 ? 'thatMayBeIneligPlural' : 'thatMayBeIneligSingular'}
          />
        </li>
      </ul>
      <Text>
        <Translate id="weveListedOut" />
      </Text>
      {nondisclosureEligibleCases.length > 0 && (
        <div>
          <Subheader>
            {getCasesText(nondisclosureEligibleCases, nondisclosureEligText)}
            {` `}
            <Translate id="forNondisclosure" />
            {` `}
            <Translate id="found" />
          </Subheader>
          <Text>
            <Translate id="nondisclosureBlurb" />
          </Text>
          {nondisclosureEligibleCases.map((currCase, idx) => (
            <LargeCaseCard key={`el_${idx}`} caseInfo={currCase} />
          ))}
        </div>
      )}
      {expunctionEligibleCases.length > 0 && (
        <div>
          <Subheader>
            {getCasesText(expunctionEligibleCases, expunctionEligText)}
            {` `}
            <Translate id="forExpunction" />
            {` `}
            <Translate id="found" />
          </Subheader>
          <Text>
            <Translate id="expEligCasesBlurb" />
            <TextLink to={TLH_EXPUNCTION_LINK}>Texas Law Help</TextLink>.
          </Text>
          {expunctionEligibleCases.map((currCase, idx) => (
            <LargeCaseCard key={`el_${idx}`} caseInfo={currCase} />
          ))}
        </div>
      )}
      {ineligibleCases.length > 0 && (
        <div>
          <Subheader>
            {getCasesText(ineligibleCases, ineligibleText)} <Translate id="found" />
          </Subheader>
          <Text>
            <Translate id="likelyIneligCasesBlurb" />
          </Text>
          {ineligibleCases.map((currCase, idx) => (
            <LargeCaseCard key={`el_${idx}`} caseInfo={currCase} />
          ))}
        </div>
      )}
    </Wrapper>
  );
};
