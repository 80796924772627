import React, { useState } from 'react';
import styled from 'styled-components';
import { HashLink as Link } from 'react-router-hash-link';
import {
  SHADOW_NONE,
  SHADOW_MENU,
  BG_DARKER,
  SHADOW_DARK,
  BG_PRIMARY,
  PRIMARY,
  BG_LIGHTER,
  MAX_DESKTOP_WIDTH,
  SECONDARY,
} from '../../cssVars.js';
import menuImg from '../../assets/menu.svg';
import closeImg from '../../assets/menu-close.svg';
import { useDispatch } from 'react-redux';
import { TextLink } from './Common.js';
import { Translate, withLocalize } from 'react-localize-redux';
import { setSurveyModal, setDemoInstructionsModal } from '../../actions/modals.js';
import { ISSUE_REPORTER_LINK, ENV } from '../../constants/general.js';

const MENU_WIDTH = 350;

const OuterWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  z-index: 1000;
`;

// shifts menu contents slightly left
const OffsetWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-right: 6px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    font-size: 18px;
  }
`;

const MenuButton = styled.button`
  background-color: ${PRIMARY};
  position: fixed;
  top: -20px;
  right: -15px;
  z-index: 10;
  box-shadow: ${SHADOW_DARK};
  border-radius: 100%;
  color: #fff;
  font-weight: 700;
  width: 105px;
  height: 105px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    width: 100px;
    height: 100px;
  }
`;

const MenuImg = styled.img`
  width: 50px;
  height: 30px;
  margin: 5px 0px;
  margin-top: 20px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    width: 44px;
    height: 30px;
  }
`;

const CloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-right: 20px;
`;

const ClickableWrapper = styled.button`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border: 0px;
  background-color: ${SECONDARY};
  font-size: 18px;
`;

const CloseText = styled.a`
  color: ${BG_PRIMARY};
  font-weight: 600;
  text-decoration: underline;
`;

const CloseImg = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 10px;
`;

const MenuNav = styled.nav`
  background-color: ${SECONDARY};
  color: ${BG_PRIMARY};
  margin: 0px;
  height: 100vh;
  width: ${MENU_WIDTH}px;
  border-left: 3px solid #206691;
  position: fixed;
  z-index: 1;
  top: 0;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  right: ${({ active }) => (active ? 0 : -MENU_WIDTH)}px;
  overflow-x: hidden;
  transition: 0.5s;
  box-shadow: ${({ active }) => (active ? SHADOW_MENU : SHADOW_NONE)};
`;

const MenuList = styled.div`
  margin: 0px;
  padding: 10px 30px;
  font-size: 20px;
  > a,
  button.like-text {
    font-weight: 700;
    font-size: 20px;
    margin: 15px 0px;
    background-color: transparent;
    border: none;
    padding: 0px;
    cursor: pointer;
    transition: color 0.2s ease;
    color: ${BG_LIGHTER};
    text-decoration: none;
    display: block;
    &:hover {
      font-color: ${BG_DARKER};
    }
  }
`;

export const SlideOutMenu = ({ active, setActive }) => {
  const close = () => setActive(false);
  const dispatch = useDispatch();
  const handleFeedbackSurveyClick = () => {
    dispatch(setSurveyModal(true));
    close();
  };
  const handleDemoInstructionsClick = () => {
    dispatch(setDemoInstructionsModal(true));
    close();
  };

  return (
    <OuterWrapper active={active} onClick={close}>
      <MenuNav role="navigation" active={active} onClick={e => e.stopPropagation()}>
        <CloseWrapper>
          <ClickableWrapper tabIndex="0" onClick={close}>
            <CloseText>
              <Translate id="close" />
            </CloseText>
            <CloseImg src={closeImg} alt="" />
          </ClickableWrapper>
        </CloseWrapper>
        <MenuList>
          <Link onClick={close} smooth tabIndex="0" to="/#top">
            <Translate id="menuHome" />
          </Link>
          {ENV === 'demo' && (
            <button className="like-text" onClick={handleDemoInstructionsClick} tabIndex="0">
              Demo Instructions
            </button>
          )}
          <Link onClick={close} tabIndex="0" to="/check/pre-check#top">
            <Translate id="menuChecker" />
          </Link>
          <Link onClick={close} tabIndex="0" to="/steps/review">
            <Translate id="menuSteps" />
          </Link>
          <Link onClick={close} tabIndex="0" to="/info/faq">
            <Translate id="menuFAQ" />
          </Link>
          <Link onClick={close} tabIndex="0" to="/info/nondisclosure-expunction">
            <Translate id="menuInfo" />
          </Link>
          <Link onClick={close} tabIndex="0" to="/info/how-to-file">
            <Translate id="menuFiling" />
          </Link>
          <button className="like-text" onClick={handleFeedbackSurveyClick} tabIndex="0">
            <Translate id="menuFeedback" />
          </button>
          <TextLink to={ISSUE_REPORTER_LINK}>
            <Translate id="menuReport" />
          </TextLink>
        </MenuList>
      </MenuNav>
    </OuterWrapper>
  );
};

const Menu = props => {
  const [menuActive, setMenuActive] = useState(false);
  return (
    <>
      <MenuButton onClick={() => setMenuActive(true)}>
        <OffsetWrapper>
          <MenuImg src={menuImg} alt="" />
          <Translate id="menu" />
        </OffsetWrapper>
      </MenuButton>
      <SlideOutMenu active={menuActive} setActive={setMenuActive} {...props} />
    </>
  );
};

export default withLocalize(Menu);
