import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { setEmailResultsModal } from '../../actions/modals';
import { useSendCaseResultsEmail } from '../../hooks/editors';
import { showEmailResultsSelector } from '../../selectors/modals';
import { emailSelector } from '../../selectors/entities';
import { Button } from './Common';
import { Modal, ModalHeader as Title } from './Modal';
import { Formik } from 'formik';
import { validateEmails } from '../../utils/validators';
import { TextField } from './FormFields';
import { Translate } from 'react-localize-redux'

const Text = styled.p`
  margin: 20px 0px;
`

export const EmailResultsModal = () => {

  const showModal = useSelector(showEmailResultsSelector);
  const dispatch = useDispatch();
  const onClose = () => dispatch(setEmailResultsModal(false));
  const initEmail = useSelector(emailSelector)

  const [submit, submitting] = useSendCaseResultsEmail(onClose);

  if (!showModal) {
    return null;
  }

  return (
    <Formik
      validate={validateEmails}
      initialValues={{ email: initEmail || '' }}
      onSubmit={submit}>
      {({ handleSubmit, errors, touched, values, submitCount }) => {
        const getError = (name) => (touched[name] || submitCount >= 1) && errors[name];
        return (
          <Modal closeOnOuterClick={true} onClose={onClose} style={{ width: 'min(95%, 550px)' }}>
            <Title><Translate id="sendEmail" /></Title>
            <Text style={{marginBottom: '10px'}}>
              <Translate id="enterEmail3" />
            </Text>
            <Text style={{marginTop: '10px'}}>
              <Translate id="sensitiveEmailNote" />
            </Text>
            <TextField name="email" placeholder="example@gmail.com" error={getError("email")} />
            <Button isLoading={submitting} onClick={handleSubmit}><Translate id="sendEmail" /></Button>
          </Modal>
        )}}
    </Formik>
  )
}