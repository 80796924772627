import React from 'react';
import styled from 'styled-components';
import { MAX_DESKTOP_WIDTH, BG_PRIMARY, SECONDARY } from '../../cssVars.js';
import { useHistory } from 'react-router-dom';
import { BUTTON_CLICK } from '../../constants/analytics.js';
import { trackEvent } from '../../utils/googleAnalytics.js';
import { Translate, withLocalize } from 'react-localize-redux';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.h1`
  font-weight: 900;
  font-size: 26px;
  color: ${SECONDARY};
  margin: 10px 15px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    font-size: 20px;
  }
`;

const HomepageButton = styled.button`
  background-color: ${SECONDARY};
  color: ${BG_PRIMARY};
  padding: 7px 10px;
  padding-top: 6px;
  padding-right: 15px;
  font-weight: 600;
  font-size: 16px;
  border-radius: 0px 0px 6px 0px;
  cursor: pointer;
  border: 0px;
  position: absolute;
  left: 0px;
  top: 0px;
`;

// Header used for the Eligibility Checker and Steps-to-File flows.
// Includes prominent title and a button to go back to the hompage
const Header = ({ titleId }) => {
  const history = useHistory();
  const handleClick = () => {
    trackEvent(BUTTON_CLICK, { button_name: 'Back to homepage' });
    history.push("/");
  }
  return (
    <>
      <HomepageButton onClick={handleClick}><Translate id="backToHomepageCorner"/></HomepageButton>
      <Wrapper>
        <Title>
          <Translate id={titleId} />
        </Title>
      </Wrapper>
    </>
  )
}

export default Header;