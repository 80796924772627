import React from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { Translate } from 'react-localize-redux';
import { SHADOW_TINY, PRIMARY, PRIMARY_DARK, MAX_DESKTOP_WIDTH } from '../../cssVars';
import { HELP_CLICK_CORNER } from '../../constants/analytics';
import { trackEvent } from '../../utils/googleAnalytics';
import personIcon from '../../assets/person.svg';
import { useDispatch } from 'react-redux';
import { setHelpModal } from '../../actions/modals';
import { useShowStickyButtons } from '../../hooks/useShowStickyButtons';

const StyledButton = styled.button`
  border: none;
  background-color: ${PRIMARY};
  color: white;
  font-weight: 600;
  font-size: 16px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  border-radius: 20px 0px 0px 20px;
  ${({sticky, shiftDown}) => sticky 
    ? `position: fixed;
       top: ${shiftDown ? 140 : 50}px;
       right: 0px;
      `
    : `position: absolute;
       top: 50px;
       right: 0px;`}
  box-shadow: ${SHADOW_TINY};
  transition: background-color 0.2s ease;
  &:hover {
    background-color: ${PRIMARY_DARK};
  }
  z-index: 10;
  > img {
    width: 15px;
    height: 15px;
    margin-left: 6px;
  }
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    top: ${({ shiftDown, sticky }) => (shiftDown && sticky) ? 125 : 33}px;
    padding: 4px 15px;
  }
`

export const HelpButton = ({ onInfoPages }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const sticky = useShowStickyButtons();
  
  const handleClick = () => {
    trackEvent(HELP_CLICK_CORNER, { help_accessed_on_page: location.pathname });
    dispatch(setHelpModal(true));
  }

  return (
    <StyledButton sticky={sticky} shiftDown={onInfoPages == null ? false : true} onClick={handleClick}>
      <Translate id="help" /> <img src={personIcon} alt="" />
    </StyledButton>
  )
}