import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { setVeteransReemploymentPrgModal, setVeteransTreatmentCrtModal } from '../../actions/modals';
import { showVetsReemploymentPrgSelector, showVetsTreatmentCrtSelector } from '../../selectors/modals';
import { Modal, ModalHeader as Title } from './Modal';
import { Translate } from 'react-localize-redux'

const Text = styled.p`
  margin: 20px 0px;
`

export const VetsTreatmentCourtModal = () => {
  const showModal = useSelector(showVetsTreatmentCrtSelector);
  const dispatch = useDispatch();
  const onClose = () => dispatch(setVeteransTreatmentCrtModal(false))

  if (!showModal) {
    return null;
  }

  return (
    <Modal closeOnOuterClick={true} onClose={onClose} style={{ width: 'min(95%, 550px)' }}>
      <Title><Translate id="whatIsVetsTreatment"/></Title>
      <Text>
        <Translate id="vetsTreatmentBlurb"/>
      </Text>
    </Modal>
  )
}

export const VetsReemploymentPrgModal = () => {
  const showModal = useSelector(showVetsReemploymentPrgSelector);
  const dispatch = useDispatch();
  const onClose = () => dispatch(setVeteransReemploymentPrgModal(false));

  if (!showModal) {
    return null;
  }

  return (
    <Modal closeOnOuterClick={true} onClose={onClose} style={{ width: 'min(95%, 550px)' }}>
      <Title><Translate id="whatIsVetsReemployment"/></Title>
      <Text>
        <Translate id="vetsReemploymentBlurb"/>
      </Text>
    </Modal>
  )
}