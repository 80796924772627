import React from 'react';
import styled from 'styled-components';
import { Button, InvertedButton, TextLink } from '../general/Common';
import { Text } from '../general/BeigeTile.js';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { searchValuesSelector } from '../../selectors/entities.js';
import { BG_DARKER } from '../../cssVars';
import retryImg from '../../assets/retry.svg';
import { getMonthFromIndex } from '../../utils/helpers';
import useDates from '../../hooks/useDates';
import { englishIsActiveSelector } from '../../selectors/translations';
import { Translate } from 'react-localize-redux';
import { SERVER_URL } from '../../constants/general';
import { HCDC_LINK } from '../../constants/links';

const SearchQuery = styled.div`
  background-color: ${BG_DARKER};
  padding: 15px;
  margin: 0px -15px;
  margin-bottom: 20px;
  border-radius: 4px;
  color: #403c24;
  > div {
    margin: 2px 0px;
    > b {
      font-weight: 700;
      margin-left: 5px;
    }
  }
`;

const RetryIcon = styled.img`
  width: 25px;
  height: 25px;
  position: absolute;
  right: 17px;
  top: 10px;
`;

export const SearchRangeNote = () => {
  const { startDate, endDate } = useDates();
  return (
    <>
      <Translate id="searchRangeNote" data={{ startDate, endDate }} />
    </>
  );
};

// Content when no cases for a given search was found.
// Restates the name(s) & DOB used in search & gives prompt to retry.
export const NoCasesFound = ({ other }) => {
  const history = useHistory();
  const searchValues = useSelector(searchValuesSelector);
  const dob = new Date(searchValues.dob);
  const isEnglishActive = useSelector(englishIsActiveSelector);

  let otherContent = '';
  if (other) {
    otherContent = isEnglishActive ? 'other' : 'otro';
  }

  return (
    <>
      <Text>
        <Translate id="couldntFindOther" data={{ other: otherContent }} />
      </Text>
      <SearchQuery>
        <div>
          <Translate id="name" />{' '}
          <b>
            {searchValues.firstName} {searchValues.middleName || ''} {searchValues.lastName}{' '}
            {searchValues.suffix || ''}
          </b>
        </div>
        {/* Display aliases if they were included in the search */}
        {searchValues.aliasFirstNames.length > 0 && (
          <div>
            <Translate id="otherFirstNames" /> <b>{searchValues.aliasFirstNames.join(', ')}</b>
          </div>
        )}
        {searchValues.aliasLastNames.length > 0 && (
          <div>
            <Translate id="otherLastNames" /> <b>{searchValues.aliasLastNames.join(', ')}</b>
          </div>
        )}
        <div>
          <Translate id="dob" />:{' '}
          <b>
            {isEnglishActive ? (
              <>
                {getMonthFromIndex(dob.getUTCMonth())} {dob.getUTCDate()}, {dob.getUTCFullYear()}
              </>
            ) : (
              <>
                el {dob.getUTCDate()} de {getMonthFromIndex(dob.getUTCMonth(), true).toLowerCase()}{' '}
                de {dob.getUTCFullYear()}
              </>
            )}
          </b>
        </div>
      </SearchQuery>
      <SearchRangeNote />
      <Text>
        <Translate id="trySearchingAgain" />
        {` `}
        <Translate id="trySearchingAgain2" />
        {` `}
        <TextLink to={HCDC_LINK}>
          <Translate id="trySearchingAgain3" />
        </TextLink>
        {` `}
        <Translate id="trySearchingAgain4" />
        {` `}
        <TextLink
          to={`${SERVER_URL}/pdfs/HCDC_accessing_records_${isEnglishActive ? 'en' : 'es'}.pdf`}
        >
          <Translate id="here" />
        </TextLink>
        {` `}
        <Translate id="trySearchingAgain5" />
      </Text>
      <Button
        type="button"
        style={{ minWidth: '250px', marginBottom: '20px', position: 'relative' }} // display: 'flex', alignItems: 'center', justifyContent: 'center'
        onClick={() => history.push('/check/basic-info')}
      >
        <Translate id="searchAgain" /> <RetryIcon src={retryImg} alt="arrow curved in a circle" />
      </Button>
      <Text>
        <Translate id="returnToHomepageNote" />
      </Text>
      <InvertedButton
        style={{ minWidth: '250px' }}
        type="button"
        onClick={() => history.push({ pathname: '/', search: '?modal=survey' })}
      >
        <Translate id="returnToHomepage" />
      </InvertedButton>
    </>
  );
};
