import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components'
import { setEligibilityDisclaimerModal } from '../../actions/modals';
import { showEligibilityDisclaimerSelector } from '../../selectors/modals';
import { Modal, ModalHeader as Title } from './Modal';
import { Translate } from 'react-localize-redux'

const Text = styled.p`
  margin: 20px 0px;
`

// Disclaimer specific to receiving potentially eligible case results
export const EligibilityDisclaimerModal = () => {

  const showDisclaimer = useSelector(showEligibilityDisclaimerSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // Allows this modal to be triggered by including a search param in the url,
  // so it can be accessed by link (we include a link to disclaimer in the results email PDF)
  useEffect(() => {
    if (!showDisclaimer && location.search.includes('modal=eligibility_disclaimer')) {
      dispatch(setEligibilityDisclaimerModal(true));
    }
    // eslint-disable-next-line
  }, [location.search]);

  const onClose = () => {
    history.push({ search: '' });
    dispatch(setEligibilityDisclaimerModal(false));
  }

  if (!showDisclaimer) {
    return null;
  }

  return (
    <Modal closeOnOuterClick={true} onClose={onClose} style={{ width: 'min(95%, 550px)' }}>
      <Title><Translate id="eligDisclaimer" /></Title>
      <Text>
        <Translate id="eligDiscBlurb1" />
      </Text>
      <Text>
        <Translate id="eligDiscBlurb2" />
      </Text>
      <Text>
        <Translate id="eligDiscBlurb3" />
      </Text>
    </Modal>
  )
}