import React from 'react';
import styled from 'styled-components';
import { TLH_EXPUNCTION_LINK, TLH_NONDISCLOSURE_LINK } from '../../constants/links';
import { TextLink } from '../general/Common';
import { DeepDiveHeader, DeepDiveWrapper } from './DeepDives';
import { Translate } from 'react-localize-redux';

export const Text = styled.p`
  margin: 20px 0px;
`;

const Subtitle = styled.h3`
  margin-bottom: 20px;
  margin-top: 40px;
  font-size: 18px;
  font-weight: 700;
`;

export const NondisclosureExpunction = () => {
  return (
    <DeepDiveWrapper>
      <DeepDiveHeader titleId='nondisclosureExp' />
      <Subtitle>
        <Translate id="whatIsNondisclosure" />
      </Subtitle>
      <Text>
        <Translate id="whatIsNondisclosureAns" />
      </Text>
      <Text>
        <b>
          <Translate id="websitePurpose" />
        </b>
      </Text>
      <Text>
        <Translate id="learnMoreTLH" /> <TextLink to={TLH_NONDISCLOSURE_LINK}>
            {TLH_NONDISCLOSURE_LINK}
          </TextLink>
      </Text>
      <Subtitle>
        <Translate id="whatIsExpunction" />
      </Subtitle>
      <Text>
        <Translate id="whatIsExpunctionAns" />
      </Text>
      <Text>
        <Translate id="websiteUnpurpose" /> <TextLink to={TLH_EXPUNCTION_LINK}>{TLH_EXPUNCTION_LINK}</TextLink>
      </Text>
    </DeepDiveWrapper>
  )
}
