const API_ROOT = '/api';

// Submits the pre-check initial form inputs (hasCrimeOutsideTexas and hasCrimeBefore1990) and creates a new app in db
export const preCheckQuery = (values, jwtToken) => ({
  url: `${API_ROOT}/application/pre-check`,
  options: { method: 'POST', headers: { Authorization: `Bearer ${jwtToken}` } },
  body: values,
  transform: resp => ({
    token: resp.token,
    tokenExpirationTime: resp.tokenExpirationTime,
    hasCrimeOutsideTexas: values.hasCrimeOutsideTexas,
    hasCrimeBefore1990: values.hasCrimeBefore1990,
  }),
  update: {
    token: (prev, updated) => updated,
    tokenExpirationTime: (prev, updated) => updated,
    hasCrimeOutsideTexas: (prev, updated) => updated,
    hasCrimeBefore1990: (prev, updated) => updated,
  },
});

// Appends name(s) and DOB to app doc to search HCDC cases
// to receive the user's current eligibility info & token
export const checkBasicInfoQuery = (values, jwtToken) => ({
  url: `${API_ROOT}/application/cont/case-lookup`,
  options: { method: 'POST', headers: { Authorization: `Bearer ${jwtToken}` } },
  body: values,
  transform: resp => ({
    eligibilityInfo: resp.eligibilityInfo,
  }),
  update: {
    eligibilityInfo: (prev, updated) => updated,
    searchValues: () => values,
    email: () => null, // Clear the email state when a new app is created
  },
});

// Submits the user's confirmation of a given set of cases by spn
export const confirmCasesQuery = (spn, jwtToken) => ({
  url: `${API_ROOT}/application/cont/confirm-cases`,
  options: { method: 'POST', headers: { Authorization: `Bearer ${jwtToken}` } },
  body: { spn },
  transform: resp => ({
    eligibilityInfo: resp.eligibilityInfo,
  }),
  update: {
    eligibilityInfo: (prev, updated) => updated,
  },
});

// Submits the user's disqualifying history and preliminary followup question responses
export const additionalInfoQuery = (values, jwtToken) => ({
  url: `${API_ROOT}/application/cont/eligibility-preliminary-questions`,
  options: { method: 'POST', headers: { Authorization: `Bearer ${jwtToken}` } },
  body: values,
  transform: resp => ({
    eligibilityInfo: resp.eligibilityInfo,
    // Saving the cases returned by the "Additional Info" query so we can reference them as we go though the case verifications
    postPreliminaryCases: resp.eligibilityInfo ? resp.eligibilityInfo.cases : [],
  }),
  update: {
    eligibilityInfo: (prev, updated) => updated,
    postPreliminaryCases: (prev, updated) => updated,
  },
});

// Submits the user's case-specific followup question responses
export const caseFollowupQuery = (formValuesWithId, jwtToken) => ({
  url: `${API_ROOT}/application/cont/case-followup-questions`,
  options: { method: 'POST', headers: { Authorization: `Bearer ${jwtToken}` } },
  body: formValuesWithId,
  transform: resp => ({
    eligibilityInfo: resp.eligibilityInfo,
  }),
  update: {
    eligibilityInfo: (prev, updated) => updated,
  },
});

// Submits the user's feedback survey responses
export const feedbackSurveyQuery = (values, jwtToken) => ({
  url: `${API_ROOT}/survey/submit-feedback`,
  options: { method: 'POST', headers: { Authorization: `Bearer ${jwtToken}` } },
  body: values,
});

// Submits the user's help form response
export const helpFormQuery = (values, jwtToken) => ({
  url: `${API_ROOT}/survey/submit-help-form`,
  options: { method: 'POST', headers: { Authorization: `Bearer ${jwtToken}` } },
  body: values,
});

// Submits request to send an email to the user with their case results
export const sendCaseResultsEmailQuery = (email, useSpanishURL, jwtToken) => ({
  url: `${API_ROOT}/application/email/send-case-results-email`,
  options: { method: 'POST', headers: { Authorization: `Bearer ${jwtToken}` } },
  body: { email, useSpanishURL },
  update: { email: () => email },
});

// Submits request to send an email to the user useSpanishURL their recommended petitions
export const sendPetitionsEmailQuery = (email, useSpanishURL, jwtToken) => ({
  url: `${API_ROOT}/application/email/send-petitions-email`,
  options: { method: 'POST', headers: { Authorization: `Bearer ${jwtToken}` } },
  body: { email, useSpanishURL },
  update: { email: () => email },
});

// Submits request to send an email to the user with the "Know Your Rights" page
export const sendKnowYourRightsQuery = (email, useSpanishURL, jwtToken) => ({
  url: `${API_ROOT}/application/email/send-know-your-rights-email`,
  options: { method: 'POST', headers: { Authorization: `Bearer ${jwtToken}` } },
  body: { email, useSpanishURL },
  update: { email: () => email },
});

// Requests the cases associated with the given temporary token for email PDF generation
export const getCasesByEmailToken = emailToken => ({
  url: `${API_ROOT}/application/pdf/case-info`,
  options: { method: 'GET', headers: { Authorization: `Bearer ${emailToken}` } },
  transform: resp => ({
    eligibilityInfo: resp.eligibilityInfo,
  }),
  update: {
    eligibilityInfo: (prev, updated) => updated,
  },
});

// Get lookup end dates from backend environment variables
export const getLookupEndDateQuery = () => ({
  url: `${API_ROOT}/application/lookup-end-date`,
  options: { method: 'GET' },
  transform: resp => ({
    lookupEndDateEn: resp.lookupEndDateEn,
    lookupEndDateEs: resp.lookupEndDateEs,
  }),
  update: {
    lookupEndDateEn: (prev, updated) => updated,
    lookupEndDateEs: (prev, updated) => updated,
  },
});
