require('dotenv').config()

export const SITE_URL = process.env.REACT_APP_SITE_URL;
export const ENV = process.env.REACT_APP_ENV;

// only used for development, for all other environments server URL is the same as site URL
export const SERVER_URL = ENV === 'development' ? process.env.REACT_APP_SERVER_URL : process.env.REACT_APP_SITE_URL;

export const LOOKUP_START_DATE_EN = 'January 1, 1990';
export const LOOKUP_START_DATE_ES = 'el 1 de enero de 1990';

export const ISSUE_REPORTER_LINK = "https://docs.google.com/forms/d/e/1FAIpQLSd7nA76ZCQgpjuS9hjCUzco39aWvcam2BOfgt6bQQD18rMaMw/viewform"