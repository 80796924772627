import React from 'react';
import { Provider } from 'react-redux';
import { Provider as ReduxQueryProvider } from 'redux-query-react';
import { BrowserRouter as Router } from 'react-router-dom';
import { LocalizeProvider } from "react-localize-redux";
import store from './reducers/store';
import { Routes } from './components/Routes'
import ScrollToTop from './components/general/ScrollToTop';
import PauseScrollWhenModalIsOpen from './components/general/PauseScrollWhenModalIsOpen';
import TokenExpirationTimer from './components/general/TokenExpirationTimer';
import 'react-toastify/dist/ReactToastify.css';
import { RouteChangeTracker } from './components/general/RouteChangeTracker';
import Translations from './components/general/Translations';


const App = () => (
  <LocalizeProvider store={store}>
    <Provider store={store}>
      <ReduxQueryProvider queriesSelector={(state) => state.queries}>
        <Router>
          <ScrollToTop />
          <RouteChangeTracker />
          <PauseScrollWhenModalIsOpen />
          <TokenExpirationTimer />
          <Translations defaultToSpanish={false} />
          <Routes />
        </Router>
      </ReduxQueryProvider>
    </Provider>
  </LocalizeProvider>
);

export default App;