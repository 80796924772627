import React from 'react';
import styled from 'styled-components';
import closeIcon from '../../assets/green_close.svg'
import { MAX_DESKTOP_WIDTH, PRIMARY, RADIUS } from '../../cssVars';
import FocusTrap from 'focus-trap-react';
import { Translate } from 'react-localize-redux';

const OuterWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgb(100,100,100,0.45);
`;

const ModalWrapper = styled.div`
  position: relative;
  margin: 10px;  
  background-color: white;
  border-radius: ${RADIUS};
  box-shadow: 0 2px 10px rgba(0,0,0,.20);
  padding: 30px;
  width: 550px;
  width: min(550px, 95%);
  max-height: 85%;
  overflow-x: auto;
  overflow-y: scroll;
  @media only screen and (max-width: 600px) {
    width: 95%;
  }
`;

const CloseButton = styled.img`
  margin-left: 10px;
  margin-top: 2px;
  height: 18px;
  width: 18px;
`

const CloseWrapper = styled.button`
  color: ${PRIMARY};
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    top: 12px;
    right: 12px;
  }
  &:focus {
    box-shadow: none !important;
  }
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: ${({isRTL}) => isRTL ? 'flex-start' : 'flex-end'};
  margin-top: 20px;
  > div {
    margin-left: 10px;
  }
  > a {
    margin-left: 10px;
  }
`

export const ModalHeader = styled.div`
  font-family: 'Saira Extra Condensed', sans-serif;
  font-size: 30px;
  font-weight: 600;
  margin: 15px 0px 0px 0px;
  text-transform: uppercase;
`

export const Modal = ({ children, onClose, style, wrapperStyle, closeOnOuterClick }) => {
  const handleClose = () => () => {
    onClose();
  } 
  return (
  <OuterWrapper style={wrapperStyle} onClick={() => closeOnOuterClick ? handleClose('clicked outside modal')() : null}>
    <FocusTrap>
      <ModalWrapper style={style} onClick={(e) => e.stopPropagation()}>
        <CloseWrapper type="button" onClick={handleClose('used button')}>
          <Translate id="close" />
          <CloseButton tabIndex="0" src={closeIcon} alt="" />
        </CloseWrapper>
        {children}
      </ModalWrapper>
    </FocusTrap>
  </OuterWrapper>
)};
