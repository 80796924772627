import React from 'react';
import { Beforeunload } from 'react-beforeunload';
import { Redirect, Route, Switch } from 'react-router-dom';
import Header from '../general/Header';
import { BubbleNavigation } from './BubbleNavigation';
import { PreCheck } from './PreCheck';
import { Review } from './Review';
import { Petition } from './Petition';
import { Payment } from './Payment';
import { File } from './File';
import { Hearing } from './Hearing';
import { Order } from './Order';
import { useSelector } from 'react-redux';
import { tokenSelector } from '../../selectors/entities';
import LanguageToggle from '../general/LanguageToggle';
import { DemoInstructionsModal } from '../general/DemoInstructionsModal';
import { HelpButton } from '../general/HelpButton';

// Root component for all of the "Next steps" pages
export const StepsRoot = () => {
  // Want to show refresh warning if there's an active token in state while on any of the next-steps pages
  const showRefreshWarning = useSelector(tokenSelector) != null; 
  return (
    <>
      <DemoInstructionsModal />
      <div style={{ backgroundColor: 'rgba(0,0,0,0.1)', minHeight: '100vh', textAlign: "center" }}>
        {showRefreshWarning && <Beforeunload onBeforeunload={(event) => event.preventDefault()} />}
        <Header titleId="menuSteps" />
        <Route path='/steps/:id' component={BubbleNavigation} />
        <Switch>
          <Route path="/steps/review" component={Review} />
          <Route path="/steps/petition" component={Petition} />
          <Route path="/steps/payment" component={Payment} />
          <Route path="/steps/file" component={File} />
          <Route path="/steps/hearing" component={Hearing} />
          <Route path="/steps/order" component={Order} />
          <Route path="/steps/precheck" component={PreCheck} />
          <Redirect to="/steps/review" />
        </Switch>
        <LanguageToggle />
        <HelpButton />
      </div>
    </>
  )
}
