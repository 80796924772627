import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer, Flip } from 'react-toastify';
import { InformationalRoot } from './informational/InformationalRoot';
import { CheckRoot } from './check/CheckRoot';
import { StepsRoot } from './steps/StepsRoot';
import { DisclaimerModal } from './general/DisclaimerModal';
import { EligibilityDisclaimerModal } from './general/EligibilityDisclaimerModal';
import { CaseModal } from './general/CaseModal';
import { PrivacyModal } from './general/PrivacyModal';
import { EmailResultsModal } from './general/EmailResultsModal';
import { SurveyModal } from './general/SurveyModal';
import { AboutUsModal } from './general/AboutUsModal';
import { EmailsRoot } from './emails/EmailsRoot';
import { EmailPetitionsModal } from './general/EmailPetitionsModal';
import { EmailKnowYourRightsModal } from './general/EmailKnowYourRightsModal';
import { VetsReemploymentPrgModal, VetsTreatmentCourtModal } from './general/QuestionAssistModals';
import { GuidedFormModal } from './general/GuidedFormModal';
import { HelpModal } from './general/HelpModal';

// High-level routes for the application
// Also provides modals and toast (little confirmation popups) used throughout the app
export const Routes = () => {
  return (
    <>
      <CaseModal />
      <DisclaimerModal />
      <EligibilityDisclaimerModal />
      <PrivacyModal />
      <EmailResultsModal />
      <EmailPetitionsModal />
      <EmailKnowYourRightsModal />
      <SurveyModal />
      <HelpModal />
      <AboutUsModal />
      <VetsTreatmentCourtModal />
      <VetsReemploymentPrgModal />
      <GuidedFormModal />
      <Switch>
        <Route path="/check" component={CheckRoot} />
        <Route path="/steps" component={StepsRoot} />
        <Route path="/emails" component={EmailsRoot} />
        <Route path="/" component={InformationalRoot} />
      </Switch>
      <ToastContainer autoClose={3000} position="bottom-right" transition={Flip} hideProgressBar />
    </>
  );
};
