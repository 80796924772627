import { useOnMobile } from './useOnMobile';
import { useLocation } from 'react-router';

const formPages = ['/check/pre-check', '/check/additional-info'];

// Sticky buttons are generally helpful, e.g. for language toggle and help - as long as they aren't getting in the way.
// This hook returns whether it's appropriate to show sticky buttons based on screen size & the current page.
export const useShowStickyButtons = () => {
  const onMobile = useOnMobile();
  const location = useLocation();

  // We can always show sticky buttons on desktop - they won't be in the way
  if (!onMobile) {
    return true;
  }
  // On mobile, we can show them as sticky unless the page has a long form on it (form pages)
  if (formPages.includes(location.pathname)) {
    return false;
  }

  return true;
};
