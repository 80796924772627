// from https://reactrouter.com/web/guides/scroll-restoration
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { anyModalIsActiveSelector } from "../../selectors/modals";

// Listener to pause the body scroll whenever a modal is open
export default function PauseScrollWhenModalIsOpen() {
  const modalIsOpen = useSelector(anyModalIsActiveSelector);

  // Prevent page scrolling when modal is open
  useEffect(() => {
    if (modalIsOpen) {
      document.querySelector("body").style.overflow = 'hidden';
    } else {
      document.querySelector("body").style.overflow = 'visible';
    }
  }, [modalIsOpen])

  return null;
}