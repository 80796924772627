import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { Translate } from 'react-localize-redux'
import { Formik } from 'formik';
import { ParagraphTextField, TextField, MultiFieldLine } from './FormFields.js';
import { setHelpModal } from '../../actions/modals';
import { showHelpSelector } from '../../selectors/modals';
import { Modal, ModalHeader as Title } from './Modal';
import { Button, TextLink } from './Common.js';
import { useHelpForm } from '../../hooks/editors.js';
import { BG_DARKER, BG_PRIMARY } from '../../cssVars.js';
import { validateHelpForm } from '../../utils/validators.js';

const Text = styled.p`
  margin: 20px 0px;
  margin-bottom: 5px;
`

const FormWrapper = styled.div`
  background-color: ${BG_PRIMARY};
  border: 1px solid ${BG_DARKER};
  padding: 10px;
  padding-top: 0px;
  margin-top: 10px;
  border-radius: 6px;
  > div:last-child {
    text-align: center;
  }
`

// Modal displaying how to get in contact with Beacon Law's support
export const HelpModal = () => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setHelpModal(false));
  };

  const [submit, submitting] = useHelpForm(onClose);

  // Show the survey when set to show in state
  const showModal = useSelector(showHelpSelector);

  if (!showModal) {
    return null;
  }

  return (
    <Modal closeOnOuterClick={true} onClose={onClose} style={{ width: 'min(95%, 550px)' }}>
      <Title><Translate id="helpModal1" /></Title>
      <Text>
        <Translate id="helpModal2" />
      </Text>
      <h3><Translate id="helpModal3" /></h3>
      <div>
        <Translate id="helpModal4" /> <TextLink to="tel:832-695-4760">(832) 695-4760</TextLink> <Translate id="helpModal5" />
        <ul>
          <li><Translate id="helpModal6" /></li>
        </ul>
        <Translate id="helpModal7" />
      </div>
      <h3><Translate id="helpModal8" /></h3>
      <div>
        <Translate id="helpModal9" />
      </div>
      <Formik
        initialValues={{
          email: '',
          phone: '',
          description: '',
        }}
        enableReinitialize={true}
        validate={validateHelpForm}
        onSubmit={submit}>
        {({ handleSubmit, values, errors, touched, submitCount }) => {
          const getError = (name) => (touched[name] || submitCount >= 1) && errors[name];
          return (
            <FormWrapper>
              <MultiFieldLine columns={`auto auto`}>
                <TextField name="email" labelId="email" error={getError('email')} />
                <TextField name="phone" labelId="phone" error={getError('phone')} />
              </MultiFieldLine>
              <ParagraphTextField name="description" labelId="helpDescription" error={getError('description')} />
              <div>
                <Button isLoading={submitting} wrapperStyle={{ marginTop: '10px' }} fillOnMobile={true} onClick={handleSubmit}>
                  <Translate id="submit" />
                </Button>
              </div>
            </FormWrapper>
          )}}
      </Formik> 
    </Modal>
  )
}