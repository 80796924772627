import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { setCase, setEmailPetitionsModal, setGuidedFormModal } from '../../actions/modals';
import { eligibleCasesSelector } from '../../selectors/entities';
import { BeigeTile, BottomSpacedHeader, Subheader, Text } from '../general/BeigeTile';
import { CaseCard } from '../general/CaseCard';
import { Button, InvertedButton, LinkLikeButton, StyledHR, TextLink } from '../general/Common';
import { TileBackArrow } from '../general/TileBackArrow';
import {
  BOATING_PETITION_ID,
  PETITION_LINKS_BY_FORM_ID,
  TLS_GUIDED_FORM_LINKS_BY_FORM_ID,
  TLS_LINKS_BY_FORM_ID,
} from '../../constants/cases';
import { SERVER_URL } from '../../constants/general';
import { LargeCaseCard } from '../general/LargeCaseCard';
import { getMisdemeanorLevelAlertType, getRecommendedFormIdForCase } from '../../utils/cases';
import { ImportantTile } from '../informational/FilingContent';
import { FRESH_START_LINK, TLH_NONDISCLOSURE_LINK } from '../../constants/links';
import { Translate } from 'react-localize-redux';
import { longLabelByIdEn, longLabelByIdEs } from '../../constants/steps';
import { englishIsActiveSelector } from '../../selectors/translations';
import { HelpText } from '../general/HelpText';

const SpacedList = styled.ol`
  > li {
    margin-top: 15px;
    padding-left: 10px;
  }
`;

const SpacedUnorderedList = styled.ul`
  > li {
    margin-top: 10px;
  }
`;

const Alert = styled(ImportantTile)`
  margin-top: 10px;
  > b {
    font-weight: 600;
  }
`;

const MisdemeanorLevelAlert = ({ alertType, hcdcInstLink }) => {
  return (
    <Alert>
      {alertType === 'DWI_ALERT' ? (
        <>
          <Translate id="levelAlert" />
        </>
      ) : (
        <>
          <Translate id="theftAlert" />
        </>
      )}{' '}
      <Translate id="noLevelAlert" />{' '}
      <TextLink to={hcdcInstLink}>
        <Translate id="here" />
      </TextLink>{' '}
      <Translate id="noLevelAlert" />
    </Alert>
  );
};

// Parent component for the "Fill out petition" page
export const Petition = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const eligibleCases = useSelector(eligibleCasesSelector);
  const clickCase = currCase => () => dispatch(setCase(currCase));
  const englishIsActive = useSelector(englishIsActiveSelector);
  const longLabelById = englishIsActive ? longLabelByIdEn : longLabelByIdEs;
  const hcdcInstLink = `${SERVER_URL}/pdfs/HCDC_accessing_records_${
    englishIsActive ? 'en' : 'es'
  }.pdf`;

  return (
    <BeigeTile>
      <BottomSpacedHeader>
        <TileBackArrow onClick={() => history.push('/steps/review')} />
        {longLabelById.petition}
        {eligibleCases.length > 1 && 's'}
      </BottomSpacedHeader>
      {eligibleCases.length > 0 ? (
        eligibleCases.length > 1 ? (
          <MultipleEligibleCasesContent eligibleCases={eligibleCases} clickCase={clickCase} />
        ) : (
          <OneEligibleCaseContent eligibleCase={eligibleCases[0]} clickCase={clickCase} />
        )
      ) : (
        <NoCasesAvailableContent hcdcInstLink={hcdcInstLink} />
      )}
      {eligibleCases.length > 0 && (
        <InvertedButton
          style={{ marginTop: '15px' }}
          onClick={() => dispatch(setEmailPetitionsModal(true))}
        >
          <Translate id="emailThisPageToMe" />
        </InvertedButton>
      )}
      <StyledHR style={{ margin: '20px 0px' }} />
      <Button onClick={() => history.push('/steps/payment')}>
        <Translate id="next" />
      </Button>
    </BeigeTile>
  );
};

// Petition instructions shown when there's just one eligible case
export const OneEligibleCaseContent = ({ eligibleCase, clickCase, formatForEmail }) => {
  return (
    <>
      <Text>
        <Translate id="oneEligCase" />
      </Text>
      <CaseWithSteps
        currCase={eligibleCase}
        clickCase={clickCase}
        formatForEmail={formatForEmail}
      />
    </>
  );
};

// Petition instructions for multiple cases, with prompts to move to the next case in each previous case's final step
export const MultipleEligibleCasesContent = ({ eligibleCases, clickCase, formatForEmail }) => {
  return (
    <>
      <Text>
        <Translate id="multiEligCase" />
      </Text>
      {eligibleCases.map((currCase, idx) => (
        <div key={`case${idx}`}>
          <Subheader>
            <Translate id="caseUp" /> {idx + 1}
          </Subheader>
          <CaseWithSteps
            currCase={currCase}
            clickCase={clickCase}
            formatForEmail={formatForEmail}
            lastStep={
              idx + 1 !== eligibleCases.length ? (
                <Translate id="petitionLast" data={{ nextIdx: idx + 2 }} />
              ) : null
            }
          />
          <StyledHR />
        </div>
      ))}
    </>
  );
};

// Use-case for this content is when a user is going through the steps without completing the eligibility checker
export const NoCasesAvailableContent = ({ hcdcInstLink }) => (
  <>
    <Text>
      <Translate id="noCases1" />{' '}
      <Link to="/check/pre-check">
        <Translate id="noCases2" />
      </Link>
      .
    </Text>
    <Text>
      <Translate id="noCases3" />{' '}
      <TextLink to={FRESH_START_LINK}>
        <Translate id="freshStart" />
      </TextLink>
      <Translate id="noCases4" />
    </Text>
    <Text>
      <Translate id="noCases5" />{' '}
      <TextLink to={TLH_NONDISCLOSURE_LINK}>
        <Translate id="noCases6" />
      </TextLink>
      .
    </Text>
    <Text>
      <Translate id="noCases7" />{' '}
      <TextLink to={hcdcInstLink}>
        <Translate id="here" />
      </TextLink>
      .
    </Text>
  </>
);

// Petition instructions for one case, displaying the case itself and steps to complete with links
const CaseWithSteps = ({ currCase, clickCase, lastStep, formatForEmail }) => {
  const dispatch = useDispatch();
  const formId = getRecommendedFormIdForCase(currCase);
  const hasTexasLegalServicesPage = TLS_LINKS_BY_FORM_ID[formId];
  // Show petition form for requirements section when the Texas Legal Services page doesn't exist
  const requirementsLink = hasTexasLegalServicesPage
    ? TLS_LINKS_BY_FORM_ID[formId]
    : PETITION_LINKS_BY_FORM_ID[formId];
  // Show BWI form when the case is marked with BWI flag
  const petitionLink = currCase.bwi
    ? PETITION_LINKS_BY_FORM_ID[BOATING_PETITION_ID]
    : PETITION_LINKS_BY_FORM_ID[formId];
  const guidedFormLink = TLS_GUIDED_FORM_LINKS_BY_FORM_ID[formId];
  const misdemeanorLevelAlertType = getMisdemeanorLevelAlertType(currCase, formId);
  const englishIsActive = useSelector(englishIsActiveSelector);
  const hcdcInstLink = `${SERVER_URL}/pdfs/HCDC_accessing_records_${
    englishIsActive ? 'en' : 'es'
  }.pdf`;

  if (!formId) {
    return null;
  }

  return (
    <>
      {formatForEmail ? (
        <LargeCaseCard caseInfo={currCase} />
      ) : (
        <CaseCard onClick={clickCase(currCase)} caseInfo={currCase} />
      )}
      <Text>
        <Translate id="petition1" data={{ formId }} />
      </Text>
      <SpacedList>
        <li>
          {hasTexasLegalServicesPage ? (
            <>
              <TextLink to={requirementsLink} style={{ textDecoration: 'underline' }}>
                <Translate id="petition2" />
              </TextLink>{' '}
              <Translate id="petition3" />
            </>
          ) : (
            <>
              <TextLink to={requirementsLink} style={{ textDecoration: 'underline' }}>
                <Translate id="petition4" />
              </TextLink>{' '}
              <Translate id="petition5" />
            </>
          )}{' '}
          <i>
            <Translate id="petition6" />{' '}
            <TextLink to={FRESH_START_LINK}>
              <Translate id="freshStart" />
            </TextLink>{' '}
            <Translate id="petition7" />
          </i>
          {misdemeanorLevelAlertType && (
            <MisdemeanorLevelAlert
              alertType={misdemeanorLevelAlertType}
              hcdcInstLink={hcdcInstLink}
            />
          )}
        </li>
        <li>
          <Translate id="petition8" />
          <SpacedUnorderedList>
            {hasTexasLegalServicesPage && (
              <li>
                <Translate id="petition9" />{' '}
                <TextLink to={petitionLink}>
                  <Translate id="petition10" />
                </TextLink>
                {` `}
                <Translate id="petition10_1" />{' '}
                <TextLink to={guidedFormLink}>
                  <Translate id="here" />
                </TextLink>{' '}
                <Translate id="petition10_2" />{' '}
                <LinkLikeButton
                  style={{ fontWeight: 400 }}
                  onClick={() => dispatch(setGuidedFormModal(true))}
                >
                  (<Translate id="petition10_3" />)
                </LinkLikeButton>
                . <Translate id="petition11" />
              </li>
            )}
            <li>
              <Translate id="petition12" />
              {` `}
              {formatForEmail ? (
                <Translate id="petition13" />
              ) : (
                <LinkLikeButton
                  style={{ fontWeight: 400, display: 'inline-block' }}
                  onClick={clickCase(currCase)}
                >
                  <Translate id="petition14" />
                </LinkLikeButton>
              )}
              {` `}
              <Translate id="petition15" />
            </li>
            <li>
              <Translate id="petition16" />{' '}
              <TextLink to={hcdcInstLink}>
                <Translate id="here" />
              </TextLink>
              .
            </li>
            <li>
              <Translate id="petition17" />
            </li>
          </SpacedUnorderedList>
        </li>
        {(!formatForEmail || lastStep) && <li>{lastStep || <Translate id="petition18" />}</li>}
      </SpacedList>
      <Text>
        <Translate id="petition19" />
      </Text>
      <HelpText />
    </>
  );
};
