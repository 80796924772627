import { SET_PAGE_BEFORE_STEPS } from "../actions/pageBeforeSteps";

const pageBeforeStepsReducer = (state = null, action) => {
  switch (action.type) {
    case SET_PAGE_BEFORE_STEPS:
      return action.payload;
    default:
      return state;
  }
};

export default pageBeforeStepsReducer;