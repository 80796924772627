import React from 'react';
import styled from 'styled-components';
import { DeepDiveHeader, DeepDiveWrapper } from './DeepDives';
import { NondisclosureFAQ, WebsiteFAQ } from './FAQ';
import { Translate } from 'react-localize-redux';

export const Text = styled.p`
  margin: 20px 0px;
`;

const Subtitle = styled.h3`
  margin-bottom: 20px;
  margin-top: 40px;
  font-size: 18px;
  font-weight: 700;
`;

export const FAQPage = () => {
  return (
    <DeepDiveWrapper>
      <DeepDiveHeader faq titleId="faqTitle" />
      <Subtitle>
        <Translate id="faqHead1" />
      </Subtitle>
      <NondisclosureFAQ />
      <Subtitle>
        <Translate id="faqHead2" />
      </Subtitle>
      <WebsiteFAQ />
    </DeepDiveWrapper>
  )
}
