import React from 'react';
import styled from 'styled-components';
import { BackArrowButton, Button, InvertedButton, TextLink, StyledHR } from '../general/Common';
import {  Header, Subheader, BeigeTile } from '../general/BeigeTile.js';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { allCasesSelector, eligibleCasesSelector } from '../../selectors/entities';
import { setEmailKnowYourRightsModal } from '../../actions/modals';
import { DPS_LINK } from '../../constants/links';
import { Translate } from 'react-localize-redux'

const SpacedUL = styled.ul`
  > li {
    margin: 15px 0px;
  }
`;

// Shared content for "Know Your Rights" app page and email page
export const KnowYourRightsContent = () => (
  <div>
    <Header style={{ marginBottom: '10px' }}><Translate id="knowYourRights" /></Header>
    <Subheader style={{ marginTop: '20px' }}><Translate id="navWithCriminalHistory" /> </Subheader>
    <SpacedUL>
      <li>
        <Translate id="kyrItem1" />
      </li>
      <li style={{ marginLeft: '40px' }}>
        <Translate id="kyrItem2" /> <TextLink style={{ textWrap: 'word-break', maxWidth: '100%'}} to={DPS_LINK}>{DPS_LINK}</TextLink>
      </li>
      <li>
        <Translate id="kyrItem3" /> 
      </li>
      <li>
        <Translate id="kyrItem4" /> 
      </li>
      <li>
        <Translate id="kyrItem5" /> 
      </li>
      <li>
        <Translate id="kyrItem6" /> 
      </li>
    </SpacedUL>
    <Subheader><Translate id="title7Header" /></Subheader>
    <SpacedUL>
      <li>
        <Translate id="kyrItem7" /> 
      </li>
      <li>
        <Translate id="kyrItem8" /> 
      </li>
      <li>
        <Translate id="kyrItem9" /> 
      </li>
      <li>
        <Translate id="kyrItem10" /> 
      </li>
      <li>
        <Translate id="kyrItem11" /> 
      </li>
      <li style={{ marginLeft: '40px' }}>
        <Translate id="kyrItem12" />  <TextLink to="tel:800-669-4000">800-669-4000</TextLink>
      </li>
      <li style={{ marginLeft: '40px' }}>
        <Translate id="kyrItem13" />  <TextLink to="tel:713-641-3247">713-641-3247</TextLink>
      </li>
    </SpacedUL>
  </div>
)

export const KnowYourRights = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const allCases = useSelector(allCasesSelector); 
  const eligibleCases = useSelector(eligibleCasesSelector); 
  return (
    <BeigeTile style={{ paddingBottom: '60px' }}>
      {allCases.length > 0 && <BackArrowButton onClick={() => history.goBack()} />}
      <KnowYourRightsContent />
      <InvertedButton onClick={() => dispatch(setEmailKnowYourRightsModal(true))}><Translate id="emailThisPageToMe"/></InvertedButton>
      <StyledHR />
      {
        eligibleCases.length > 0 
          ? <Button type="button" onClick={() => history.push('/check/results')}><Translate id="returnToResults" /> </Button>
          : <Button type="button" onClick={() => history.push({ pathname: '/', search: '?modal=survey'})}><Translate id="returnToHomepage" /></Button>
      }
    </BeigeTile>
  )
}