import React from 'react';
import styled from 'styled-components';
import { BG_PRIMARY, MAIN_CONTENT_WIDTH, MAX_DESKTOP_WIDTH } from '../../cssVars';

const SectionWrapper = styled.div`
  background-color: ${({ color }) => color || BG_PRIMARY};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const SectionContent = styled.div`
  display: grid;
  width: ${MAIN_CONTENT_WIDTH}px;
  width: min(${MAIN_CONTENT_WIDTH}px, 100%);
  ${({noGrid, templateColumns}) => noGrid ? `
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  ` : `
    grid-template-columns: ${templateColumns}; 
    column-gap: 10px;
  `}
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    width: 100%;
    width: min(${MAIN_CONTENT_WIDTH}px, 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: ${({mobilePadding}) => mobilePadding};
    > div {
      margin-bottom: ${({ splitMargin }) => splitMargin};
    }
  }  
`

// Desktop/mobile responsive wrapper div that has ample padding 
// and provides a nice, full-width toned backdrop for contents.
export const Section = ({ 
  color, 
  children, 
  templateColumns = '50% 50%', 
  margin = '0px', 
  padding = '50px 0px', 
  splitMargin = '10px',
  mobilePadding = '10px 20px',
  style,
  noGrid
}) => {
  return (
    <SectionWrapper color={color}>
      <SectionContent style={style} noGrid={noGrid} templateColumns={templateColumns} padding={padding} margin={margin} splitMargin={splitMargin} mobilePadding={mobilePadding}>
        {children}
      </SectionContent>
    </SectionWrapper>
  )
}