import React from 'react';
import styled from 'styled-components';
import { EligibleTile, ExpunctionEligibleTile, IneligibleTile } from './StatusTiles';
import { FullCaseContent } from './FullCaseContent';

const Wrapper = styled.div`
  background-color: #FEFEFD;
  border: 1px solid #969381;
  border-radius: 6px;
  color: #37352B;
  padding: 10px 20px;
  margin: 15px 0px;
`;

// Displays full case information on non-clickable card, used for email PDFs
export const LargeCaseCard = ({ caseInfo, style }) => {
  const { final_eligibility, hideStatus } = caseInfo;

  let statusTile = <EligibleTile />
  if (final_eligibility === 'ineligible') {
    statusTile = <IneligibleTile />
  } else if (final_eligibility === 'expunction') {
    statusTile = <ExpunctionEligibleTile />
  }

  return (
      <Wrapper style={style} hideStatus={hideStatus}>
        {!hideStatus && statusTile }
        <FullCaseContent caseInfo={caseInfo} />
      </Wrapper>
    )
}
