import { useSelector } from 'react-redux';
import { useRequest } from 'redux-query-react';
import { getLookupEndDateQuery } from '../actions/queries';
import { LOOKUP_START_DATE_EN, LOOKUP_START_DATE_ES } from '../constants/general';
import { lookupEndDateEnSelector, lookupEndDateEsSelector } from '../selectors/entities';
import { englishIsActiveSelector } from '../selectors/translations';

const useDates = () => {
  useRequest(getLookupEndDateQuery());
  const inEnglishMode = useSelector(englishIsActiveSelector);
  const lookupEndDateEn = useSelector(lookupEndDateEnSelector)
  const lookupEndDateEs = useSelector(lookupEndDateEsSelector)

  return {
    startDate: inEnglishMode ? LOOKUP_START_DATE_EN : LOOKUP_START_DATE_ES,
    endDate: inEnglishMode ? lookupEndDateEn : lookupEndDateEs
  }
}

export default useDates;