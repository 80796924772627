import React from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setHelpModal } from '../../actions/modals';
import { HELP_CLICK_INLINE } from '../../constants/analytics';
import { trackEvent } from '../../utils/googleAnalytics';
import { Text } from './BeigeTile';
import { LinkLikeButton, TextLink } from './Common';
import { HOTLINE_LINK } from '../../constants/links';

export const HelpText = ({ introTextId, addHotlineLink = false }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const handleButtonClick = () => {
    trackEvent(HELP_CLICK_INLINE, { help_accessed_on_page: location.pathname });
    dispatch(setHelpModal(true));
  };

  return (
    <Text style={{ fontStyle: 'italic', marginBottom: '30px' }}>
      <Translate id={introTextId || 'haveQuestions'} />
      {` `}
      <LinkLikeButton style={{ fontStyle: 'italic' }} onClick={handleButtonClick}>
        <Translate id="callOrSendMessage" />
      </LinkLikeButton>
      {` `}
      <Translate id="toBeaconLawRep" />
      {addHotlineLink && (
        <>
          {` `}
          <Translate id="hotlineText1" />
          {` `}
          <TextLink to={HOTLINE_LINK} style={{ fontWeight: 'bold' }}>
            <Translate id="hotlineLink" />
          </TextLink>
          {` `}
          <Translate id="hotlineText3" />
        </>
      )}
    </Text>
  );
};
