import { useSelector } from "react-redux";
import { useHandleUnauthorized } from "../../hooks/useHandleUnauthorized";
import { useInterval } from "../../hooks/useInterval";
import { tokenExpirationTimeSelector, tokenSelector } from "../../selectors/entities";

// Frequency to check token expiration time against current time
const TOKEN_CHECK_INTERVAL_MS = 1000;

/*  Check whether we've passed the current token's expiration time on a running interval.
    When the expiration time has passed, fully clear the state and redirect to the homepage.
*/
export default function TokenExpirationTimer() {
  const clearStore = useHandleUnauthorized(); 
  const jwtToken = useSelector(tokenSelector);
  const tokenExpirationTime = useSelector(tokenExpirationTimeSelector);

  useInterval(() => {
    if (tokenExpirationTime != null && jwtToken != null) {
      const currTimeInSeconds = Math.floor(Date.now() / 1000);
      if (currTimeInSeconds > tokenExpirationTime) {
        clearStore();
      }
    }
  }, TOKEN_CHECK_INTERVAL_MS)

  return null;
}