import { useEffect, useState } from "react";
import { MAX_DESKTOP_WIDTH } from "../cssVars";

// Returns whether current screen size is mobile based on MAX_DESKTOP_WIDTH, or desktopWidthPx if provided
export const useOnMobile = (desktopWidthPx) => {
  const [width, setWidth] = useState(window.innerWidth);
  const updateMedia = () => setWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);
  
  return width < (desktopWidthPx || MAX_DESKTOP_WIDTH);
}