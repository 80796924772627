export const monthOptionsEs = [
  {value: '', label:'Seleccione mes'},
  {value:'1', label:'Enero'},
  {value:'2', label:'Febrero'},
  {value:'3', label:'Marzo'},
  {value:'4', label:'Abril'},
  {value:'5', label:'Mayo'},
  {value:'6', label:'Junio'},
  {value:'7', label:'Julio'},
  {value:'8', label:'Agosto'},
  {value:'9', label:'Septiembre'},
  {value:'10', label:'Octubre'},
  {value:'11', label:'Noviembre'},
  {value:'12', label:'Diciembre'},
]

export const ageOptionsEs = [
  {value:"Under 18", label:"Menor de 18 años"},
  {value:"18-29 years", label:"18-29 years"},
  {value:"30-39 years", label:"30-39 years"},
  {value:"40-49 years", label:"40-49 years"},
  {value:"50-59 years", label:"50-59 years"},
  {value:"60-69 years", label:"60-69 years"},
  {value:"70 and over", label:"Mayor de 70 años"},
]

export const genderOptionsEs = [
  {value:'Male', label:'Masculino'},
  {value:'Female', label:'Femenino'},
  {value:'Transgender man/trans man/female-to-male (FTM)', label:'Hombre transgénero/hombre trans/mujer a hombre (FTM)'},
  {value:'Transgender woman/trans woman/male-to-female (MTF)', label:'Mujer transgénero/mujer trans/hombre a mujer (MTF)'},
  {value:'Non-Binary/Third Gender', label:'No binario/tercer género '},
]

export const raceOptionsEs = [
  {value:'Hispanic/Latinx', label: 'Hispano/latino'},
  {value:'Black or African American (non-Hispanic/Latinx)', label:'Negro o afroamericano (no hispano/latino)'},
  {value:'White (Non-Hispanic/Latinx)', label:'Blanco (no hispano/latino)'},
  {value: 'Asian or Pacific Islander', label: 'Asiático o isleño del Pacífico'},
  {value:'American Indian and/or Alaska Native', label:'Indio americano y/o nativo de Alaska'},
  {value:'Other race-ethnicity', label:'Otra raza/etnia'}
]

// Survey options

export const helpfulnessOptionsEs = [
  {value: "Very helpful", label:"Muy útil"},
  {value: "Moderately helpful", label:"Algo útil"},
  {value: "Slightly helpful", label:"Poco útil"},
  {value: "Not helpful", label:"Nada útil"},
]

export const didYouFindWhatYouNeededOptionsEs = [
  {value: 'Yes', label: 'Sí'},
  {value: 'Partially', label: 'En parte'},
  {value: "No", label: "No"},
]

export const howEasyWasInfoToFindOptionsEs = [
  {value: 'Very easy to find', label: 'Muy fácil de encontrar'},
  {value: 'Easy to find', label: 'Fácil de encontrar'},
  {value: 'Neither easy nor hard to find', label: 'Ni fácil ni difícil de encontrar'},
  {value: 'Hard to find', label: 'Difícil de encontrar'},
  {value: 'Very hard to find', label: 'Muy difícil de encontrar'},
  {value: "I didn't find what I was looking for", label: "No encontré lo que buscaba"},
]

export const whereDidYouAccessWebsiteOptionsEs = [
  {value: "At a friend or family member's house", label: "Desde la casa de un amigo o familiar"},
  {value: "At a self help center", label: "Desde un centro de autoayuda"},
  {value: "At home", label: "Desde mi casa"},
  {value: "At the library", label: "Desde una biblioteca"},
  {value: "At work", label: "Desde el trabajo"},
  {value: "On my phone", label: "Desde mi teléfono"},
  {value: "Other", label: "Otro"},
]

export const additionalInfoOptionsEs = [
  {value: "I am a victim and/or survivor of domestic violence", label: "Soy víctima y/o sobreviviente de la violencia doméstica"},
  {value: "I am a victim and/or survivor of another crime other than domestic violence", label: "Soy víctima y/o sobreviviente de otro crimen que no es violencia doméstica"},
  {value: "I am a veteran", label: "Soy veterano"},
  {value: "I am homeless or unstably housed", label: "No tengo hogar o no tengo una vivienda inestable"},
  {value: "I am a full time student", label: "Soy estudiante de tiempo completo"},
  {value: "I am using this website to help someone else", label: " Estoy usando este sitio web para ayudar a otra persona"},
  {value: "If I needed to, I don't think I could afford a lawyer", label: "No creo que pudiera pagar un abogado si lo necesitara"},
  {value: "I am unemployed or have been unemployed in the last year", label: "Estoy desempleado o he estado desempleado durante el último año"},
  {value: "None of the above", label: "Ninguna de las anteriores"},
]

export const didYouCompleteTheFormsOptionsEs = [
  {value:"Yes", label:"Sí"},
  {value:"No", label:"No"},
  {value:"Not yet, but I plan to", label:"Todavía no, pero pienso hacerlo"},
]

export const petitionFormOptionsEs = [
  {value:"411.072", label:"411.072"},
  {value:"411.0725", label:"411.0725"},
  {value:"411.0726", label:"411.0726"},
  {value:"411.0727", label:"411.0727"},
  {value:"411.0728", label:"411.0728"},
  {value:"411.0729", label:"411.0729"},
  {value:"411.03", label:"411.073"},
  {value:"411.031", label:"411.0731"},
  {value:"411.035", label:"411.0735"},
  {value:"411.036", label:"411.0736"},
  {value:"I filled out multiple", label:"Llené múltiples"},
]

export const timeToCompleteFormsOptionsEs = [
  {value:"Less than 30 minutes", label:"Menos de 30 minutos"},
  {value:"Between 30 minutes and 1 hour", label:"Entre 30 minutos y 1 hora"},
  {value:"1-2 hours", label:"1-2 horas"},
  {value:"2-4 hours", label:"2-4 horas"},
  {value:"Over 4 hours", label:"Más de 4 horas"},
]

export const howUserHeardAboutSiteOptionsEs = [
  {value:"Friend", label:"Por un amigo"},
  {value:"Social media", label:"Por las redes sociales"},
  {value:"The Beacon", label:"The Beacon"},
  {value:"Other", label:"Otro"},
]

export const educationLevelOptionsEs = [
  {value:"Less than a high school degree", label: "Inferior a un título de bachillerato"},
  {value:"High school degree or GED", label: "Título de bachillerato o GED"},
  {value:"Some college credit", label: "Algunos créditos universitariost"},
  {value:"Associate's degree", label: "Técnico profesional universitario"},
  {value:"Bachelor's degree", label: "Licenciatura"},
  {value:"Master's/professional/doctorate", label: "Máster/profesional/doctorado"},
  {value:"Prefer not to say", label: "Prefiero no decirlo"},
]

export const incomeRangeOptionsEs = [
  {value:"Less than $20,000", label: "Menos de $20,000"},
  {value:"$20,000 - $44,999", label: "$20,000 - $44,999"},
  {value:"$45,000 - $59,999", label: "$45,000 - $59,999",},
  {value:"$60,000 - $139,999", label: "$60,000 - $139,999"},
  {value:"$140,000 or more", label: "$140,000 o más"},
  {value:"Prefer not to say", label: "Prefiero no decirlo"},
]