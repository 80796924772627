import React from 'react';
import { useHistory } from 'react-router-dom';
import { SERVER_URL } from '../../constants/general';
import {
  EFILE_TYLERTECH_LINK,
  FELONY_CT_LINK,
  MISD_CT_LINK,
  MLH_COURT_TIPS,
  SPANISH_INTERPT_LINK,
  TLH_COURTROOM_TIPS_LINK,
  TLH_VIRTUAL_CT_LINK,
} from '../../constants/links';
import { BeigeTile, Header, Subheader, Text } from '../general/BeigeTile';
import { Button, TextLink } from '../general/Common';
import { TileBackArrow } from '../general/TileBackArrow';
import { Translate } from 'react-localize-redux';
import { longLabelByIdEn, longLabelByIdEs } from '../../constants/steps';
import { englishIsActiveSelector } from '../../selectors/translations';
import { useSelector } from 'react-redux';
import { HelpText } from '../general/HelpText';

export const Hearing = () => {
  const history = useHistory();
  const englishIsActive = useSelector(englishIsActiveSelector);
  const longLabelById = englishIsActive ? longLabelByIdEn : longLabelByIdEs;
  const hearingGuideLink = `${SERVER_URL}/pdfs/Pro_Se_Hearing_Guide_for_Nondisclosure_Hearings_${
    englishIsActive ? 'en' : 'es'
  }.pdf`;

  return (
    <BeigeTile>
      <Header>
        <TileBackArrow onClick={() => history.push('/steps/file')} />
        {longLabelById.hearing}
      </Header>
      <Subheader>
        <Translate id="misdFel" />
      </Subheader>
      <Text>
        <b>
          <Translate id="misdInst" />{' '}
          <TextLink to={MISD_CT_LINK}>
            <Translate id="misdInst2" />
          </TextLink>
        </b>
        {` `}
        <Translate id="misdInst3" /> <TextLink to={EFILE_TYLERTECH_LINK}>EfileTexas.gov</TextLink>
        {` `}
        <Translate id="misdInst4" />
      </Text>
      <Text id="felony-courts">
        <b>
          <Translate id="felInst" />{' '}
          <TextLink to={FELONY_CT_LINK}>
            <Translate id="felInst2" />
          </TextLink>
        </b>{' '}
        <Translate id="felInst3" />
        <TextLink to={FELONY_CT_LINK}>
          <Translate id="felInst4" />
        </TextLink>
        , <Translate id="felInst5" />
      </Text>
      <Text>
        <Translate id="felInst6" />
      </Text>
      <Text>
        <b>
          <Translate id="important" />
        </b>
        <Translate id="importantNote" />
      </Text>
      <Subheader>
        <Translate id="hearing" />
      </Subheader>
      <Text>
        <Translate id="hearing1" />
      </Text>
      <ul>
        <li>
          <TextLink to={TLH_COURTROOM_TIPS_LINK}>
            <Translate id="hearing2" />
          </TextLink>
        </li>
        <li>
          <TextLink to={TLH_VIRTUAL_CT_LINK}>
            <Translate id="hearing3" />
          </TextLink>{' '}
          (Texas Law Help)
        </li>
        <li>
          <TextLink to={MLH_COURT_TIPS}>
            <Translate id="hearing4" />
          </TextLink>{' '}
          (Michigan Legal Help)
        </li>
        <li>
          <TextLink to={SPANISH_INTERPT_LINK}>
            <Translate id="hearing5" />
          </TextLink>
        </li>
        <li>
          <TextLink to={hearingGuideLink}>
            <Translate id="hearing6" />
          </TextLink>
        </li>
      </ul>
      <HelpText />
      <Button style={{ marginTop: '30px' }} onClick={() => history.push('/steps/order')}>
        <Translate id="next" />
      </Button>
    </BeigeTile>
  );
};
