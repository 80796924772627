import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { setDemoInstructionsModal } from '../../actions/modals';
import { showDemoInstructionsSelector } from '../../selectors/modals';
import { Button, TextLink } from './Common';
import { Modal, ModalFooter, ModalHeader as Title } from './Modal';
import { doesNotMatch } from 'assert';
import checkMyEligBtnImg from '../../assets/checkEligibilityBtn.png'
import { BG_DARKER } from '../../cssVars';
import { Link } from 'react-router-dom';


const Text = styled.p`
  margin: 20px 0px;
`

const CaptionText = styled.div`
  font-size: 15px;
  font-style: italic;
  text-align: center;
  margin-top: 5px;
`

const ButtonImageWrapper = styled.div`
  padding-top: 5px;
  display: flex;
  justify-content: center;
  > img {
    width: 200px;
  }
`

const Scenario = styled.div`
  padding: 10px;
  margin: 0px -5px;
  background-color: ${BG_DARKER};
  border-radius: 5px;
  margin-bottom: 10px;
  > div:first-child {
    margin-bottom: 5px;
  }
`

export const DemoInstructionsModal = () => {
  
  const showModal = useSelector(showDemoInstructionsSelector);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setDemoInstructionsModal(false));
  }

  if (!showModal) {
    return null;
  }

  return (
    <Modal closeOnOuterClick={true} onClose={onClose} style={{ width: 'min(95%, 550px)' }}>
      <Title>Demo Overview</Title>
      <Text>
        <b>Welcome to the demo website for Clear Your Record Harris County!</b> You can explore any page or link you would on the actual ClearYourRecordHarrisCounty.org website. 
      </Text>
      <Text>
        We encourage you to click the "Check my eligibility" button on the <Link to="/">
          landing page
        </Link>. This will guide you through checking your Harris County records for nondisclosure eligible cases and filing for nondisclosure. 
      </Text>
      <ButtonImageWrapper>
        <img src={checkMyEligBtnImg} alt="" />
      </ButtonImageWrapper>
      <CaptionText>
        Image of the "Check my eligibility" button found on the landing page.
      </CaptionText>
      <Text>
        We have added mock data to our system so that you can step through this process and see the screens and questions an actual applicant would see. Below, we have outlined a few scenarios with mock data you can enter while checking your eligibility. Please note that this mock data was created to provide a sampling of scenarios a user may encounter; the eligibility outcomes displayed in the mock data may not accurately reflect eligibility determinations in the actual application.
      </Text>
      <Scenario>
        <div><b>Scenario 1: Eligible</b></div>
        <div>Name: Jessie Doe</div>
        <div>Date of Birth: January 1, 1990</div>
      </Scenario>
      <Scenario>
        <div><b>Scenario 2: Eligible Based on Follow Up Questions</b></div>
        <div>Name: Skylar Doe</div>
        <div>Date of Birth: January 1, 1990</div>
      </Scenario>
      <Scenario>
        <div><b>Scenario 3: Ineligible</b></div>
        <div>Name: Alex Doe</div>
        <div>Date of Birth: January 1, 1990</div>
      </Scenario>
      <Scenario style={{marginBottom: '0px'}}>
        <div><b>Scenario 4: Multiple Search Results Found</b></div>
        <div>Name: Taylor Doe</div>
        <div>Date of Birth: January 1, 1990</div>
      </Scenario>
      <Text>
        To revisit this information, click <b>Demo Instructions</b> in the menu.
      </Text>
      <ModalFooter>
        <Button onClick={onClose}>
          Got it!
        </Button>
      </ModalFooter>
    </Modal>
  )
}
