import React from 'react';
import styled from 'styled-components';
import { 
  FORM_ID_411_0735,
  FORM_ID_411_0736,
} from '../../constants/cases';
import { BG_DARKER, BG_PRIMARY, MAX_DESKTOP_WIDTH } from '../../cssVars';
import { Translate } from 'react-localize-redux'

const Wrapper = styled.div`
  > div: {
    border-top: ${({ hideBorderLines }) => hideBorderLines ? 'none' : '1px solid #EBEAE4'};
  }
  > div:first-child {
    border-radius: 4px 4px 0px 0px;
  }
  > div:nth-child(odd) {
    background-color: ${BG_PRIMARY};
  }
  > div:nth-child(even) {
    background-color: ${BG_DARKER};
  }
  > div:last-child {
    border-radius: 0px 0px 4px 4px;
  }
`

const LineItem = styled.div`
  display: flex;
  justify-content: space-between;
  > div:first-child {
    font-weight: 600;
  }
  padding: 8px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    display: block;
  } 
`

// The `waiting_period_start` field means different things depending on the case
const WaitingPeriodStartDate = ({caseInfo}) => {
  const { waiting_period_start, ndo_form, final_eligibility, final_pot_elig_411_0736 } = caseInfo;

  // Only show if waiting_period_start is nonnull & the case is eligible
  if (waiting_period_start == null || final_eligibility !== 'nondisclosure') {
    return null;
  }

  // Show as date of sentence completion for 411.0735 and 411.0736
  if (ndo_form === FORM_ID_411_0735 || (ndo_form === FORM_ID_411_0736 || final_pot_elig_411_0736)) {
    return (<LineItem>
      <div><Translate id="sentenceCompletionDate" /></div>
      <div>{waiting_period_start}</div>
    </LineItem>)
  }

  return null;
}

// Displays full case information
export const FullCaseContent = ({ caseInfo, hideBorderLines }) => {
  const { 
    casID, 
    current_offense_name, 
    current_charge_level,
    misdemeanor_felony,
    conviction_date,
    dismissal_date,
    sentence, 
    fine,
    full_name, 
    fda, 
    crt, 
    da_start,
    da_end,
    probation_start,
    probation_end,
  } = caseInfo;
  return (
      <Wrapper hideBorderLines={hideBorderLines}>
        <LineItem>
          <div><Translate id="causeNo" /></div>
          <div>{casID}</div>
        </LineItem>
        <LineItem>
          <div><Translate id="offenseName" /></div>
          <div>{current_offense_name}</div>
        </LineItem>
        <LineItem>
          <div><Translate id="chargeLevel" /></div>
          <div style={{ textTransform: 'capitalize' }}>
            {misdemeanor_felony ? `${misdemeanor_felony} ` : ''}{current_charge_level || ''}
          </div>
        </LineItem>
        <LineItem>
          <div><Translate id="defName" /></div>
          <div>{full_name}</div>
        </LineItem>
        {sentence && <LineItem>
          <div><Translate id="sentence" /></div>
          <div>{sentence}</div>
        </LineItem>}
        {fine && <LineItem>
          <div><Translate id="fine" /></div>
          <div>{fine}</div>
        </LineItem>}
        <LineItem>
          <div><Translate id="filingDate" /></div>
          <div>{fda}</div>
        </LineItem>
        {conviction_date && <LineItem>
          <div><Translate id="convDate" /></div>
          <div>{conviction_date}</div>
        </LineItem>}
        {dismissal_date && <LineItem>
          <div><Translate id="dismissalDate" /></div>
          <div>{dismissal_date}</div>
        </LineItem>}
        {da_start && <LineItem>
          <div><Translate id="startOfDA" /></div>
          <div>{da_start}</div>
        </LineItem>}
        {da_end && <LineItem>
          <div><Translate id="endOfDA" /></div>
          <div>{da_end}</div>
        </LineItem>}
        {probation_start && <LineItem>
          <div><Translate id="probationStart" /></div>
          <div>{probation_start}</div>
        </LineItem>}
        {probation_end && <LineItem>
          <div><Translate id="probationEnd" /></div>
          <div>{probation_end}</div>
        </LineItem>}
        <WaitingPeriodStartDate caseInfo={caseInfo} />
        <LineItem style={{marginBottom: '15px'}}>
          <div><Translate id="courtNum" /></div>
          <div>{crt}</div>
        </LineItem>
      </Wrapper>
    )
}
