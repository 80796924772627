import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { setDisclaimerModal } from '../../actions/modals';
import { FRESH_START_LINK, HCDC_LINK } from '../../constants/links';
import { showDisclaimerSelector } from '../../selectors/modals';
import { TextLink } from './Common';
import { Modal, ModalHeader as Title } from './Modal';
import { Translate } from 'react-localize-redux'

const Text = styled.p`
  margin: 20px 0px;
`

// General disclaimer for the website
export const DisclaimerModal = () => {

  const showDisclaimer = useSelector(showDisclaimerSelector);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setDisclaimerModal(false));
  }

  if (!showDisclaimer) {
    return null;
  }

  return (
    <Modal closeOnOuterClick={true} onClose={onClose} style={{ width: 'min(95%, 550px)' }}>
      <Title><Translate id="footer2_3" /></Title>
      <Text>
        <Translate id="discBlurb1" />
      </Text>
      <Text>
        <Translate id="discBlurb2" /> <TextLink to={FRESH_START_LINK}><Translate id="freshStart" /></TextLink><Translate id="discBlurb2_5" />
      </Text>
      <Text>
        <Translate id="discBlurb3" /> <TextLink to={HCDC_LINK}><Translate id="hcdcOffice" /></TextLink>.
      </Text>
    </Modal>
  )
}