import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Landing } from './Landing';
import Menu from '../general/Menu.js';
import { DeepDives } from './DeepDives';
import { Footer } from './Footer';
import LanguageToggle from '../general/LanguageToggle';
import { DemoInstructionsModal } from '../general/DemoInstructionsModal';
import { HelpButton } from '../general/HelpButton';

export const InformationalRoot = () => {
  return (
    <>
      <DemoInstructionsModal />
      <Menu />
      <Switch>
        <Route path="/info" component={DeepDives} />
        <Route path="/" component={Landing} />
      </Switch>
      <LanguageToggle onInfoPages={true} />
      <HelpButton onInfoPages={true} />
      <Footer />
    </>
  )
}
