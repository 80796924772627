import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import {
  OptionsList,
  SelectField,
  TextField,
  MultiFieldLine,
  CheckboxField,
  MDYField,
} from '../general/FormFields.js';
import { Button, LinkLikeButton } from '../general/Common.js';
import { validateInitialForm } from '../../utils/validators.js';
import { useBasicInfo } from '../../hooks/editors.js';
import { BeigeTile, Header, Subheader } from '../general/BeigeTile.js';
import { MAX_DESKTOP_WIDTH } from '../../cssVars.js';
import { useHistory } from 'react-router-dom';
import { TileBackArrow } from '../general/TileBackArrow.js';
import { useDispatch } from 'react-redux';
import { setDisclaimerModal } from '../../actions/modals.js';
import { NameArrayEditor } from '../general/OtherNamesFields.js';
import { Translate } from 'react-localize-redux';
import { useFormOptions } from '../../hooks/useFormOptions.js';
import { trackEvent } from '../../utils/googleAnalytics.js';

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 20px;
  & > div:second-child {
    font-size: 18px;
    font-style: italic;
    margin-right: 10px;
  }
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    > div {
      margin: 10px 0px;
      max-width: 400px;
    }
    > div:last-child {
      align-self: stretch;
    }
  }
`;

const Text = styled.p`
  margin: 10px 0px;
`;

// Groups first, middle, last name, & suffix into one form line (on desktop, breaks into 4 lines on mobile)
const NameField = ({ namePrefix, onDelete, getError, noRequired }) => {
  const { suffixOptions } = useFormOptions();
  const getName = name => (namePrefix ? `${namePrefix}${name}` : name);
  return (
    <MultiFieldLine columns={`25% 25% 25% auto ${onDelete ? '30px' : ''}`}>
      <TextField
        name={getName('firstName')}
        labelId="firstName"
        field={{ required: !noRequired }}
        error={getError('firstName')}
      />
      <TextField
        name={getName('middleName')}
        labelId="middleName"
        field={{ required: false }}
        error={getError('middleName')}
      />
      <TextField
        name={getName('lastName')}
        labelId="lastName"
        field={{ required: !noRequired }}
        error={getError('lastName')}
      />
      <SelectField
        name={getName('suffix')}
        labelId="suffix"
        field={{ required: false }}
        error={getError('suffix')}
      >
        <OptionsList options={suffixOptions} name={getName('suffix')} hideSelectOne={true} />
      </SelectField>
      {onDelete && (
        <button
          type="button"
          style={{ height: '30px', position: 'relative', top: '40px' }}
          onClick={onDelete}
        >
          -
        </button>
      )}
    </MultiFieldLine>
  );
};

// Second form users sees in the eligibility checker flow, asks for name(s) and DOB
export const BasicInfo = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const validate = validateInitialForm;
  const [submit, submitting] = useBasicInfo(casesFound => {
    if (casesFound) {
      // show "confirm cases" if user has cases found
      history.push('/check/confirm-cases');
    } else {
      // jump to results if user has no cases
      history.push('/check/results');
    }
  });

  const submitClick = values => {
    trackEvent('Form Submit Click'); // Beacon Law requested event
    // Send a tracking event for BSA when user clicks "submit"
    // This will skip if not in the production environment ("window.fbq" will not exist)
    if (window && window.fbq) {
      window.fbq('trackCustom', 'EligibilityFormSubmit');
    }
    submit(values);
  };

  return (
    <Formik
      validate={validate}
      initialValues={{
        firstName: '',
        middleName: '',
        lastName: '',
        suffix: '',
        agreeToTerms: false,
        dob: null,
        aliasFirstNames: [],
        aliasLastNames: [],
      }}
      enableReinitialize={true}
      onSubmit={submitClick}
    >
      {({ handleSubmit, errors, touched, values, submitCount }) => {
        // return error if field is touched
        const getError = name => (touched[name] || submitCount >= 1) && errors[name];
        return (
          <>
            <BeigeTile>
              <Header>
                <TileBackArrow onClick={() => history.push('/check/pre-check')} />
                <Translate id="basicInfo" />
              </Header>

              <Text>
                <Translate id="basicInfoIntro1" />
              </Text>

              <Text>
                <Translate id="basicInfoIntro2" />
              </Text>

              <Subheader>
                <Translate id="legalName" />
              </Subheader>
              <NameField getError={getError} />

              <Subheader>
                <Translate id="otherNames" />{' '}
              </Subheader>
              <Text>
                <Translate id="otherNamesNote" />
              </Text>

              <NameArrayEditor nameType="first" values={values} fieldName="aliasFirstNames" />
              <NameArrayEditor nameType="last" values={values} fieldName="aliasLastNames" />

              <Subheader>
                <Translate id="dob" />
              </Subheader>
              <MDYField name="dob" field={{ required: true }} error={getError('dob')} />

              <CheckboxField
                name="agreeToTerms"
                error={getError('agreeToTerms')}
                wrapperStyle={`align-items: flex-start; margin-top: 30px;`}
              >
                <label htmlFor="agreeToTerms" style={{ paddingTop: '3px' }}>
                  <Translate id="shortDisclaimer" />{' '}
                  <LinkLikeButton onClick={() => dispatch(setDisclaimerModal(true))}>
                    <Translate id="learnMore" />
                  </LinkLikeButton>
                </label>
              </CheckboxField>

              <Footer>
                <div>
                  * <Translate id="fieldsAreMandatory" />{' '}
                </div>
                <Button
                  id="check_eligibility_form_submit"
                  fillOnMobile={true}
                  type="button"
                  isLoading={submitting}
                  onClick={handleSubmit}
                >
                  <Translate id="submit" />
                </Button>
              </Footer>
            </BeigeTile>
          </>
        );
      }}
    </Formik>
  );
};
