import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { setAboutUsModal } from '../../actions/modals';
import { BEACON_LINK, TATJ_LINK, TLSC_LINK } from '../../constants/links';
import { showAboutUsSelector } from '../../selectors/modals';
import { TextLink } from './Common';
import { Modal, ModalHeader as Title } from './Modal';
import { Translate } from 'react-localize-redux'

const Text = styled.p`
  margin: 20px 0px;
`

export const AboutUsModal = () => {

  const showModal = useSelector(showAboutUsSelector);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setAboutUsModal(false));
  }

  if (!showModal) {
    return null;
  }

  return (
    <Modal closeOnOuterClick={true} onClose={onClose} style={{ width: 'min(95%, 550px)' }}>
      <Title><Translate id="aboutUs" /></Title>
      <Text>
        <Translate id="aboutUsBlurb1" />
      </Text>
      <Text>
        <Translate id="siteWasCreatedBy" /> <TextLink to={BEACON_LINK}>The Beacon</TextLink>,{` `}
        <Translate id="aboutUsBlurb2" /> <TextLink to={TLSC_LINK}>Texas Legal Services Center</TextLink>.{` `}
        <Translate id="aboutUsBlurb3" /> <TextLink to={TATJ_LINK}>Texas Access to Justice Foundation</TextLink>.
      </Text>
    </Modal>
  )
}