// IMPORTANT: IF ADDING A LINK, PLEASE ADD IT TO THE 'links_test.js' FILE!

export const FRESH_START_LINK = 'https://georgetown.neotalogic.com/a/TexasFreshStartV2';
export const DPS_LINK = 'https://publicsite.dps.texas.gov/DpsWebsite/CriminalHistory/';
export const CLEAN_SLATE_TX_LINK = 'https://www.cleanslatetexas.org/';
export const TLH_MAIN_LINK = 'https://texaslawhelp.org';
export const TLH_ELIGIBILITY_REQS_LINK =
  'https://texaslawhelp.org/article/what-nondisclosure-order-and-how-do-i-get-one#shared-basic-eligibility-requirements-for-all-nondisclosure-orders';
export const TLH_NDO_TYPES_LINK =
  'https://texaslawhelp.org/article/what-nondisclosure-order-and-how-do-i-get-one#types-of-nondisclosure-orders-available';
export const TLH_EXPUNCTION_LINK =
  'https://texaslawhelp.org/resources/expunction-forms-clear-arrest-record';
export const TLH_NDO_FORMS_LINK = 'https://texaslawhelp.org/resources/nondisclosure-forms';
export const TLH_NONDISCLOSURE_LINK =
  'https://texaslawhelp.org/article/what-is-a-nondisclosure-order-and-how-do-i-get-one';
export const TLH_COURTROOM_TIPS_LINK = 'https://texaslawhelp.org/article/tips-courtroom';
export const TLH_DOCS_NEEDED_LINK =
  'https://texaslawhelp.org/article/what-nondisclosure-order-and-how-do-i-get-one#documents-that-you-may-need-so-you-can-ask-for-a-nondisclosure-order';
export const TLH_AFTER_ORDER_LINK =
  'https://texaslawhelp.org/article/what-is-a-nondisclosure-order-and-how-do-i-get-one#what-happens-after-the-court-grants-me-a-nondisclosure-order';
export const TLH_VIRTUAL_CT_LINK = 'https://texaslawhelp.org/article/virtual-court';
export const TLH_INABILITY_TO_PAY_LINK =
  'https://texaslawhelp.org/form/statement-inability-afford-payment-court-costs-or-appeal-bond';
export const MLH_COURT_TIPS = 'https://michiganlegalhelp.org/videos';
export const SPANISH_INTERPT_LINK = 'https://www.txcourts.gov/tcris/';
export const BEACON_LINK = 'https://www.beaconhomeless.org';
export const TLSC_LINK = 'https://www.tlsc.org/';
export const TATJ_LINK = 'https://www.teajf.org/';
export const HCDC_LINK = 'https://www.hcdistrictclerk.com/Common/Default.aspx';
export const HCDC_SEARCH_LINK =
  'https://www.hcdistrictclerk.com/eDocs/Public/Search.aspx?BGCheckTab=1&amp;Tab=tabBkgrndChk';
export const ACROBAT_LINK = 'https://www.acrobat.com';
export const HVL_LINK = 'https://makejusticehappen.org';
export const LEGAL_LINE_LINK = 'https://www.hba.org/?pg=LegalLine';
export const FELONY_CT_LINK = 'https://www.justex.net/courts/criminal/criminalcourts.aspx';
export const MISD_CT_LINK = 'https://www.ccl.hctx.net/criminal/';
export const EFILE_TYLERTECH_LINK = 'https://efiletx.tylertech.cloud/OfsEfsp/ui/landing';
export const JA_LINK = 'https://www.januaryadvisors.com/';
export const HOTLINE_LINK =
  'https://calendly.com/egarcia-cyrhc/clearyourrecordharriscounty-oneonone';

export const TLH_411_072_INFO_LINK =
  'https://texaslawhelp.org/guide/nondisclosure-order-prep-guide/?tab=0';
export const TLH_411_0725_INFO_LINK =
  'https://texaslawhelp.org/guide/nondisclosure-order-prep-guide/?tab=0';
export const TLH_411_0726_INFO_LINK =
  'https://texaslawhelp.org/guide/nondisclosure-order-prep-guide/?tab=0';
export const TLH_411_0728_INFO_LINK =
  'https://texaslawhelp.org/guide/nondisclosure-order-prep-guide/?tab=0';
export const TLH_411_0729_INFO_LINK =
  'https://texaslawhelp.org/guide/nondisclosure-order-prep-guide/?tab=0';
export const TLH_411_073_INFO_LINK =
  'https://texaslawhelp.org/guide/nondisclosure-order-prep-guide/?tab=0';
export const TLH_411_0731_INFO_LINK =
  'https://texaslawhelp.org/guide/nondisclosure-order-prep-guide/?tab=0';
export const TLH_411_0735_INFO_LINK =
  'https://texaslawhelp.org/guide/nondisclosure-order-prep-guide/?tab=0';
export const TLH_411_0736_INFO_LINK =
  'https://texaslawhelp.org/guide/nondisclosure-order-prep-guide/?tab=0';

export const TLH_411_072_GUIDED_FORM_LINK =
  'https://texas.tylertech.cloud/srl/srl/Start?legalProcessKey=Nondisclosure_Order_411072';
export const TLH_411_0725_GUIDED_FORM_LINK =
  'https://texas.tylertech.cloud/srl/srl/Start?legalProcessKey=Nondisclosure_Order_4110725';
export const TLH_411_0726_GUIDED_FORM_LINK =
  'https://texas.tylertech.cloud/srl/srl/Start?legalProcessKey=Nondisclosure_Order_4110726';
export const TLH_411_0727_GUIDED_FORM_LINK =
  'https://texas.tylertech.cloud/srl/srl/Start?legalProcessKey=Nondisclosure_Order_4110727';
export const TLH_411_0728_GUIDED_FORM_LINK =
  'https://texas.tylertech.cloud/srl/srl/Start?legalProcessKey=Nondisclosure_Order_4110728';
export const TLH_411_0729_GUIDED_FORM_LINK =
  'https://texas.tylertech.cloud/srl/srl/Start?legalProcessKey=Nondisclosure_Order_4110729';
export const TLH_411_073_GUIDED_FORM_LINK =
  'https://texas.tylertech.cloud/srl/srl/Start?legalProcessKey=Nondisclosure_Order_411073';
export const TLH_411_0731_GUIDED_FORM_LINK =
  'https://texas.tylertech.cloud/srl/srl/Start?legalProcessKey=Nondisclosure_Order_4110731';
export const TLH_411_0735_GUIDED_FORM_LINK =
  'https://texas.tylertech.cloud/srl/srl/Start?legalProcessKey=Nondisclosure_Order_4110735';
export const TLH_411_0736_GUIDED_FORM_LINK =
  'https://texas.tylertech.cloud/srl/srl/Start?legalProcessKey=Nondisclosure_Order_4110736';

export const TLH_411_072_PETITION_LINK =
  'https://texaslawhelp.org/sites/default/files/2021-04/section-411-072-2020_combined_jan_2020-fillable_3_2021.pdf';
export const TLH_411_0725_PETITION_LINK =
  'https://texaslawhelp.org/sites/default/files/2021-04/section-411-0725-final_combined_jan2020-fillable_3_2021_0.pdf';
export const TLH_411_0726_PETITION_LINK =
  'https://texaslawhelp.org/sites/default/files/2021-04/section-4110726-2020_combineddwi_jan2020-fillable_4_2021_0.pdf';
export const TLH_411_0727_PETITION_LINK =
  'https://texaslawhelp.org/sites/default/files/2021-04/section-4110727_combined_jan2020-fillable_4_2021.pdf';
export const TLH_411_0728_PETITION_LINK =
  'https://texaslawhelp.org/sites/default/files/2021-05/section-411-0728-2020_combined_jan2020-fillable_3_2021.pdf';
export const TLH_411_0729_PETITION_LINK =
  'https://texaslawhelp.org/sites/default/files/2021-04/section-4110729_combined_jan2020-fillable_4_2021.pdf';
export const TLH_411_073_PETITION_LINK =
  'https://texaslawhelp.org/sites/default/files/2021-04/section-411-073-2017_combined_jan2020-fillable_3_2021.pdf';
export const TLH_411_0731_PETITION_LINK =
  'https://texaslawhelp.org/sites/default/files/2021-04/section-4110731_combined_jan2020-fillable_4_2021.pdf';
export const TLH_411_0735_PETITION_LINK =
  'https://texaslawhelp.org/sites/default/files/2021-08/section-411-0735-2017_combined_jan2020-fillable_3_2021_t.pdf';
export const TLH_411_0736_PETITION_LINK =
  'https://texaslawhelp.org/sites/default/files/2021-04/section-411-0736_combined_jan2020-fillable_3_2021.pdf';
export const TLH_BOATING_PETITION_LINK =
  'https://texaslawhelp.org/sites/default/files/2021-04/section-4110726-2020_combinedbwi_jan2020-fillable_3_2021.pdf';
// IMPORTANT: IF ADDING A LINK, PLEASE ADD IT TO THE 'links_test.js' FILE!
