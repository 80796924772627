import { useDispatch, useSelector } from 'react-redux';
import { mutateAsync } from 'redux-query';
import { toast } from 'react-toastify';
import { useState } from 'react';
import {
  preCheckQuery,
  checkBasicInfoQuery,
  caseFollowupQuery,
  additionalInfoQuery,
  confirmCasesQuery,
  feedbackSurveyQuery,
  sendCaseResultsEmailQuery,
  sendPetitionsEmailQuery,
  sendKnowYourRightsQuery,
  helpFormQuery,
} from '../actions/queries';
import { statusIsGood, statusIsUnauthorized } from '../utils/helpers';
import { useHistory } from 'react-router-dom';
import { englishIsActiveSelector } from '../selectors/translations';
import { getEligibleCases, tokenSelector } from '../selectors/entities';
import { getFormsNeededForFollowUp } from '../utils/cases';
import { useHandleUnauthorized } from './useHandleUnauthorized';
import enTranslations from '../translations/en.json';
import esTranslations from '../translations/es.json';

// Returns common vars/functions used across editors
const useEditorBasics = () => {
  const dispatch = useDispatch();
  const jwtToken = useSelector(tokenSelector);
  const handleUnauthorized = useHandleUnauthorized();
  return [dispatch, jwtToken, handleUnauthorized];
};

export const usePreCheck = onSuccess => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch, jwtToken] = useEditorBasics();
  const englishIsActive = useSelector(englishIsActiveSelector);

  const submit = values => {
    setSubmitting(true);
    dispatch(mutateAsync(preCheckQuery(values, jwtToken))).then(({ status, body }) => {
      if (statusIsGood(status) && body) {
        setSubmitting(false);
        onSuccess();
      } else {
        setSubmitting(false);
        toast.error(englishIsActive ? enTranslations.generalError : esTranslations.generalError);
      }
    });
  };
  return [submit, submitting];
};

export const useBasicInfo = onSuccess => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch, jwtToken, handleUnauthorized] = useEditorBasics();
  const englishIsActive = useSelector(englishIsActiveSelector);

  const submit = values => {
    setSubmitting(true);

    // remove any blank ("") names from the aliases arrays
    const filteredValues = {
      ...values,
      aliasFirstNames: values.aliasFirstNames.filter(name => name),
      aliasLastNames: values.aliasLastNames.filter(name => name),
    };

    dispatch(mutateAsync(checkBasicInfoQuery(filteredValues, jwtToken))).then(
      ({ status, body }) => {
        if (statusIsGood(status) && body) {
          setSubmitting(false);
          const cases = body.eligibilityInfo ? body.eligibilityInfo.cases : null;
          const casesFound = Array.isArray(cases) && cases.length > 0;
          onSuccess(casesFound);
        } else if (statusIsUnauthorized(status)) {
          handleUnauthorized();
        } else {
          setSubmitting(false);
          if (body && body.nameError) {
            toast.error(englishIsActive ? enTranslations.nameError : esTranslations.nameError);
          } else {
            toast.error(
              englishIsActive ? enTranslations.generalError : esTranslations.generalError
            );
          }
        }
      }
    );
  };
  return [submit, submitting];
};

export const useConfirmCases = () => {
  const history = useHistory();
  const [dispatch, jwtToken, handleUnauthorized] = useEditorBasics();
  const englishIsActive = useSelector(englishIsActiveSelector);

  const submit = spn => {
    dispatch(mutateAsync(confirmCasesQuery(spn, jwtToken))).then(({ status, body }) => {
      if (statusIsGood(status) && body) {
        const cases = (body.eligibilityInfo || {}).cases || [];
        const hasEligibleCases = getEligibleCases(cases).length > 0;
        const hasCasesThatNeedFollowup = cases.some(
          currCase => getFormsNeededForFollowUp(currCase).length > 0
        );
        if (hasEligibleCases || hasCasesThatNeedFollowup) {
          // continue through flow if user has eligible cases or ineligible cases that need followup
          history.push('/check/additional-info');
        } else {
          // jump to results if user has no eligible cases
          history.push('/check/results');
        }
      } else if (statusIsUnauthorized(status)) {
        handleUnauthorized();
      } else {
        toast.error(englishIsActive ? enTranslations.generalError : esTranslations.generalError);
      }
    });
  };
  return [submit];
};

// Returns disqualifying offenses, sex offender, veteran, & human trafficking victim question answers
export const useAdditionalInfo = onSuccess => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch, jwtToken, handleUnauthorized] = useEditorBasics();
  const englishIsActive = useSelector(englishIsActiveSelector);

  const submit = values => {
    setSubmitting(true);
    dispatch(mutateAsync(additionalInfoQuery(values, jwtToken))).then(({ status, body }) => {
      if (statusIsGood(status) && body) {
        setSubmitting(false);
        onSuccess();
      } else if (statusIsUnauthorized(status)) {
        handleUnauthorized();
      } else {
        setSubmitting(false);
        toast.error(englishIsActive ? enTranslations.generalError : esTranslations.generalError);
      }
    });
  };
  return [submit, submitting];
};

// Return followup questions for a specific case
export const useCaseFollowupQuestions = (onSuccess, currCase) => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch, jwtToken, handleUnauthorized] = useEditorBasics();
  const englishIsActive = useSelector(englishIsActiveSelector);

  const submit = values => {
    setSubmitting(true);
    // Return form values and the case object's mongodb ID
    const formValuesWithId = {
      ...values,
      _id: currCase._id,
    };
    dispatch(mutateAsync(caseFollowupQuery(formValuesWithId, jwtToken))).then(
      ({ status, body }) => {
        if (statusIsGood(status) && body) {
          setSubmitting(false);
          onSuccess();
        } else if (statusIsUnauthorized(status)) {
          handleUnauthorized();
        } else {
          setSubmitting(false);
          toast.error(englishIsActive ? enTranslations.generalError : esTranslations.generalError);
        }
      }
    );
  };
  return [submit, submitting];
};

// Submit feedback survey results
export const useFeedbackSurvey = onSuccess => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch, jwtToken, handleUnauthorized] = useEditorBasics();
  const englishIsActive = useSelector(englishIsActiveSelector);

  const submit = values => {
    setSubmitting(true);
    dispatch(mutateAsync(feedbackSurveyQuery(values, jwtToken))).then(({ status, body }) => {
      if (statusIsGood(status) && body) {
        setSubmitting(false);
        toast.success(
          englishIsActive
            ? enTranslations.feedbackSubmittedMsg
            : esTranslations.feedbackSubmittedMsg
        );
        onSuccess();
      } else if (statusIsUnauthorized(status)) {
        handleUnauthorized();
      } else {
        setSubmitting(false);
        toast.error(englishIsActive ? enTranslations.generalError : esTranslations.generalError);
      }
    });
  };
  return [submit, submitting];
};

// Submit help form response
export const useHelpForm = onSuccess => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch, jwtToken, handleUnauthorized] = useEditorBasics();
  const englishIsActive = useSelector(englishIsActiveSelector);

  const submit = values => {
    setSubmitting(true);
    dispatch(mutateAsync(helpFormQuery(values, jwtToken))).then(({ status, body }) => {
      if (statusIsGood(status) && body) {
        setSubmitting(false);
        toast.success(
          englishIsActive
            ? enTranslations.feedbackSubmittedMsg
            : esTranslations.feedbackSubmittedMsg
        );
        onSuccess();
      } else if (statusIsUnauthorized(status)) {
        handleUnauthorized();
      } else {
        setSubmitting(false);
        toast.error(englishIsActive ? enTranslations.generalError : esTranslations.generalError);
      }
    });
  };
  return [submit, submitting];
};

// Email case results to user
export const useSendCaseResultsEmail = onSuccess => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch, jwtToken, handleUnauthorized] = useEditorBasics();
  const englishIsActive = useSelector(englishIsActiveSelector);

  const submit = ({ email }) => {
    setSubmitting(true);
    dispatch(mutateAsync(sendCaseResultsEmailQuery(email, !englishIsActive, jwtToken))).then(
      ({ status, body }) => {
        if (statusIsGood(status) && body) {
          setSubmitting(false);
          toast.success(
            `${
              englishIsActive ? enTranslations.emailSentMsg : esTranslations.emailSentMsg
            } ${email}.`
          );
          onSuccess();
        } else if (statusIsUnauthorized(status)) {
          handleUnauthorized();
        } else {
          setSubmitting(false);
          toast.error(englishIsActive ? enTranslations.generalError : esTranslations.generalError);
        }
      }
    );
  };
  return [submit, submitting];
};

// Email petitions and case info to user
export const useSendPetitionsEmail = onSuccess => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch, jwtToken, handleUnauthorized] = useEditorBasics();
  const englishIsActive = useSelector(englishIsActiveSelector);

  const submit = ({ email }) => {
    setSubmitting(true);
    dispatch(mutateAsync(sendPetitionsEmailQuery(email, !englishIsActive, jwtToken))).then(
      ({ status, body }) => {
        if (statusIsGood(status) && body) {
          setSubmitting(false);
          toast.success(
            `${
              englishIsActive ? enTranslations.emailSentMsg : esTranslations.emailSentMsg
            } ${email}.`
          );
          onSuccess();
        } else if (statusIsUnauthorized(status)) {
          handleUnauthorized();
        } else {
          setSubmitting(false);
          toast.error(englishIsActive ? enTranslations.generalError : esTranslations.generalError);
        }
      }
    );
  };
  return [submit, submitting];
};

// Email Know Your Rights information to user
export const useSendKnowYourRightsEmail = onSuccess => {
  const [submitting, setSubmitting] = useState(false);
  const [dispatch, jwtToken, handleUnauthorized] = useEditorBasics();
  const englishIsActive = useSelector(englishIsActiveSelector);

  const submit = ({ email }) => {
    setSubmitting(true);
    dispatch(mutateAsync(sendKnowYourRightsQuery(email, !englishIsActive, jwtToken))).then(
      ({ status, body }) => {
        if (statusIsGood(status) && body) {
          setSubmitting(false);
          toast.success(
            `${
              englishIsActive ? enTranslations.emailSentMsg : esTranslations.emailSentMsg
            } ${email}.`
          );
          onSuccess();
        } else if (statusIsUnauthorized(status)) {
          handleUnauthorized();
        } else {
          setSubmitting(false);
          toast.error(englishIsActive ? enTranslations.generalError : esTranslations.generalError);
        }
      }
    );
  };
  return [submit, submitting];
};
