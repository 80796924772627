import { monthOptionsEs } from "../constants/formOptionsEs";
import { monthOptionsEn } from "../constants/formOptionsEn";
import { MAX_DESKTOP_WIDTH } from "../cssVars";

// Status code helpers
export const statusIsGood = (status) => status >= 200 && status < 300;
export const statusIsUnauthorized = (status) => status === 401;

// Date helpers
// get date in YYYY-MM-DD format
export const getYearMonthDayDate = (date) => {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

const monthsWithThirtyDays = ['4', '6', '9', '11'];

// Given month & year, return array of day options
export const getDayOptions = (month, year, englishIsActive) => {
  let days = 31;
  if (monthsWithThirtyDays.includes(month)) {
    days = 30;
  } else if (month === '2') {
    const isLeapYear = year ? year % 4 === 0 : true;
    days = isLeapYear ? 29 : 28;
  }
  const options = Array.from({length: days}, (item, index) => {
    const day = (index + 1).toString();
    return { value: day, label: day };
  });
  return [{ value: '', label: englishIsActive ? 'Select day' : 'Seleccione día' }, ...options];
}

// Given an optional range, return array of year options starting from the given year (or current year if undefined)
export const getYearOptions = (range, endYear, offset, englishIsActive) => {
  const currYear = endYear || new Date().getFullYear();
  const options = Array.from({length: (range || 90)}, (item, index) => {
    const year = currYear - index - (offset != null ? offset : 17);
    return { value: year, label: year };
  });
  return [{ value: '', label: englishIsActive ? 'Select year' : 'Seleccione año' }, ...options];
}

// For IE
export const getMonthFromIndex = (index, useSpanish) => {
  const stringIndex = (index + 1).toString();
  return useSpanish 
    ? monthOptionsEs.find(({ value }) => value === stringIndex).label
    : monthOptionsEn.find(({ value }) => value === stringIndex).label
}

// Date is valid if the given day is less than the number of days for that month + year from getDayOptions (+1 for 'Select day' option)
export const isValidDate = (month, day, year) => parseInt(day) < getDayOptions(month, year).length;

// Return whether mobile system is detected, or is mobile width based on initial load
export const onMobile = () => {
  const mobileSystemDetected = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const mobileScreenWidth = window.innerWidth < MAX_DESKTOP_WIDTH;
  return mobileSystemDetected || mobileScreenWidth;
};
