import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components'
import {  Field, FieldArray } from 'formik';
import { LinkLikeButton } from './Common';
import trashIcon from '../../assets/trash.svg'
import { useSelector } from 'react-redux';
import { englishIsActiveSelector } from '../../selectors/translations';
import { Translate } from 'react-localize-redux';

const NameFieldWrapper = styled.div`
  display: flex;
  margin: 10px 0px;
  > input {
    margin-right: 5px;
  }
`;

const TrashIcon = styled.img`
  height: 24px;
  width: 24px;
`

const RemoveButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
`

const SectionWrapper = styled.div`
  padding-bottom: 10px;
` 

const SectionTitle = styled.p`
  margin-bottom: 2px;
  font-weight: 600;
  padding: 0px;
` 

// Section displaying title (e.g. "Other first names") and the fields / buttons to add & remove names
export const NameArrayEditor = ({ nameType, values, fieldName }) => {
  const englishIsActive = useSelector(englishIsActiveSelector);
  const firstNameText = englishIsActive ? 'first name' : 'nombre';
  const lastNameText = englishIsActive ? 'last name' : 'apellido';
  const nameText = nameType === 'first' ? firstNameText : lastNameText;
  const linkText = `Add ${values[fieldName].length > 0 ? 'another' : 'a'} ${nameText} +`;
  const [previousValuesLength, setPreviousValuesLength] = useState(values[fieldName].length);
  // Move focus to the top input when a new field is added
  useEffect(() => {
    if (values[fieldName].length > previousValuesLength) {
      const newInput = document.getElementById(`${fieldName}-input-${values[fieldName].length - 1}`);
      if (newInput) {
        newInput.focus();
      }
    }
    setPreviousValuesLength(values[fieldName].length);
  }, [values[fieldName].length]);
  return (
    <SectionWrapper>
      <SectionTitle><Translate id="otherBlankNames" data={{ nameText }} /></SectionTitle>
      <FieldArray
          name={fieldName}
          render={arrayHelpers => (
            <div>
              {values[fieldName].map((item, index) => (
                  <NameFieldWrapper key={index}>
                    <Field name={`${fieldName}.${index}`} id={`${fieldName}-input-${index}`} />
                    <RemoveButton type="button" aria-label={`Delete other ${nameType} name`} onClick={() => { arrayHelpers.remove(index);}}>
                      <TrashIcon src={trashIcon} alt="Green trash can icon" />
                    </RemoveButton>
                  </NameFieldWrapper>
                ))
              }
              <LinkLikeButton type="button" onClick={() => arrayHelpers.push()} analyticsName={linkText}>
                {
                  values[fieldName].length > 0 
                  ? <Translate id="addAnotherName" data={{ nameText }} />
                  : <Translate id="addName" data={{ nameText }} />
                }
              </LinkLikeButton>
            </div>
          )}
        />
    </SectionWrapper>
  )
}