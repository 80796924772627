import {
  TLH_411_0725_GUIDED_FORM_LINK,
  TLH_411_0725_INFO_LINK,
  TLH_411_0725_PETITION_LINK,
  TLH_411_0726_GUIDED_FORM_LINK,
  TLH_411_0726_INFO_LINK,
  TLH_411_0726_PETITION_LINK,
  TLH_411_0727_GUIDED_FORM_LINK,
  TLH_411_0727_PETITION_LINK,
  TLH_411_0728_GUIDED_FORM_LINK,
  TLH_411_0728_INFO_LINK,
  TLH_411_0728_PETITION_LINK,
  TLH_411_0729_GUIDED_FORM_LINK,
  TLH_411_0729_INFO_LINK,
  TLH_411_0729_PETITION_LINK,
  TLH_411_072_INFO_LINK,
  TLH_411_072_PETITION_LINK,
  TLH_411_0731_GUIDED_FORM_LINK,
  TLH_411_0731_INFO_LINK,
  TLH_411_0731_PETITION_LINK,
  TLH_411_0735_GUIDED_FORM_LINK,
  TLH_411_0735_INFO_LINK,
  TLH_411_0735_PETITION_LINK,
  TLH_411_0736_GUIDED_FORM_LINK,
  TLH_411_0736_INFO_LINK,
  TLH_411_0736_PETITION_LINK,
  TLH_411_073_GUIDED_FORM_LINK,
  TLH_411_072_GUIDED_FORM_LINK,
  TLH_411_073_INFO_LINK,
  TLH_411_073_PETITION_LINK,
  TLH_BOATING_PETITION_LINK,
} from './links';

// Nondisclosure form case properties. Pulling them out into variables since I'm currently guessing on naming
export const FINAL_POT_ELIG_411_0727 = 'final_pot_elig_411_0727';
export const FINAL_POT_ELIG_411_0728 = 'final_pot_elig_411_0728';
export const FINAL_POT_ELIG_411_0729 = 'final_pot_elig_411_0729';
export const FINAL_POT_ELIG_411_0731 = 'final_pot_elig_411_0731';
export const FINAL_POT_ELIG_411_0736 = 'final_pot_elig_411_0736';
export const PENDING_POT_ELIG_411_0727 = 'pending_pot_elig_411_0727';
export const PENDING_POT_ELIG_411_0728 = 'pending_pot_elig_411_0728';
export const PENDING_POT_ELIG_411_0729 = 'pending_pot_elig_411_0729';
export const NDO_FORM = 'ndo_form';
export const DAYS_SINCE_SENTENCE_COMPLETION = 'days_since_sentence_completion';
export const DAYS_SINCE_PROBATION_COMPLETION = 'days_since_probation_completion';

// Nondisclosure Form IDs
export const FORM_ID_411_072 = '411.072';
export const FORM_ID_411_0725 = '411.0725';
export const FORM_ID_411_0726 = '411.0726'; // Needs followup questions
export const FORM_ID_411_0727 = '411.0727'; // Needs followup questions
export const FORM_ID_411_0728 = '411.0728'; // Needs followup questions
export const FORM_ID_411_0729 = '411.0729'; // Needs followup questions
export const FORM_ID_411_073 = '411.073';
export const FORM_ID_411_0731 = '411.0731'; // Needs followup questions
export const FORM_ID_411_0735 = '411.0735';
export const FORM_ID_411_0736 = '411.0736'; // Needs followup questions

// Followup Field Ids
export const COMPLETED_VET_REEMPLOYMENT_PRG_FOR_CASE = 'completed_vet_reemployment_prg_for_case'; // Veteran's reemployemt program (411.0729)
export const COMPLETED_VET_TREATMENT_COURT_FOR_CASE = 'completed_vet_treatment_court_for_case'; // Veteran's treatment court (411.0727)
export const BAC_UNDER_015 = 'bac_under_015'; // DWI (411.0726, 411.0731, 411.0736)
export const HAD_COMMERCIAL_LICENSE_OR_PERMIT = 'had_commercial_license_or_permit'; // DWI - deferred adjudication (411.0726)
export const USED_IGNITION_INTERLOCK = 'used_ignition_interlock_device'; // DWI - conviction (411.0731, 411.0736)
export const COMMITTED_SOLELY_AS_TRAFFICKING_VICTIM = 'committed_solely_as_trafficking_victim'; // Human Trafficking (411.0728)
export const COMMITTED_AFTER_SUBMITTING_PETITION = 'commited_after_submitting_petition'; // Human Trafficking (411.0728)

// Initial fields object for formik for each NDO form type requiring followup questions
export const INIT_FOLLOWUP_FIELDS_BY_FORM = {
  [FORM_ID_411_0726]: { [BAC_UNDER_015]: null, [HAD_COMMERCIAL_LICENSE_OR_PERMIT]: null },
  [FORM_ID_411_0727]: { [COMPLETED_VET_TREATMENT_COURT_FOR_CASE]: null },
  [FORM_ID_411_0728]: {
    [COMMITTED_SOLELY_AS_TRAFFICKING_VICTIM]: null,
    [COMMITTED_AFTER_SUBMITTING_PETITION]: null,
  },
  [FORM_ID_411_0729]: { [COMPLETED_VET_REEMPLOYMENT_PRG_FOR_CASE]: null },
  [FORM_ID_411_0731]: { [BAC_UNDER_015]: null, [USED_IGNITION_INTERLOCK]: null },
  [FORM_ID_411_0736]: { [BAC_UNDER_015]: null, [USED_IGNITION_INTERLOCK]: null },
};

// Texas Legal Services page links for each NDO form type
export const TLS_LINKS_BY_FORM_ID = {
  [FORM_ID_411_072]: TLH_411_072_INFO_LINK,
  // NOTE: 0725 is terrible, maybe should just set to null and show other form
  [FORM_ID_411_0725]: TLH_411_0725_INFO_LINK,
  // NOTE: 0726 has "in detail" wording, instead of "who is eligible"
  [FORM_ID_411_0726]: TLH_411_0726_INFO_LINK,
  // NOTE: No texas law help page for 411.0727
  [FORM_ID_411_0727]: null,
  [FORM_ID_411_0728]: TLH_411_0728_INFO_LINK,
  // NOTE: 0729 has "in detail" wording, instead of "who is eligible"
  [FORM_ID_411_0729]: TLH_411_0729_INFO_LINK,
  [FORM_ID_411_073]: TLH_411_073_INFO_LINK,
  [FORM_ID_411_0731]: TLH_411_0731_INFO_LINK,
  [FORM_ID_411_0735]: TLH_411_0735_INFO_LINK,
  [FORM_ID_411_0736]: TLH_411_0736_INFO_LINK,
};

// Texas Legal Services guided form links for each NDO form type
export const TLS_GUIDED_FORM_LINKS_BY_FORM_ID = {
  [FORM_ID_411_072]: TLH_411_072_GUIDED_FORM_LINK,
  [FORM_ID_411_0725]: TLH_411_0725_GUIDED_FORM_LINK,
  [FORM_ID_411_0726]: TLH_411_0726_GUIDED_FORM_LINK,
  [FORM_ID_411_0727]: TLH_411_0727_GUIDED_FORM_LINK,
  [FORM_ID_411_0728]: TLH_411_0728_GUIDED_FORM_LINK,
  [FORM_ID_411_0729]: TLH_411_0729_GUIDED_FORM_LINK,
  [FORM_ID_411_073]: TLH_411_073_GUIDED_FORM_LINK,
  [FORM_ID_411_0731]: TLH_411_0731_GUIDED_FORM_LINK,
  [FORM_ID_411_0735]: TLH_411_0735_GUIDED_FORM_LINK,
  [FORM_ID_411_0736]: TLH_411_0736_GUIDED_FORM_LINK,
};

// There's a separate boating petition for 411.0726, variable to specify with
export const BOATING_PETITION_ID = '411.0726_boating';

// Texas Legal Services fillable petition links for each NDO form
export const PETITION_LINKS_BY_FORM_ID = {
  [FORM_ID_411_072]: TLH_411_072_PETITION_LINK,
  [FORM_ID_411_0725]: TLH_411_0725_PETITION_LINK,
  [FORM_ID_411_0726]: TLH_411_0726_PETITION_LINK,
  [FORM_ID_411_0727]: TLH_411_0727_PETITION_LINK,
  [FORM_ID_411_0728]: TLH_411_0728_PETITION_LINK,
  [FORM_ID_411_0729]: TLH_411_0729_PETITION_LINK,
  [FORM_ID_411_073]: TLH_411_073_PETITION_LINK,
  [FORM_ID_411_0731]: TLH_411_0731_PETITION_LINK,
  [FORM_ID_411_0735]: TLH_411_0735_PETITION_LINK,
  [FORM_ID_411_0736]: TLH_411_0736_PETITION_LINK,
  [BOATING_PETITION_ID]: TLH_BOATING_PETITION_LINK,
};
