import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { setPrivacyModal } from '../../actions/modals';
import { showPrivacySelector } from '../../selectors/modals';
import { Modal, ModalHeader as Title } from './Modal';
import { Translate } from 'react-localize-redux'

const Text = styled.p`
  margin: 20px 0px;
`

export const PrivacyModal = () => {

  const showModal = useSelector(showPrivacySelector);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setPrivacyModal(false));
  }

  if (!showModal) {
    return null;
  }

  return (
    <Modal closeOnOuterClick={true} onClose={onClose} style={{ width: 'min(95%, 550px)' }}>
      <Title><Translate id="footer2_4" /></Title>
      <Text>
        <Translate id="privacyStmntBlurb" />     
      </Text>
    </Modal>
  )
}