import React from 'react';
import { InvertedButton, LinkLikeButton, StyledHR, TextLink } from '../general/Common';
import { BeigeTile, Header, Text } from '../general/BeigeTile.js';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  eligibleCasesSelector,
  expunctionEligibleCasesSelector,
  purelyIneligibleCasesSelector,
} from '../../selectors/entities.js';
import { CaseCard } from '../general/CaseCard';
import { setCase, setEligibilityDisclaimerModal, setEmailResultsModal } from '../../actions/modals';
import { NextStepsOverview } from './NextStepsOverview';
import { NoCasesFound } from './NoCasesFound';
import { TLH_EXPUNCTION_LINK } from '../../constants/links';
import { Translate } from 'react-localize-redux';
import useResultsText from '../../hooks/useResultsText';
import { HelpText } from '../general/HelpText';

// Helper, gives "# case" or "# cases" depending on length of array
const getCasesText = (caseArray, casesText) => {
  return `${caseArray.length} ${casesText}`;
};

// No cases were found content
const NoResults = () => {
  return (
    <BeigeTile>
      <Header>
        <Translate id="noCasesFound" />
      </Header>
      <NoCasesFound />
    </BeigeTile>
  );
};

// Eligible cases with content
// The content is split into three parts, each of these contains the total number of cases found and a dropdown with case details:
// 1) Cases likely eligible for nondisclosure
// 2) Cases likely eligible for expunction
// 3) Ineligible cases
const ResultDetails = ({
  nondisclosureEligibleCases,
  expunctionEligibleCases,
  ineligibleCases,
}) => {
  const dispatch = useDispatch();
  const { ineligibleText } = useResultsText(ineligibleCases.length > 1);

  const { eligibleText: nondisclosureEligText } = useResultsText(
    nondisclosureEligibleCases.length > 1
  );
  const { eligibleText: expunctionEligText } = useResultsText(expunctionEligibleCases.length > 1);

  const clickCase = currCase => () => dispatch(setCase(currCase));

  return (
    <BeigeTile>
      {/* Brief explanation that expunction and nondisclosure are separate petitions and procedures */}
      <Text style={{ marginBottom: '40px' }}>
        <Translate id="expAndNondiscSeparate" />
      </Text>

      {/* Likely eligible for nondisclosure */}
      {nondisclosureEligibleCases.length === 0 ? (
        <Header>
          <Translate id="noLikelyCasesForNondisclosure" />
        </Header>
      ) : (
        <>
          <Header>
            {getCasesText(nondisclosureEligibleCases, nondisclosureEligText)}
            {` `}
            <Translate id="forNondisclosure" />
            {` `}
            <Translate id="found" />
          </Header>
          <Text>
            <Translate id="nondisclosureBlurb" />
          </Text>
          {nondisclosureEligibleCases.map((currCase, idx) => (
            <CaseCard onClick={clickCase(currCase)} key={`case${idx}`} caseInfo={currCase} />
          ))}
        </>
      )}

      {/* Likely eligible for expunction  */}
      {expunctionEligibleCases.length === 0 ? (
        <Header>
          <Translate id="noLikelyCasesForExpunction" />
        </Header>
      ) : (
        <>
          <Header>
            {getCasesText(expunctionEligibleCases, expunctionEligText)}
            {` `}
            <Translate id="forExpunction" />
            {` `}
            <Translate id="found" />
          </Header>
          <Text>
            <Translate id="expEligCasesBlurb" />
            <TextLink to={TLH_EXPUNCTION_LINK}>Texas Law Help</TextLink>.
          </Text>
          {expunctionEligibleCases.map((currCase, idx) => (
            <CaseCard onClick={clickCase(currCase)} key={`case${idx}`} caseInfo={currCase} />
          ))}
        </>
      )}

      {/* Ineligible cases  */}
      {ineligibleCases.length === 0 ? (
        <Header>
          <Translate id="noIneligibleCases" />
        </Header>
      ) : (
        <>
          <Header>
            {getCasesText(ineligibleCases, ineligibleText)} <Translate id="found" />
          </Header>
          <Text>
            <Translate id="likelyIneligCasesBlurb" />
          </Text>
          {ineligibleCases.map((currCase, idx) => (
            <CaseCard onClick={clickCase(currCase)} key={`case${idx}`} caseInfo={currCase} />
          ))}
          <i>
            <Translate id="whatToDoInelig" />{' '}
            <Link to="/check/know-your-rights">
              <Translate id="viewYourRights" />.
            </Link>{' '}
          </i>
        </>
      )}

      <StyledHR margin={'30px 0px'} />

      {/* These results do not guarantee eligibility + modal */}
      <Text>
        <i>
          *<Translate id="noteResults" />{' '}
          <LinkLikeButton
            style={{ fontStyle: 'italic' }}
            onClick={() => dispatch(setEligibilityDisclaimerModal(true))}
          >
            <Translate id="learnMore" />
          </LinkLikeButton>
        </i>
      </Text>

      {/* Email my case information to me button */}
      <InvertedButton onClick={() => dispatch(setEmailResultsModal(true))}>
        <Translate id="emailCaseInfo" />{' '}
      </InvertedButton>

      {/* Beacon Law support text + modal */}
      <HelpText introTextId="haveQuestionAboutResults" />

      {/* Next Steps section */}
      <NextStepsOverview nondisclosureEligibleCasesExist={nondisclosureEligibleCases.length > 0} />
    </BeigeTile>
  );
};

export const Results = () => {
  const nondisclosureEligibleCases = useSelector(eligibleCasesSelector);
  const expunctionEligibleCases = useSelector(expunctionEligibleCasesSelector);
  const ineligibleCases = useSelector(purelyIneligibleCasesSelector);

  if (
    nondisclosureEligibleCases.length === 0 &&
    expunctionEligibleCases.length === 0 &&
    ineligibleCases.length === 0
  ) {
    return <NoResults />;
  } else {
    return (
      <ResultDetails
        nondisclosureEligibleCases={nondisclosureEligibleCases}
        expunctionEligibleCases={expunctionEligibleCases}
        ineligibleCases={ineligibleCases}
      />
    );
  }
};
