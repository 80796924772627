import React, { useMemo } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Formik, useFormikContext } from 'formik';
import { YesNoField } from '../general/FormFields.js';
import { BackArrowButton, Button } from '../general/Common';
import { BeigeTile, Header } from '../general/BeigeTile.js';
import { followUpQuestionCasesSelector } from '../../selectors/entities.js';
import { useDispatch, useSelector } from 'react-redux';
import { CaseCard } from '../general/CaseCard.js';
import { setCase } from '../../actions/modals.js';
import { getFormsNeededForFollowUp } from '../../utils/cases.js';
import { Translate } from 'react-localize-redux';
import { 
  COMPLETED_VET_REEMPLOYMENT_PRG_FOR_CASE, 
  INIT_FOLLOWUP_FIELDS_BY_FORM, 
  COMMITTED_SOLELY_AS_TRAFFICKING_VICTIM, 
  COMMITTED_AFTER_SUBMITTING_PETITION,
  COMPLETED_VET_TREATMENT_COURT_FOR_CASE,
  USED_IGNITION_INTERLOCK,
  HAD_COMMERCIAL_LICENSE_OR_PERMIT,
  BAC_UNDER_015,
  FORM_ID_411_0726,
  FORM_ID_411_0727,
  FORM_ID_411_0728,
  FORM_ID_411_0729,
  FORM_ID_411_0731,
  FORM_ID_411_0736,
  FINAL_POT_ELIG_411_0731,
  FINAL_POT_ELIG_411_0736,
} from '../../constants/cases.js';
import { validateFollowupQuestions } from '../../utils/validators.js';
import { useCaseFollowupQuestions } from '../../hooks/editors.js';

const Text = styled.p`
  margin: 10px 0px;
`;

// Router component for the case-specific followup question pages 
// (where the user walks through each case we need to ask followup questions about)
export const CaseFollowupQuestionsRoutes = () => {
  const casesToVerify = useSelector(followUpQuestionCasesSelector);

  return (<Switch>
    {casesToVerify.map((currCase, index) => 
      <Route path={`/check/case-verifications/${index}`} key={currCase.casID} 
             render={() => <CaseFollowupQuestions index={index}/>} />
    )}
    {casesToVerify.length === 0 && <Redirect to="/check/results" />}
  </Switch>)
}

// Case-specific page component to ask the user NDO form followup questions
export const CaseFollowupQuestions = ({ index }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const casesToVerify = useSelector(followUpQuestionCasesSelector);
  const currCase = casesToVerify[index];
  const clickCase = () => dispatch(setCase(currCase));
  const formIds = getFormsNeededForFollowUp(currCase);
  
  // Grab the initial values for the form based on the current case
  const initialValues = useMemo(() => 
    formIds.reduce((allFields, formId) => ({
      ...allFields,
      ...INIT_FOLLOWUP_FIELDS_BY_FORM[formId],
      ...currCase,
    }), {}), [currCase, formIds]);
  
  // Go to next case after they submit if there are any cases left; otherwise, go to results
  const nextLink = index + 1 < casesToVerify.length ? `/check/case-verifications/${index + 1}` : '/check/results'
  const [submit, submitting] = useCaseFollowupQuestions(() => history.push(nextLink), currCase);

  return (
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validate={validateFollowupQuestions(currCase)}
        onSubmit={submit}>
        {({ handleSubmit, errors, touched, submitCount }) => {
          const getError = (name) => (touched[name] || submitCount >= 1) && errors[name];
          return (
            <BeigeTile>
              {index !== 0 && <BackArrowButton onClick={() => history.goBack()} />}
              {casesToVerify.length > 1 
                ? <Header style={{ marginBottom: '0px' }}>
                   <Translate id="caseVerifDynamic" data={{ index: index + 1, total: casesToVerify.length }} />
                </Header>
                : <Header style={{ marginBottom: '0px' }}><Translate id="caseVerif" /></Header>
              }

              <Text><Translate id="pleaseTakeALook" /></Text>
              <CaseCard caseInfo={currCase} onClick={clickCase} /> 
              
              {/* Inject form-specific questions for this case */}
              {formIds.map((formId) => getFieldsByFormId(formId, getError, currCase))}

              <Button type="submit" data-testid="case_followup_next" isLoading={submitting} fillOnMobile={true} style={{ minWidth: '260px' }} onClick={handleSubmit}>
                <Translate id="next" />
              </Button>
            </BeigeTile>
          )}}
    </Formik> 
  )
}

// Grab the correct question form fields for the given form ID
const getFieldsByFormId = (formId, getError, currCase) => {
  if (formId === FORM_ID_411_0727) {
    return <VetsTreatmentCourtFields key={formId} getError={getError} />
  }
  if (formId === FORM_ID_411_0728) {
    return <HumanTraffickingVictimFields key={formId} getError={getError} />
  }
  if (formId === FORM_ID_411_0729) {
    return <VetsReemploymentPrgFields key={formId} getError={getError} />
  }
  if (formId === FORM_ID_411_0731 || formId === FORM_ID_411_0736) {
    return <DWIConvictionFields currCase={currCase} key={formId} getError={getError} />
  }
  if (formId === FORM_ID_411_0726) {
    return <DWIDeferredAdjudictionFields key={formId} getError={getError} />
  }
}

// Form fields for human trafficking victim eligible cases
const HumanTraffickingVictimFields = ({ getError }) => {
  const { values } = useFormikContext();
  return (<>
    <Text style={{marginTop: '30px'}}>
      <Translate id="humanTraffickingCVQ1" />
    </Text>
    <YesNoField name={COMMITTED_SOLELY_AS_TRAFFICKING_VICTIM} error={getError(COMMITTED_SOLELY_AS_TRAFFICKING_VICTIM)} />
    {values[COMMITTED_SOLELY_AS_TRAFFICKING_VICTIM] === true &&
      <>
        <Text>
          <Translate id="humanTraffickingCVQ2" />
        </Text>
        <YesNoField name={COMMITTED_AFTER_SUBMITTING_PETITION} error={getError(COMMITTED_AFTER_SUBMITTING_PETITION)} />
      </>}
  </>)
}

// Form fields for DWI conviction cases
const DWIConvictionFields = ({ getError, currCase }) => {
  const { values } = useFormikContext();
  return (<>
    <Text style={{marginTop: '30px'}}>
      <Translate id="dwiConvCVQ1" />
    </Text>
    <YesNoField name={BAC_UNDER_015} error={getError(BAC_UNDER_015)}  />
    {/* Ask about the ignition interlock if they are flagged as potentially eligible and have a qualifying BAC */}
    {(currCase[FINAL_POT_ELIG_411_0731] || currCase[FINAL_POT_ELIG_411_0736]) && values[BAC_UNDER_015] === true && 
      <>
        <Text>
          <Translate id="dwiConvCVQ1" />
        </Text>
        <YesNoField name={USED_IGNITION_INTERLOCK} error={getError(USED_IGNITION_INTERLOCK)} />
      </>}
  </>)
}

// Form fields for DWI deferred adjudication cases
const DWIDeferredAdjudictionFields = ({ getError }) => {
  const { values } = useFormikContext();
  return (<>
    <Text style={{marginTop: '30px'}}>
      <Translate id="dwiDACVQ1" />
    </Text>
    <YesNoField name={BAC_UNDER_015} error={getError(BAC_UNDER_015)} />
    {values[BAC_UNDER_015] === true &&
      <>
        <Text>
          <Translate id="dwiDACVQ2" />
        </Text>
        <YesNoField name={HAD_COMMERCIAL_LICENSE_OR_PERMIT} error={getError(HAD_COMMERCIAL_LICENSE_OR_PERMIT)} />
      </>}
  </>)
}

// Form fields for veteran's reemployment program applicable cases
const VetsReemploymentPrgFields = ({ getError }) => {
  return (<>
    <Text style={{marginTop: '30px'}}>
      <Translate id="vetReemploymentCVQ" />
    </Text>
    <YesNoField name={COMPLETED_VET_REEMPLOYMENT_PRG_FOR_CASE} error={getError(COMPLETED_VET_REEMPLOYMENT_PRG_FOR_CASE)} />
  </>)
}

// Form fields for veteran's treatment court applicable cases
const VetsTreatmentCourtFields = ({ getError }) => {
  return (<>
    <Text style={{marginTop: '30px'}}>
      <Translate id="vetTreatmentCVQ1" />
    </Text>
    <YesNoField name={COMPLETED_VET_TREATMENT_COURT_FOR_CASE} error={getError(COMPLETED_VET_TREATMENT_COURT_FOR_CASE)} />
  </>)
}
