import { ENV } from '../constants/general';

import {
  SET_CASE_MODAL,
  SET_DISCLAIMER_MODAL,
  SET_PRIVACY_MODAL,
  SET_EMAIL_PETITIONS_MODAL,
  SET_EMAIL_RESULTS_MODAL,
  SET_EMAIL_KNOW_YOUR_RIGHTS_MODAL,
  SET_ELIGIBILITY_DISCLAIMER_MODAL,
  SET_SURVEY_MODAL,
  SET_ABOUT_US_MODAL,
  SET_VETS_REEMPLOYMENT_MODAL,
  SET_VETS_TREATMENT_CRT_MODAL,
  SET_DEMO_INSTRUCTIONS_MODEL,
  SET_GUIDED_FORM_MODAL,
  SET_HELP_MODAL,
} from '../actions/modals';

const defaultState = {
  selectedCase: null,
  showIneligibleCases: false,
  showExpEligibleCases: false,
  showDisclaimer: false,
  showEligibilityDisclaimer: false,
  showPrivacy: false,
  showEmailPetitions: false,
  showEmailResults: false,
  showEmailKnowYourRights: false,
  showSurvey: false,
  showAboutUs: false,
  showVetsReemploymentPrg: false,
  showVetsTreatmentCrt: false,
  showGuidedForm: false,
  showHelp: false,
  showDemoInstructions: ENV === 'demo' ? true : false,
};

const modalsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_CASE_MODAL:
      return {
        ...state,
        selectedCase: action.payload,
      };
    case SET_DISCLAIMER_MODAL:
      return {
        ...state,
        showDisclaimer: action.payload,
      };
    case SET_ELIGIBILITY_DISCLAIMER_MODAL:
      return {
        ...state,
        showEligibilityDisclaimer: action.payload,
      };
    case SET_PRIVACY_MODAL:
      return {
        ...state,
        showPrivacy: action.payload,
      };
    case SET_EMAIL_RESULTS_MODAL:
      return {
        ...state,
        showEmailResults: action.payload,
      };
    case SET_EMAIL_PETITIONS_MODAL:
      return {
        ...state,
        showEmailPetitions: action.payload,
      };
    case SET_EMAIL_KNOW_YOUR_RIGHTS_MODAL:
      return {
        ...state,
        showEmailKnowYourRights: action.payload,
      };
    case SET_SURVEY_MODAL:
      return {
        ...state,
        showSurvey: action.payload,
      };
    case SET_ABOUT_US_MODAL:
      return {
        ...state,
        showAboutUs: action.payload,
      };
    case SET_VETS_REEMPLOYMENT_MODAL:
      return {
        ...state,
        showVetsReemploymentPrg: action.payload,
      };
    case SET_VETS_TREATMENT_CRT_MODAL:
      return {
        ...state,
        showVetsTreatmentCrt: action.payload,
      };
    case SET_GUIDED_FORM_MODAL:
      return {
        ...state,
        showGuidedForm: action.payload,
      };
    case SET_DEMO_INSTRUCTIONS_MODEL:
      return {
        ...state,
        showDemoInstructions: action.payload,
      };
    case SET_HELP_MODAL:
      return {
        ...state,
        showHelp: action.payload,
      };
    default:
      return state;
  }
};

export default modalsReducer;
